import * as Sentry from '@sentry/react';
import { useEffect, useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { queryFnFetchErrorWrapper, throwFetchError } from 'src/api/utils/errorHandlers';
import { mergeQueryOptions } from 'src/api/utils/helpers';
import { queryNames } from 'src/api/utils/queryNames';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { CustomQueryOptions, FetchError, DashboardFilters } from 'src/types/TApiQueries';
import { getCollection, getAllCollections, CollectionQuery, searchCollections } from '../functions/collectionsApi';
import { isValidSort } from '../utils/search';

export function useCollectionQuery(
  collectionId?: number,
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<any[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions(options, queryOptions);
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () => queryFnFetchErrorWrapper(() => getCollection(accessToken, collectionId)),
    [collectionId, accessToken],
  );

  const query = useQuery<any, FetchError>([queryNames.collection, collectionId], queryFn, actualQueryOptions);

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching collection', Sentry.Severity.Error);
    } else {
      throwFetchError(query.error);
    }
  }

  return query;
}

export function useAllCollectionsQuery(
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<any[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions(options, queryOptions);
  const accessToken = useAccessToken();

  const queryFn = useMemo(() => queryFnFetchErrorWrapper(() => getAllCollections(accessToken)), [accessToken]);

  const query = useQuery<any, FetchError>([queryNames.collection, 'all'], queryFn, actualQueryOptions);

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching collection', Sentry.Severity.Error);
    } else {
      throwFetchError(query.error);
    }
  }

  return query;
}

const sortConfig = {
  delimiter: ' ',
  validFields: ['last_update', 'name', 'group_access'],
  validDirections: ['asc', 'desc'],
};

export function useSearchCollectionsQuery(
  searchQuery: CollectionQuery,
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<any[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions(options, queryOptions);
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () => queryFnFetchErrorWrapper(() => searchCollections(accessToken, searchQuery)),
    [accessToken, searchQuery],
  );

  const enabled = useMemo(() => {
    if (!searchQuery?.sort_by) return false;

    const { sort_by } = searchQuery;

    return sort_by.every((sort) => isValidSort(sort, sortConfig));
  }, [searchQuery]);

  const query = useQuery<any, FetchError>([queryNames.collection, 'search'], queryFn, {
    ...actualQueryOptions,
    enabled,
    staleTime: 0,
  });

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching collection', Sentry.Severity.Error);
    } else {
      throwFetchError(query.error);
    }
  }

  useEffect(() => {
    query.refetch();
  }, [searchQuery]);

  return query;
}
