import React, { useState } from 'react';
import { useAddUserManagementMutation } from 'src/api/mutations/userManagementMutation';
import { useAlerts } from 'src/common/AlertManager';
import { Button, Checkbox, Input, Paper, Select } from 'src/components';
import BaseModal, { BaseModalProps } from 'src/components/BaseModal/BaseModal';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import useForm from 'src/hooks/useForm';

import * as yup from 'yup';

import styles from '../styles.module.scss';

import messages from '../messages';
import { FormattedMessage, useIntl } from 'react-intl';
import useModal from 'src/hooks/useModal';

const options = [
  {
    label: 'ADMINISTRATOR',
    value: '1',
  },
  {
    label: 'MANAGER',
    value: '2',
  },
  {
    label: 'USER',
    value: '3',
  },
  {
    label: 'CREATOR',
    value: '4',
  },
];

// write a regex that validates if an email is a string without the dot com

// regex that validates if string is a partial email

const AddUserSchema = yup.object().shape({
  email: yup
    .string()
    .matches(/^\w+([.-]?\w+)*@(\w+)|((\.\w{2,})+)/g, 'is not a valid email')
    .required()
    .label('Email'),
  first_name: yup.string().required().label('First name'),
  last_name: yup.string().required().label('Last name'),
  password: yup.string().required().min(8).max(32).label('Password'),
  role_id: yup.number().required().label('Role'),
  enabled: yup.boolean().label('Enabled'),
  notification_email: yup.boolean().label('Notification Email'),
  group_asset_access: yup.boolean().label('Group Asset Access'),
  template_user_id: yup.number().label('Template User'),
});

interface AddUserModalProps extends BaseModalProps {
  onAddUser: () => void;
}

const AddUserModal = (props: AddUserModalProps) => {
  const { form, handleChange } = useForm({
    email: '',
    first_name: '',
    last_name: '',
    role_id: '1',
    enabled: false,
    notification_email: false,
    group_asset_access: false,
    template_user_id: '',
    password: '',
  });

  const [errors, setErrors] = useState();
  const { closeModal } = useModal();

  const { showSuccess, showAlert } = useAlerts();
  const { getErrorMessage } = useErrorMessages();

  const { formatMessage } = useIntl();

  const userMutation = useAddUserManagementMutation({
    onSuccess: () => {
      showSuccess(formatMessage(messages.userAddedSuccessfully));
      closeModal();
      props.onAddUser();
    },
    onError: (error: any) => {
      showAlert(getErrorMessage(error));
    },
  });

  const handleCloseModal = () => closeModal();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setErrors(undefined);
    try {
      await AddUserSchema.validate({
        ...form,
        role_id: Number(form.role_id),
        template_user_id: Number(form.template_user_id),
      });

      userMutation.mutateAsync({ ...form });
    } catch (error: any) {
      setErrors(error.message);
    }
  };

  return (
    <BaseModal {...props}>
      <h3>
        <FormattedMessage {...messages.newUserModalTitle} />
      </h3>

      <form onSubmit={handleSubmit}>
        <Paper>
          <Input
            label="Email"
            type="email"
            variant="secondary"
            value={form.email}
            onChange={(value) => handleChange('email', value)}
          />

          <div className={styles.inlineGroup}>
            <Input
              label={formatMessage(messages.firstName)}
              variant="secondary"
              value={form.first_name}
              onChange={(value) => handleChange('first_name', value)}
            />
            <Input
              label={formatMessage(messages.lastName)}
              variant="secondary"
              value={form.last_name}
              onChange={(value) => handleChange('last_name', value)}
            />
          </div>

          <Input
            label="Password"
            type="password"
            variant="secondary"
            value={form.password}
            onChange={(value) => handleChange('password', value)}
            className={styles.formSpacing}
          />

          <div className={styles.inlineGroup}>
            <div className={styles.formSpacing}>
              <p>
                <FormattedMessage {...messages.role} />
              </p>
              <Select options={options} value={form.role_id} onChange={(value) => handleChange('role_id', value)} />
            </div>
            <Input
              label="Template User ID"
              type="number"
              variant="secondary"
              value={form.template_user_id}
              onChange={(value) => handleChange('template_user_id', value)}
              className={styles.formSpacing}
            />
          </div>

          <div className={styles.inlineGroup}>
            <Checkbox
              label={formatMessage(messages.enabled)}
              checked={form.enabled}
              onChangeCapture={() => handleChange('enabled', !form.enabled)}
              className={styles.formSpacing}
            />

            <Checkbox
              label={formatMessage(messages.notificationEmail)}
              checked={form.notification_email}
              onChangeCapture={() => handleChange('notification_email', !form.notification_email)}
              className={styles.formSpacing}
            />

            <Checkbox
              label={formatMessage(messages.allowUserGroupAssets)}
              checked={form.group_asset_access}
              onChangeCapture={() => handleChange('group_asset_access', !form.group_asset_access)}
              className={styles.formSpacing}
            />
          </div>
        </Paper>

        <Paper>
          {errors && <p className={styles.errorMessage}>{errors}</p>}
          <div className={styles.groupedButtons}>
            <Button type="submit" mode="primary" loading={userMutation.isLoading} size="small">
              <FormattedMessage {...messages.submit} />
            </Button>
            <Button
              type="button"
              mode="tertiary"
              onClick={handleCloseModal}
              loading={userMutation.isLoading}
              size="small"
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
          </div>
        </Paper>
      </form>
    </BaseModal>
  );
};

export default AddUserModal;
