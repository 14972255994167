import * as React from 'react';
import cn from 'classnames';
import styles from './LayoutView.module.scss';
import useLayoutSwitcher from 'src/hooks/useLayoutSwitcher';
import ListingCardShimmer from '../ListingCardShimmer';
import Shimmer from '../Shimmer';

type LayoutViewProps = {
  className?: string;
  data: unknown[];
  emptyDataMessage: string;
  cardView: (item: any) => React.ReactNode;
  listView: (item: any) => React.ReactNode;
  isLoading?: boolean;
  classNames?: {
    cardView?: string;
    listView?: string;
  };
};

/**
 * @component LayoutView
 * 
 * @description
 * This component is a wrapper around a list and card view.
 * It takes care of:
 *   - displaying an empty message when there is no data
 *   - displaying a loading state when data is loading
 *   - displaying the data when it is available
 * 
 * 
 * @param {function} listView - function that returns a list view
 * @param {function} cardView - function that returns a card view
 * @param {string} className - additional class name
 * @param {any[]} data - data to be displayed
 * @param {boolean} isLoading - flag to indicate if data is loading
 * @param {object} classNames - object with classNames for cardView and listView
 * @param {string} emptyDataMessage - message to be displayed when there is no data
 * 
 * @returns {JSX.Element} - LayoutView component
 * 
 **/
export const LayoutView: React.FC<LayoutViewProps> = ({
  children,
  className,
  data,
  cardView,
  listView,
  isLoading = false,
  classNames = {},
  emptyDataMessage,
  ...rest
}) => {


  const { isLayoutList, isLayoutGrid } = useLayoutSwitcher();

  const isEmpty = !isLoading && data?.length === 0;

  if (isEmpty) {
    return (
      <div className={cn([styles.LayoutView, className, styles.empty])} data-test="layout-view">
        <p>{emptyDataMessage}</p>
      </div>
    )
  }


  return (
    <div className={cn([styles.LayoutView, className])} data-test="layout-view">
      {isLayoutList && (
        <div className={cn(classNames.cardView)}>
          {
            isLoading
              ? new Array(30).fill(0).map((e, i) => <ListingCardShimmer key={i} />)
              : data?.map(cardView)
          }
        </div>
      )}
      {isLayoutGrid && <div className={cn(classNames.listView)}>
        {
          isLoading
            ? new Array(30).fill(0).map((e, i) => <Shimmer w={'100%'} h={80} radius={4} key={i} className={styles.shimmer} />)
            : data?.map(listView)
        }
      </div>}
    </div>
  );
};

export default LayoutView;
