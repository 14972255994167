import { FetchResult, NotificationsResponse } from 'src/types/TApiQueries';
import { config } from 'src/utils/config';
import { performFetchWithLogging, getStandardHeaders } from '../utils/helpers';

export async function getAllNotifications(accessToken: string): Promise<FetchResult<NotificationsResponse>> {
  const url = `${config.hostUrl}/api/v1/user/notifications`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export async function deleteNotifications(notifications: number[], accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/user/notifications`;

  const payload = JSON.stringify(notifications);

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}
