import { createAtom } from './base';
import { getSettings } from './utils';

export const { currentWatermarkSettingsAtom, updatedWatermarkSettingsAtom, watermarkSettingsAtom } = createAtom(
  'watermark',
  {
    updater: (currentAtom: any, updatedAtom: any, get: any, set: any, action: any) => {
      const { payload } = action;

      const state = get(updatedAtom);

      set(updatedAtom, { ...state, ...payload });
    },
  },
);

export const getWatermarkSettings = (get: any) =>
  getSettings(get, [watermarkSettingsAtom, updatedWatermarkSettingsAtom]);
