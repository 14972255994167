import { useSettingsQuery } from 'src/api/queries/userQueries';
import {
  AdminSettingsSection,
  AdminSettingsSidebar,
  Input,
  Paper,
  SaveChangesBar,
  SettingsNavigation,
} from 'src/components';
import styles from './styles.module.scss';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import ReactSwitch from 'react-switch';
import { webhookSettingsAtom } from 'src/shared/settings/webhook';
import { ACTION_TYPES } from 'src/shared/settings/base';

export const WebhookSettings = () => {
  const { isLoading } = useSettingsQuery();

  const [webhookSettings, updateWebhookSettings] = useAtom(webhookSettingsAtom);

  const events = useMemo(() => {
    if (!webhookSettings) return [];

    return Object.entries(webhookSettings.callback_events).map(([key, value]: [string, any]) => ({
      key,
      enabled: value,
    }));
  }, [webhookSettings]);

  const handleEventChange = (name: string, value: boolean) =>
    updateWebhookSettings({ type: ACTION_TYPES.UPDATE_STATE, payload: { callback_events: { [name]: value } } });

  if (isLoading) return null;

  return (
    <div className={styles.pageWrapper}>
      <SettingsNavigation />
      <SaveChangesBar />

      <div className={styles.container}>
        <div className={styles.sidebar}>
          <AdminSettingsSidebar
            items={[
              {
                key: 'webhook',
                label: 'Webhook',
              },
              {
                key: 'asset-events',
                label: 'Asset events',
              },
            ]}
          />
        </div>
        <div className={styles.content}>
          <AdminSettingsSection title="Webhooks">
            <Paper>
              <Input
                variant="secondary"
                value={webhookSettings.web_urls}
                onChange={(value: string) =>
                  updateWebhookSettings({ type: ACTION_TYPES.UPDATE_STATE, payload: { web_urls: value } })
                }
                placeholder=""
              />
              <p className={styles.sectionDescription}>
                Webhook must start with "http://" or "https://". Upto two Webhooks can be provided -- seperate them by
                comma
              </p>
            </Paper>
          </AdminSettingsSection>
          <AdminSettingsSection title="Asset events" className={styles.webhookOptions}>
            {events.map(({ key, enabled }) => (
              <Paper key={key} className={styles.webhookOption}>
                <ReactSwitch
                  checked={enabled}
                  onChange={(value) => handleEventChange(key, value)}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#2e53e3"
                />
                <label>{key}</label>
              </Paper>
            ))}
          </AdminSettingsSection>
        </div>
      </div>
    </div>
  );
};
