import * as React from 'react';
import cn from 'classnames';
import styles from './ConfirmDialog.module.scss';
import messages from './messages';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';

type ConfirmDialogProps = {
  className?: string;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  message,
  className,
  onConfirm,
  onCancel,
  ...rest
}) => {
  return (
    <div className={cn([styles.ConfirmDialog, className])}>
      <div className={styles.backdrop}></div>
      <div className={styles.dialog}>
        <h3>{title}</h3>
        {message && <p>{message}</p>}
        <div className={styles.controls}>
          <Button mode="decline" size="small" onClick={onCancel}>
            <FormattedMessage {...messages.no} />
          </Button>
          <Button mode="accept" size="small" onClick={onConfirm}>
            <FormattedMessage {...messages.yes} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
