import * as React from 'react';
import cn from 'classnames';
import styles from './Table.module.scss';
import messages from './messages';

type TableProps = {
  className?: string;
  headers: string[];
};

export const Table: React.FC<TableProps> = ({ children, className, headers, ...rest }) => {
  return (
    <div className={styles.responsiveTable}>
      <table className={cn([styles.Table, className])}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
