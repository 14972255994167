import { defineMessages } from 'react-intl';
export default defineMessages({
  title: {
    id: 'components.AddAssetsToCollections.title',
    defaultMessage: 'Add Assets to Collections',
  },
  description: {
    id: 'components.AddAssetsToCollections.description',
    defaultMessage: 'Select the collection you wish to add the {numberOfAssets} assets to',
  },
  success: {
    id: 'components.AddAssetsToCollections.success',
    defaultMessage: 'Successfully added {numberOfAssets} assets to the selected collection(s).',
  },
});
