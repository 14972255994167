import { defineMessages } from 'react-intl';

const messageId = (id: string) => `page.admin.settings.storage.${id}`;

export default defineMessages({
  filesHeader: {
    id: messageId('table.header.files'),
    defaultMessage: 'Files',
  },
  storageBucket: {
    id: messageId('table.header.storageBucket'),
    defaultMessage: 'S3 Bucket',
  },
  storagePathPrefix: {
    id: messageId('table.header.storagePathPrefix'),
    defaultMessage: 'Storage Path Prefix',
  },
  verified: {
    id: messageId('table.header.verified'),
    defaultMessage: 'Verified',
  },
  originalFiles: {
    id: messageId('table.body.originalFiles'),
    defaultMessage: 'Original Files',
  },
  storageBucketPlaceholder: {
    id: messageId('table.body.storageBucketPlaceholder'),
    defaultMessage: 'Leave empty for Default Storage',
  },
  imageConversions: {
    id: messageId('table.body.imageConversions'),
    defaultMessage: 'Image conversions',
  },
  videoTranscodes: {
    id: messageId('table.body.videoTranscodes'),
    defaultMessage: 'Video transcodes',
  },
});
