import cn from 'classnames';
import styles from './SaveChangesBar.module.scss';
import messages from './barMessages';
import { WarningCircle } from 'src/common/icons';
import Button from 'src/components/Button';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';


import SaveChangesBarProps from './types';

export const SaveChangesBar: React.FC<SaveChangesBarProps> = (props) => {

  return (
    <motion.div
      initial={false} 
      animate={{ height: props.isVisible ? 'auto' : 0 }}
      style={{ overflow: 'hidden' }}
    >
      <div className={cn([styles.SaveChangesBar, props.className])}>
        <p className={styles.unsavedChanges}>
          <span>
            <WarningCircle fontSize={18} color="var(--color-prime-400)" />
          </span>
          <FormattedMessage {...messages.unsavedChanges} />
        </p>
        <div className={styles.buttonGroup}>
          <Button mode="tertiary" size="small" onClick={props.onCancel}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            mode="primary"
            size="small"
            onClick={props.onConfirm}
            loading={props.isLoading}
          >
            <FormattedMessage {...messages.save} />
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export default SaveChangesBar;
