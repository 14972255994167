
import { useHistory } from 'react-router-dom';
import useRoleManager from 'src/hooks/useRoleManager';
import useModal from 'src/hooks/useModal';
import React from 'react';


/**
 * 
 * 
 * @returns object containing all the hooks
 **/
const useListingRowHooks = () => {

  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);

  return {
    roleManager: useRoleManager(),
    modal: useModal(),
    history: useHistory(),
    dialog: {
      isOpen: dialogIsOpen,
      setIsOpen: setDialogIsOpen
    }
  }
}

export default useListingRowHooks