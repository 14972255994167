import { useAtom } from 'jotai';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { AdminSettingsSection, Checkbox, ColorPicker, Input, Select, Table } from 'src/components';
import { ACTION_TYPES } from 'src/shared/settings/base';
import { videoSettingsAtom } from 'src/shared/settings/video';

import styles from '../styles.module.scss';

type VideoTranscodesProps = {};

export const VideoTranscodes: React.FC<VideoTranscodesProps> = () => {
  const [videoSettings, updateVideoSettings] = useAtom(videoSettingsAtom);

  const videos = useMemo(() => {
    if (!videoSettings) return [];

    return Object.entries(videoSettings.transcodes)
      .map(([key, value]: [string, any]) => ({ key, ...value }))
      .sort();
  }, [videoSettings]);

  const handleVideoUpdate = (key: string, property: string, value: any) =>
    updateVideoSettings({
      type: ACTION_TYPES.UPDATE_STATE,
      payload: {
        transcodes: {
          [key]: {
            ...videoSettings.transcodes[key],
            [property]: value,
          },
        },
      },
    });

  return (
    <AdminSettingsSection title="Video transcodes">
      <p>*-wm-video = Watermarked video transcodes</p>
      <Table
        headers={['Key', 'Watermarked', 'size (wxh)', 'Mode', 'padding color', 'upscale', 'public', 'create on upload']}
      >
        {videos.map(({ key, watermark, padding, aspect, upscale, enabled, width, height, ...video }: any) => (
          <tr key={key}>
            <td>{key}</td>
            <td>
              {key === 'hls-video' && (
                <Checkbox
                  className={styles.centeredCheckbox}
                  checked={watermark}
                  disabled={key === 'hls-video' ? false : true}
                  onChange={(e) => handleVideoUpdate(key, 'watermark', e.target.checked)}
                />
              )}
              {key !== 'hls-video' && (watermark ? 'Yes' : 'No')}
            </td>
            <td>{`${width}x${height}`}</td>
            <td>
              <Select
                value={aspect}
                className={styles.centeredSelect}
                onChange={(value) => handleVideoUpdate(key, 'aspect', value)}
                options={[
                  { label: 'Pad', value: 'pad' },
                  { label: 'Preserve', value: 'preserve' },
                  { label: 'Scale', value: 'scale' },
                  { label: 'Crop', value: 'crop' },
                ]}
              />
            </td>
            <td>
              <ColorPicker value={padding} onChange={(color) => handleVideoUpdate(key, 'padding', color)} />
            </td>
            <td>
              <Checkbox
                className={styles.centeredCheckbox}
                checked={upscale}
                onChange={(e) => handleVideoUpdate(key, 'upscale', e.target.checked)}
              />
            </td>
            <td>
              <Checkbox
                className={styles.centeredCheckbox}
                checked={video.public}
                onChange={(e) => handleVideoUpdate(key, 'public', e.target.checked)}
              />
            </td>
            <td>
              <Checkbox
                className={styles.centeredCheckbox}
                checked={enabled}
                onChange={(e) => handleVideoUpdate(key, 'enabled', e.target.checked)}
              />
            </td>
          </tr>
        ))}
      </Table>
    </AdminSettingsSection>
  );
};
