import * as React from 'react';
import cn from 'classnames';
import { TrashSimple, Pencil } from 'src/common/icons';
import styles from './styles.module.scss';
import ReactTooltip from 'react-tooltip';
import Dialog from '../Dialog';
import WordsListingRowProps from './types';
import useListingRowHooks from './hooks';
import useHandlers from './handlers';



export const ListingRow: React.FC<WordsListingRowProps> = (props) => {
  const hooks = useListingRowHooks();
  const handlers = useHandlers(props, hooks);


  return (
    <div className={cn([styles.ListingRow, props.className])}>
      <div style={{ marginLeft: '20px' }}> {props.word}</div>
      <div style={{ marginLeft: '20px' }}>
        {
          props.variants.join(",").length > 50 
            ? `${props.variants.join(",").slice(0, 50)} ...` 
            : props.variants.join(",")
        }
      </div>

      <div className={styles.actions}>
        {hooks.roleManager.isUser ? <></> : (
          <div>
            <button data-tip="Edit" onClick={handlers.actions.edit}>
              <Pencil />
              <ReactTooltip effect="solid" />
            </button>
            <button data-tip="Delete Word" onClick={handlers.dialog.open}>
              <TrashSimple />
              <ReactTooltip effect="solid" />
            </button>
            {hooks.dialog.isOpen && (
              <Dialog
                onClick={handlers.dialog.click}
                message={"Are you sure you want to delete?"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListingRow;
