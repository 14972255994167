import cn from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// import { ChevronLeft, ChevronRight } from 'src/common/icons';
import Dropdown from 'src/components/Dropdown';
import messages from './messages';
import styles from './Pager.module.scss';

type PagerProps = {
  currentPage: number;
  totalPages: number;
  onChange: (newPage: number) => void;
  itemsPerPage: number;
  onItemsPerPageChange: (itemsPerPage: number) => void;
  className?: string;
  showItemsPerPage?: boolean;
};

const createRange = (from: number, to: number): number[] => [...Array(to - from)].map((_, i) => i + from);

const PerPageDropdown = ({
  onItemsPerPageChange,
  itemsPerPage,
}: Pick<PagerProps, 'itemsPerPage' | 'onItemsPerPageChange'>) => {
  return (
    <div className={styles.dropdown}>
      <Dropdown
        dropdownAnchor="right"
        dropdownDirection="up"
        onClick={onItemsPerPageChange}
        options={[12, 18, 30].map((e) => ({
          key: e,
          value: e,
          noBorderBottom: true,
        }))}
        className={styles.dropdown}
      >
        <FormattedMessage {...messages.show} values={{ items: itemsPerPage }} />
      </Dropdown>
    </div>
  );
};

export const Pager: React.FC<PagerProps> = ({
  currentPage,
  totalPages,
  onChange,
  itemsPerPage,
  onItemsPerPageChange,
  className,
  showItemsPerPage = true,
  ...rest
}) => {
  const [value, setValue] = React.useState(currentPage);

  const visiblePagesRange = createRange(currentPage - 3, currentPage + 4).filter((v) => v > 0 && v <= totalPages);

  const emitValue = (requestedPage: number) => {
    if (requestedPage !== currentPage) onChange(requestedPage);
  };

  React.useEffect(() => {
    setValue(currentPage);
  }, [currentPage]);

  if (totalPages < 2)
    return showItemsPerPage ? (
      <PerPageDropdown itemsPerPage={itemsPerPage} onItemsPerPageChange={onItemsPerPageChange} />
    ) : null;

  return (
    <div className={cn([styles.Pager, className])}>
      <div className={styles.buttons}>
        {visiblePagesRange[0] > 1 && (
          <button onClick={() => emitValue(1)} className={cn([styles.button, { [styles.active]: currentPage === 1 }])}>
            1
          </button>
        )}
        {visiblePagesRange[0] > 2 && <span>&hellip;</span>}
        {visiblePagesRange.map((page, idx) => (
          <button
            key={idx}
            onClick={() => emitValue(page)}
            className={cn([styles.button, { [styles.active]: currentPage === page }])}
          >
            {page}
          </button>
        ))}
        {visiblePagesRange[visiblePagesRange.length - 1] < totalPages - 1 && <span>&hellip;</span>}
        {visiblePagesRange[visiblePagesRange.length - 1] < totalPages && (
          <button
            onClick={() => emitValue(totalPages)}
            className={cn([styles.button, { [styles.active]: currentPage === totalPages }])}
          >
            {totalPages}
          </button>
        )}
      </div>
      {showItemsPerPage && <PerPageDropdown itemsPerPage={itemsPerPage} onItemsPerPageChange={onItemsPerPageChange} />}
    </div>
  );
};

export default Pager;
