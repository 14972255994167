import * as React from 'react';

function SvgPlayBox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 64 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <g>
        <rect width={64} height={44} rx={4} fill="rgba(1, 10, 44, 0.8)" fillOpacity={0.8} />
        <path
          d="M44.519 21.147L26.522 10.148a1 1 0 00-1.522.853V33a1 1 0 001.521.853L44.52 22.853a1 1 0 000-1.706z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgPlayBox;
