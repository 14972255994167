import * as React from 'react';

function SvgDownloadSimple(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M8.063 10.313L12 14.25l3.938-3.938M12 3.747v10.5"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12.75v6.75a.75.75 0 01-.75.75H3.75A.75.75 0 013 19.5v-6.75"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgDownloadSimple;
