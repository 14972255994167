import * as React from 'react';
import cn from 'classnames';
import styles from './PageTitle.module.scss';
import messages from './messages';

type PageTitleProps = {
  className?: string;
  title: string;
  subtitle?: string;
  counter?: number;
  midContainer?: React.ReactNode;
  rightContainer?: React.ReactNode;
};

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  subtitle,
  counter,
  midContainer,
  rightContainer,
  className,
  ...rest
}) => {
  return (
    <div className={cn([styles.PageTitle, className])}>
      <div>
        <span className={styles.title}>{title}</span>
        <span className={styles.subtitle}>{subtitle}</span>
        <span className={styles.counter}>{counter}</span>
      </div>
      <div>{midContainer}</div>
      <div>{rightContainer}</div>
    </div>
  );
};

export default PageTitle;
