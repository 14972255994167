import React, { useMemo, useState } from 'react';
import { useCollectionMutation } from 'src/api/mutations/collectionMutations';
import { useAlerts } from 'src/common/AlertManager';
import { BaseModal, Button, Checkbox, Input, Paper, Textarea } from 'src/components';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import useModal from 'src/hooks/useModal';

import styles from '../styles.module.scss';
import { Collection, CollectionResponse } from 'src/types/TCollection';
import CollectionSchema from 'src/schemas/collection';
import { useQueryClient } from 'react-query';
import { queryNames } from 'src/api/utils/queryNames';

type EditCollectionModalProps = {
  collection: Collection;
};

const EditCollectionModal = ({ collection }: EditCollectionModalProps) => {
  const [name, setName] = useState(collection.name);
  const [groupAccess, setGroupAccess] = useState(collection.group_access);
  const [description, setDescription] = useState(collection.description);

  const { showSuccess, showAlert } = useAlerts();
  const { getErrorMessage } = useErrorMessages();

  const { closeModal } = useModal();

  const queryClient = useQueryClient();

  const updateCollectionMutation = useCollectionMutation(collection.id, {
    onSuccess: (_data, variables) => {
      const currentQueryData = queryClient.getQueryData<CollectionResponse>([queryNames.collection, collection.id]);

      const updatedCollectionData = {
        ...currentQueryData,
        data: {
          ...currentQueryData?.data,
          ...variables,
        },
      };

      queryClient.setQueryData([queryNames.collection, collection.id], updatedCollectionData);

      showSuccess('Collection successfully updated');
      closeModal();
    },
    onError: (error) => {
      showAlert(getErrorMessage(error.message));
    },
  });

  const isValid = useMemo(() => {
    try {
      CollectionSchema.validateSync({ name, group_access: groupAccess });
      return true;
    } catch (error) {
      return false;
    }
  }, [name, description, groupAccess]);

  const handleSaveCollection = () => {
    if (!isValid) return;

    updateCollectionMutation.mutateAsync({
      name,
      group_access: groupAccess,
      description,
    });
  };

  return (
    <BaseModal>
      <h3>Editing Collection {collection.name}</h3>

      <section>
        <Paper>
          <Input
            label="Collection Name"
            placeholder="Name Collection"
            value={name}
            onChange={(value) => setName(value)}
            className={styles.modalInput}
          />

          <Textarea
            className={styles.formGroup}
            label="Description"
            placeholder="Collection's description..."
            rows={4}
            value={description}
            onChange={(value) => setDescription(value)}
          />

          <Checkbox
            label="Group Collection"
            checked={groupAccess}
            onChange={() => setGroupAccess(!groupAccess)}
            className={styles.formGroup}
          />
        </Paper>
      </section>

      <div className={styles.modalActions}>
        <Button size="small" mode="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="small" mode="primary" onClick={handleSaveCollection} disabled={!isValid}>
          Save
        </Button>
      </div>
    </BaseModal>
  );
};

export default EditCollectionModal;
