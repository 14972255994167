import * as React from 'react';

function SvgPuzzlePiece(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M6 20.25a.75.75 0 01-.75-.75v-4.003a2.625 2.625 0 110-4.744V6.75A.75.75 0 016 6h4.378a2.626 2.626 0 114.744 0H19.5a.75.75 0 01.75.75v4.003a2.624 2.624 0 100 4.744V19.5a.75.75 0 01-.75.75H6z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgPuzzlePiece;
