import { defineMessages } from 'react-intl';
export default defineMessages({
  submit: {
    id: 'component.schemaForm.submit',
    defaultMessage: 'Submit',
  },
  cancel: {
    id: 'component.schemaForm.cancel',
    defaultMessage: 'Cancel',
  },
});
