import { useRef, useState, useReducer } from 'react';
import { useAtom } from 'jotai';
import { useDebounce } from 'use-debounce';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { DashboardFilters } from 'src/types/TApiQueries';
import { isRefetchingAtom, refreshAtom } from 'src/shared/refresh';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { selectedAssetsAtom } from 'src/shared/selectedAssets';
import { deletedAssetsAtom } from 'src/shared/deletedAssets';
import useModal from 'src/hooks/useModal';
import useRoleManager from 'src/hooks/useRoleManager';
import { useQueryClient } from 'react-query';
import { useCollectionQuery } from 'src/api/queries/collectionsQueries';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import { useHistory } from 'react-router-dom';
import { TSorting } from 'src/types/TSorting';
import { getThumbnailUrl } from 'src/utils/previews';
import { INITIAL_FILTERS, ITEMS_PER_PAGE_LOCAL_KEY } from './constants';

function filtersReducer(state: DashboardFilters, payload: { [key: string]: any }) {
  return { ...state, ...payload };
}

const checkImage = (url: string) =>
  new Promise<string>((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(url)
    img.onerror = () => reject()
    img.src = url
  })

export const useCachePreviewImages = (data: any) => {
  const accessToken = useAccessToken();

  const [firstTime, setFirstTime] = useState(true);

  if (data && data.result && firstTime) {

    for (const assetId of data.result) {
      const url = getThumbnailUrl({
        width: 800,
        height: 600,
        id: assetId,
        jwt: accessToken,
      })

      checkImage(url)

    }

    setFirstTime(false)
  }
}


const useComponentHooks = () => {

  const [isDeletingAssets, setIsDeletingAssets] = useState(false);
  const [sorting, setSorting] = useState<TSorting>(['upload_time_range', 'DESC']);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebounce(search, 800);

  const [fileName, setFileName] = useState('');
  const [fileNameDebounced] = useDebounce(fileName, 800);

  const [filters, dispatchFilter] = useReducer(
    filtersReducer,
    INITIAL_FILTERS,
    (state) => {
      const limit = localStorage.getItem(ITEMS_PER_PAGE_LOCAL_KEY)
        ? Number(localStorage.getItem(ITEMS_PER_PAGE_LOCAL_KEY))
        : state.limit_per_page;

      return { ...state, limit_per_page: limit, trashed: false };
    });

  const [selectedAssets, updateSelectedAssets] = useAtom(selectedAssetsAtom);
  const [deletedAssets, updateDeletedAssets] = useAtom(deletedAssetsAtom);

  return {
    accessToken: useAccessToken(),
    roleManager: useRoleManager(),
    history: useHistory(),
    modal: useModal(),
    queryClient: useQueryClient(),
    alerts: useAlerts(),
    errorMessages: useErrorMessages(),

    searchDebounced,
    fileNameDebounced,
    reducer: { filters, dispatchFilter, },
    queries: {
      collections: useCollectionQuery(),
    },

    refs: {
      searchHash: useRef(''),
    },

    state: {
      fileName, setFileName,
      search, setSearch,
      isDeletingAssets, setIsDeletingAssets,
      sorting, setSorting,
      filtersVisible, setFiltersVisible,
      totalPages, setTotalPages,
      refreshValue: useAtomValue(refreshAtom),
      setIsRefetching: useUpdateAtom(isRefetchingAtom),
      selectedAssets, updateSelectedAssets,
      deletedAssets, updateDeletedAssets
    }
  }
}


export default useComponentHooks