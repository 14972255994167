import * as React from 'react';

function SvgSlidersHorizontal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M18.5 21.5H5M27 21.5h-3.5M21 24a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM10.5 10.5H5M27 10.5H15.5M13 13a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgSlidersHorizontal;
