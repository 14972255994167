import { defineMessages } from 'react-intl';
export default defineMessages({
	view: {
		id: 'comp.ListingCard.viewAndUpdate',
		defaultMessage: 'Details',
	},
	download: {
		id: 'comp.ListingCard.download',
		defaultMessage: 'Download',
	},
});
