import { FetchResult } from 'src/types/TApiQueries';
import { CollectionResponse, AllCollectionsResponse } from 'src/types/TCollection';
import { config } from 'src/utils/config';
import { getStandardHeaders, performFetchWithLogging } from '../utils/helpers';

export async function getCollection(
  accessToken: string,
  collectionId?: number,
): Promise<FetchResult<CollectionResponse>> {
  const url = collectionId
    ? `${config.hostUrl}/api/v1/assetcollection/${collectionId}?load_assets=y`
    : `${config.hostUrl}/api/v1/assetcollection?load_assets=y`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export async function getAllCollections(accessToken: string): Promise<FetchResult<AllCollectionsResponse>> {
  const url = `${config.hostUrl}/api/v1/assetcollections`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}
 
export type CollectionQuery = {
  keyword?: string;
  last_update_range?: {
    start?: string;
    end?: string;
  };
  private_only?: boolean;
  sort_by?: string[];
  offset?: number;
  limit?: number;
};

export async function searchCollections(accessToken: string, query?: CollectionQuery) {
  const url = `${config.hostUrl}/api/v1/assetcollections`;

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: JSON.stringify(query),
  });
}

export type CreateCollectionData = {
  name: string;
  description?: string;
  group_access?: boolean;
};

export async function createCollection(
  accessToken: string,
  data: CreateCollectionData,
): Promise<FetchResult<CollectionResponse>> {
  const url = `${config.hostUrl}/api/v1/assetcollection`;
  const payload = JSON.stringify(data);

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

export type DuplicateCollectionData = {
  source_id: number;
  name?: string;
  description?: string;
  group_access?: boolean;
};

export async function duplicateCollection(
  accessToken: string,
  data: DuplicateCollectionData,
): Promise<FetchResult<CollectionResponse>> {
  const url = `${config.hostUrl}/api/v1/assetcollection`;
  const payload = JSON.stringify(data);

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

// export async function updateCollection(
// 	accessToken: string,
// 	data: string[],
// 	collectionId?: number,
// ): Promise<FetchResult<any>> {
// 	const url = collectionId ? `${config.hostUrl}/api/v1/collection/${collectionId}` : `${config.hostUrl}/api/v1/collection`;
// 	const payload = JSON.stringify(data);

// 	return performFetchWithLogging(url, {
// 		method: 'PUT',
// 		headers: getStandardHeaders(accessToken),
// 		credentials: 'include',
// 		body: payload,
// 	});
// }

export async function patchCollection(
  accessToken: string,
  data: { name?: string; additions?: string[]; deletions?: string[] },
  collectionId?: number,
): Promise<FetchResult<any>> {
  const url = collectionId ? `${config.hostUrl}/api/v1/assetcollection/${collectionId}` : `${config.hostUrl}/api/v1/assetcollection`;
  const payload = JSON.stringify(data);

  return performFetchWithLogging(url, {
    method: 'PATCH',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

export async function patchCollections(
  accessToken: string,
  data: { ids: number[]; name?: string; additions?: string[]; deletions?: string[] },
): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assetcollection`;
  const payload = JSON.stringify(data);

  return performFetchWithLogging(url, {
    method: 'PATCH',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

export async function getCollectionDownloadUrl(collectionId: number, accessToken: string) {
  const url = `${config.hostUrl}/api/v1/assetcollection/${collectionId}/download?as_url=y`;

  return performFetchWithLogging(
    url,
    {
      method: 'GET',
      headers: getStandardHeaders(accessToken),
      credentials: 'include',
    },
    'text',
  );
}

export function deleteCollection(collectionId: number, accessToken: string) {
  const url = `${config.hostUrl}/api/v1/assetcollection/${collectionId}`;

  return performFetchWithLogging(url, {
    method: 'DELETE',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}
