
import { useMemo } from 'react';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { queryFnFetchErrorWrapper } from '../utils/errorHandlers';
import { FetchError } from 'src/types/TApiQueries';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { queryNames } from 'src/api/utils/queryNames';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import { updateSubstitutionList, SubstitutionsList } from '../functions/toolsApi';


type UpdateSubstitutionMutation = UseMutationOptions<unknown, FetchError, unknown>

const useUpdateSubstitutionApiCall = () => {
  const accessToken = useAccessToken();
  const apiCall = (data: SubstitutionsList) => updateSubstitutionList(accessToken, data)
  const memoizedApiCall = useMemo(() => queryFnFetchErrorWrapper(apiCall), [accessToken]);
  return memoizedApiCall;
}

export const useUpdateSubstitutionMutation = (
  mutationOptions?: UpdateSubstitutionMutation
) => {

  const queryClient = useQueryClient();
  const alerts = useAlerts();
  const errorMessages = useErrorMessages();
  const mutationFn = useUpdateSubstitutionApiCall()

  return useMutation(mutationFn, { ...mutationOptions,
    onSuccess: function (...args){
      if (mutationOptions?.onSuccess)
        return mutationOptions?.onSuccess?.(...args);
      queryClient.invalidateQueries([queryNames.substitution]);
      // window.location.reload();
    },
    onError: function (...args) {
      const errorMessageString = args[0].message
      const errorMessage = errorMessages.getErrorMessage(errorMessageString);
      alerts.showAlert(errorMessage);
      mutationOptions?.onError?.(...args);
    },
  });
};