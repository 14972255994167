import * as React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { useAtom } from 'jotai';

import { filtersVisibleAtom } from 'src/shared/filters';
import { SlidersHorizontal, MagnifyingGlass, ArrowsClockwise } from 'src/common/icons';
import Button from 'src/components/Button';
import Input from 'src/components/Input';
import styles from './SearchBar.module.scss';
import messages from './messages';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { isRefetchingAtom, refreshAtom } from 'src/shared/refresh';
import Dropdown from '../Dropdown';
import { deletedAssetsAtom } from 'src/shared/deletedAssets';
import { TSorting, TSortingOptions } from 'src/types/TSorting';
import { SelectInputOption } from '../Dropdown/Dropdown';

type SearchBarProps = {
  className?: string;
  hideFilters?: boolean;
  search?: string;
  sortKeys?: TSortingOptions[];
  onSearch?: (searchTerm: string) => void;
  searchButton?: boolean;
  refreshButton?: boolean;
  filterButton?: boolean;
  dropDownOptions?: SelectInputOption[];
  onClickDropdown?: React.Dispatch<React.SetStateAction<string>>;
  dropDownSelectedKeys?: string;
  onClickSearchButton?: (searchTerm: string) => void;
  onSelectSort?: (sorting: TSorting) => void;
  onClickFiltersButton?: any;
};

const DEFAULT_DIRECTION = 'DESC';

export const SearchBar = ({
  className,
  hideFilters,
  search = '',
  sortKeys,
  searchButton,
  onSearch,
  onSelectSort,
  onClickSearchButton,
  onClickFiltersButton,
  refreshButton=true,
  filterButton=true,
  dropDownOptions,
  dropDownSelectedKeys,
  onClickDropdown,
  ...rest
}: SearchBarProps) => {
  const { formatMessage } = useIntl();

  const [searchTerm, setSearchTerm] = React.useState(search);
  const [sorting, setSorting] = React.useState<TSorting>([sortKeys? sortKeys[0].key:'', DEFAULT_DIRECTION]);

  const refreshUpdate = useUpdateAtom(refreshAtom);
  const isRefetching = useAtomValue(isRefetchingAtom);
  const [, updateDeletedAssets] = useAtom(deletedAssetsAtom);

  const handleRefresh = () => {
    updateDeletedAssets([]);
    refreshUpdate((c) => c + 1);
  };

  const onSort = (key: string) => {
    let newSorting: TSorting;

    if (sorting[0] === key) {
      newSorting = [key, sorting[1] === DEFAULT_DIRECTION ? 'ASC' : 'DESC'];
    } else {
      newSorting = [key, DEFAULT_DIRECTION];
    }

    setSorting(newSorting);
    onSelectSort && onSelectSort(newSorting);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    onSearch && onSearch(value);
  };

  const handleClickSearchButton = () => onClickSearchButton && onClickSearchButton(searchTerm);

  const handleKeyDown = (key: string) => key === 'Enter' && handleClickSearchButton();

  React.useEffect(() => {
    return () => {
      console.log('unmounting search component...');
    };
  }, []);

  React.useEffect(() => {
    setSearchTerm(search);
  }, [search]);

  return (
    <div className={cn([styles.SearchBar, className])}>
      {!hideFilters && (
        <>
          {filterButton?<Button icon={<SlidersHorizontal />} mode="flat" size="large" onClick={onClickFiltersButton} />:''}
          <div className={styles.searchInput}>
            <Input
              placeholder={formatMessage(messages.search)}
              icon={searchButton ? undefined : <MagnifyingGlass />}
              value={searchTerm}
              onChange={handleSearch}
              data-has-search-button={searchButton}
              onKeyDown={handleKeyDown}
            />
            {searchButton && (
              <button onClick={handleClickSearchButton}>
                <MagnifyingGlass />
              </button>
            )}
          </div>
        </>
      )}

      {
        refreshButton ?
        <Button
          className={styles.refetchButton}
          icon={<ArrowsClockwise />}
          onClick={handleRefresh}
          loading={isRefetching}
          mode="flat"
          tooltip={formatMessage(messages.refresh)}
        />:''
      }


      { sortKeys? 
        <Dropdown
        title="Sorting"
        className={styles.sortDropdown}
        isSortDropdown
        dropdownAnchor="right"
        sortDirection={sorting[1]}
        onClick={onSort}
        options={sortKeys.map((sortKey) => ({ ...sortKey, noBorderBottom: true }))}
        selectedKeys={[sorting[0]]}
      >
        Sort by: {sortKeys.find(({ key }) => key === sorting[0])?.value}
      </Dropdown>:''
      }
      {dropDownOptions?
        <Dropdown
            title="Filter Language"
            className={styles.sortDropdown}
            isSortDropdown
            dropdownAnchor="right"
            onClick={onClickDropdown}
            options={dropDownOptions}
            selectedKeys={[dropDownSelectedKeys]}
          >
            Filter by: {dropDownSelectedKeys}
        </Dropdown>:''
      }
    </div>
  );
};

export default SearchBar;
