import { createAtom } from './base';
import { getSettings } from './utils';

export const { currentSecuritySettingsAtom, updatedSecuritySettingsAtom, securitySettingsAtom } = createAtom(
  'security',
  {
    updater: (currentAtom: any, updatedAtom: any, get: any, set: any, action: any) => {
      const { payload } = action;

      const state = get(updatedAtom);

      set(updatedAtom, { ...state, ...payload });
    },
  },
);

export const getSecuritySettings = (get: any) =>
  getSettings(get, [securitySettingsAtom, updatedSecuritySettingsAtom], (state: any) => {
    const parsedState = state;
    delete parsedState.access_id;

    return parsedState;
  });
