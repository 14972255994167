import * as React from 'react';

function SvgGear(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M12 17.25a5.25 5.25 0 100-10.5 5.25 5.25 0 000 10.5z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 11.25l.967-1.45a.767.767 0 00.1-.643 9.676 9.676 0 00-.717-1.735.763.763 0 00-.526-.383l-1.71-.342-1.06-1.06-.342-1.71a.765.765 0 00-.383-.526 9.677 9.677 0 00-1.735-.718.768.768 0 00-.644.1l-1.45.967h-1.5l-1.449-.966a.767.767 0 00-.644-.1c-.6.182-1.181.422-1.735.717a.764.764 0 00-.383.525l-.342 1.71-1.06 1.06-1.71.343a.763.763 0 00-.526.383 9.675 9.675 0 00-.717 1.735.767.767 0 00.1.644l.966 1.449v1.5l-.966 1.45a.768.768 0 00-.1.643c.182.6.422 1.182.717 1.735a.763.763 0 00.525.383l1.71.342 1.061 1.061.342 1.71a.764.764 0 00.383.525 9.668 9.668 0 001.735.718.769.769 0 00.644-.1l1.45-.967h1.5l1.449.966a.768.768 0 00.644.1 9.679 9.679 0 001.735-.716.764.764 0 00.383-.526l.342-1.71 1.06-1.06 1.71-.342a.765.765 0 00.526-.384 9.679 9.679 0 00.717-1.735.768.768 0 00-.1-.643l-.967-1.45v-1.5z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgGear;
