import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { useAssetMetadataMutation } from 'src/api/mutations/assetMutations';
import { useAssetSchemaQuery } from 'src/api/queries/schemesQueries';
import { queryNames } from 'src/api/utils/queryNames';
import { useAlerts } from 'src/common/AlertManager';
import { Pencil } from 'src/common/icons';
import { Asset } from 'src/types/TAssets';

import styles from '../Modal.module.scss';
import messages from '../messages';
import MetadataForm from 'src/components/MetadataForm';

import AssetMetadataSetup from './AssetMetadataSetup';
import { isEmpty } from 'lodash';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import useRoleManager from 'src/hooks/useRoleManager';

type TAssetMetadata = {
  asset: Asset;
};


const renderMetadata = (isUser: any, schema: any, metadata: any, onEdit: any) => {

  return (
    <>
      <div className={styles.title}>
        {(schema as any)?.title ?? <FormattedMessage {...messages.customMetadata} />}
        {isUser ? (<></>) : (
          <button className={styles.edit} onClick={onEdit}>
            <Pencil />
          </button>
        )}
      </div>
      {Object.entries(metadata).map(([k, v]) => (
        <div className={styles.metaField} key={k}>
          <p>{k}</p>
          <div>{String(v)}</div>
        </div>
      ))}
    </>
  );
};

const renderEditMetadata = (schema: any, metadata: any, onSubmit: any, onCancel: any) => {
  return <MetadataForm schema={schema} metadata={metadata} onSubmit={onSubmit} onCancel={onCancel} />;
};

export const AssetMetadata: React.FC<TAssetMetadata> = ({ asset }) => {

  const metadata = asset?.data ?? {};
  const data_schema_id = asset.data_schema_id ?? 0;

  const [metadataEditMode, setMetadataEditMode] = useState(false);
  const [schema, setSchema] = useState();

  const queryClient = useQueryClient();
  const { showSuccess, showAlert } = useAlerts();

  const { getErrorMessage } = useErrorMessages();
  const { isUser } = useRoleManager();
  const metadataMutation = useAssetMetadataMutation(asset.id, {
    onError: (error, newData, context: any) => {
      queryClient.setQueryData([queryNames.asset, asset.id], context.previousData);
      // TODO: Handle error, show feedback
      Sentry.captureMessage(
        `Failed metadata mutation for asset ${asset.id} with error: ${error.message}`,
        Sentry.Severity.Error,
      );

      showAlert(getErrorMessage('system.errorMessages.unknownError'));
    },
    onMutate: (updated_data) => {
      let data = { ...updated_data.data, data_schema_id: updated_data.data_schema_id }

      setMetadataEditMode(false);
      const previousData = queryClient.getQueryData<Asset>([queryNames.asset, asset.id]);
      queryClient.setQueryData<Asset | undefined>([queryNames.asset, asset.id], (old) => {
        if (typeof old !== 'undefined') {
          old.data = data;
        }
        return old;
      });

      showSuccess('Asset metadata updated sucessfully');

      return { previousData };
    },
  });


  // query the metadata
  const {
    data: schemaData,
    error: schemaError,
    isLoading: isSchemaLoading,
  } = useAssetSchemaQuery(data_schema_id,
    {
      staleTime: 1000 * 60 * 5,
      refetchOnMount: true,
    },
    { enabled: !!data_schema_id },
  );



  useEffect(() => {
    if (!schemaData) return;

    const { $schema, ...data } = schemaData?.schema ?? { schema: {} };

    setSchema(data);
  }, [schemaData]);

  const submitMetadata = async (schemaObject: any) => {
    console.log(schemaObject, data_schema_id);
    await metadataMutation.mutateAsync({
      data: schemaObject?.formData,
      mode: 'REPLACE',
      data_schema_id
    });
  };

  const submitSchema = async (schemaObject: any, schemaId: number) => {
    console.log(schemaObject, schemaId);
    await metadataMutation.mutateAsync({
      data: schemaObject?.formData,
      mode: 'REPLACE',
      data_schema_id: schemaId
    });
  };

  if (!schema && isEmpty(metadata)) {
    return (
      <div className={styles.metadataWrapper}>
        <AssetMetadataSetup onSubmitSchema={submitSchema} asset={asset} />
      </div>
    );
  }

  return (
    <div className={styles.metadataWrapper}>
      {!metadataEditMode && renderMetadata(isUser, schema, metadata, () => setMetadataEditMode(true && !isUser))}
      {metadataEditMode && renderEditMetadata(schema, metadata, submitMetadata, () => setMetadataEditMode(false))}
    </div>
  );
};
