import { useContext } from 'react';
import { Button } from 'src/components';
import { OnboardingActionType, OnboardingContext, useOnboardingContext } from '../context';
import styles from '../styles.module.scss';

type StepBarProps = {
  onGoToPreviousStep: () => void;
  onGoToNextStep: () => void;
  loadingNextStep?: boolean;
  loadingPreviousStep?: boolean;
  hasUnsavedChanges?: boolean;
};

const StepBar = ({
  onGoToNextStep,
  onGoToPreviousStep,
  loadingNextStep,
  loadingPreviousStep,
  hasUnsavedChanges,
}: StepBarProps) => {
  const { currentStep, totalSteps, stepTitle, isFirstStep, isLastStep, goToNextStep, skippable } =
    useOnboardingContext();

  return (
    <nav className={styles.stepBar}>
      <div>
        <Button
          mode="tertiary"
          size="small"
          onClick={onGoToPreviousStep}
          disabled={isFirstStep}
          loading={loadingPreviousStep}
        >
          Previous Step
        </Button>
      </div>

      <div className={styles.stepInfo}>
        <span>{`step ${currentStep} of ${totalSteps}`}</span>
        <h3>{stepTitle}</h3>
      </div>

      <div>
        {skippable && (
          <Button mode="tertiary" size="small" onClick={goToNextStep}>
            Skip this step
          </Button>
        )}
        <Button size="small" onClick={onGoToNextStep} loading={loadingNextStep} disabled={!hasUnsavedChanges}>
          {isLastStep ? 'Save and finish' : 'Save and Go to Next Step'}
        </Button>
      </div>
    </nav>
  );
};

export default StepBar;
