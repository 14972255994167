import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ArrowLeftCircle } from 'src/common/icons';
import { Button, Input } from 'src/components';

import styles from '../Modal.module.scss';

import messages from '../messages';

type TAssetUpdatedConfirmationDialog = {
  onReplaceOriginal: () => void;
  onCreateConversion: (conversionName: string) => void;
  onDownloadConversion: () => void;
  onCancel: () => void;
  assetType: 'original' | 'conversion';
};

export const AssetUpdatedDialog: React.FC<TAssetUpdatedConfirmationDialog> = ({
  onCreateConversion,
  onReplaceOriginal,
  onDownloadConversion,
  onCancel,
  assetType,
}) => {
  const [showConversionOptions, setShowConversionOptions] = useState(false);
  const [conversionName, setConversionName] = useState('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleCreateConversion = () => {
    if (!conversionName) {
      setError('Conversion name is required');
      return;
    }

    if (conversionName.length > 20) {
      setError('Conversion name must be 20 characters or less');
      return;
    }

    if (!/^[a-zA-Z0-9_-]{1,}$/.test(conversionName)) {
      setError('Conversion name must be alphanumeric and can contain "-" and "_"');
      return;
    }

    onCreateConversion(conversionName);
    setLoading(true);
  };

  const handleDownloadConversion = () => {
    setLoading(true);
    onDownloadConversion();
  };

  return (
    <div className={styles.AssetUpdatedConfirmationDialogContainer}>
      <div className={styles.AssetUpdatedConfirmationDialog}>
        {showConversionOptions ? (
          <>
            <Button icon={<ArrowLeftCircle />} mode="flat" size="small" onClick={() => setShowConversionOptions(false)}>
              <FormattedMessage {...messages.goBack} />
            </Button>
            <div className={styles.conversionForm}>
              <Input
                type="text"
                variant="secondary"
                value={conversionName}
                onChange={(value) => setConversionName(value)}
                placeholder="Type a name for the conversion"
                label="Converesion Name"
                className={styles.conversionFormInput}
                error={error}
              />

              <Button type="button" mode="primary" size="small" onClick={handleCreateConversion} loading={loading}>
                <FormattedMessage {...messages.saveConversion} />
              </Button>
            </div>
          </>
        ) : (
          <>
            <h3>
              <FormattedMessage {...messages.assetUpdateDialogTitle} />
            </h3>
            <div className={styles.AssetDialogButtons}>
              <Button
                type="button"
                mode="decline"
                size="small"
                onClick={() => {
                  setLoading(true);
                  onReplaceOriginal();
                }}
                loading={loading}
              >
                <FormattedMessage
                  {...messages.replaceOriginalImage}
                  values={{ assetType: assetType === 'original' ? 'Image' : 'Conversion' }}
                />
              </Button>
              <Button type="button" mode="primary" size="small" onClick={() => setShowConversionOptions(true)}>
                <FormattedMessage {...messages.addAsAConversion} />
              </Button>

              <Button type="button" mode="tertiary" size="small" onClick={handleDownloadConversion} loading={loading}>
                <FormattedMessage {...messages.downloadEditedCopy} />
              </Button>

              <Button icon={<ArrowLeftCircle />} mode="flat" size="small" onClick={onCancel}>
                <FormattedMessage {...messages.cancel} />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
