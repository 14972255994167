import * as React from 'react';

function SvgWrench(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M17.343 3.475L13.41 7.41l.53 2.652 2.652.53 3.934-3.934h0a6.002 6.002 0 01-8.426 7.597h0L6.841 20.34a2.25 2.25 0 01-3.182-3.182l6.088-5.258h0a6.003 6.003 0 017.596-8.426h0z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgWrench;
