import * as React from 'react';
import cn from 'classnames';
import styles from './AssetPreview.module.scss';
import messages from './messages';
import { File, FileArrowDown, MusicNotes, VideoCamera } from 'src/common/icons';
import SvgImageSquare from 'src/common/icons/ImageSquare';

type AssetPreviewProps = {
  className?: string;
  status: string;
  src: string;
  name: string;
  contentType: string;
};



export const AssetPreview: React.FC<AssetPreviewProps> = ({ name, src, className, contentType, status, ...rest }) => {
  if (status !== 'NOT_READY') return <img src={src} alt={name} className={className} />;

  const [mimeType] = contentType.toLowerCase().split('/');

  switch (mimeType) {
    case 'image':
      return <SvgImageSquare className={cn(styles.icon, className)} />;

    case 'video':
      return <VideoCamera className={cn(styles.icon, className)} />;

    case 'audio':
      return <MusicNotes className={cn(styles.icon, className)} />;
    default:
      return <File className={cn(styles.icon, className)} />;
  }
};

export default AssetPreview;
