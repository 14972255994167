import * as React from 'react';
import cn from 'classnames';
import styles from './Textarea.module.scss';
import messages from './messages';
import { v4 as uuidv4 } from 'uuid';

type TextareaProps = {
  className?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  label?: string;
  value?: string;
  rows?: number;
  cols?: number;
  error?: string;
  disabled?: boolean;
};

export const Textarea = ({
  className,
  value,
  onChange,
  rows,
  cols,
  placeholder,
  label,
  error,
  disabled,
  ...rest
}: TextareaProps) => {
  const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => onChange && onChange(e.target.value);

  const id = uuidv4();

  return (
    <div className={cn([styles.Textarea, className])}>
      <label htmlFor={id}>{label}</label>
      <textarea
        id={id}
        value={value}
        onChange={handleOnChange}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        data-has-error={!!error}
        disabled={disabled}
        {...rest}
      ></textarea>
      {error && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default Textarea;
