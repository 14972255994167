import cn from 'classnames';
import * as React from 'react';
import styles from './DropDown.module.scss';
import { CaretDown } from 'src/common/icons';

export type SelectInputOption = {
  key: string | number;
  value: string | number | React.ReactNode;
  optionDisabled?: boolean;
};

type NewDropdownProps = {
  options: Array<SelectInputOption>;
  selectedKeys: any[];
  onClick: (targetKey: any) => void;
  placeholder?: string;
};

export const CheckUsageDropDown: React.FC<NewDropdownProps> = ({
  options = [],
  selectedKeys = [],
  onClick,
  placeholder = 'Select an option',
}) => {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const toggleRef = React.useRef<HTMLButtonElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [menuWidth, setMenuWidth] = React.useState<number | null>(null);

  React.useEffect(() => {
    const handleResize = () => {
      if (open && toggleRef.current && menuRef.current) {
        const toggleWidth = toggleRef.current.offsetWidth;
        const menuWidth = menuRef.current.scrollWidth;
        setMenuWidth(Math.max(toggleWidth, menuWidth));
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [open, options]);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const selectedOption = options.find(option => selectedKeys.includes(option.key));

  return (
    <div
      className={styles.newDropdown}
      ref={ref}
      onKeyDown={handleKeyDown}
    >
      <button
        onClick={() => setOpen(!open)}
        ref={toggleRef}
        className={styles.dropdownToggle}
      >
        <span>{selectedOption ? selectedOption.value : placeholder}</span>
        <span className={styles.arrow}>
          {open ? <CaretDown /> : <CaretDown />}
        </span>
      </button>
      {open && (
        <div
          ref={menuRef}
          style={{ width: menuWidth ? `${menuWidth}px` : 'auto' }}
          className={cn(styles.dropdownMenu, { [styles.open]: open })}
        >
          {options.length > 0 ? (
            options.map(({ key, value, optionDisabled }) => (
              <div
                key={key}
                className={cn(styles.dropdownItem, {
                  [styles.selected]: selectedKeys.includes(key),
                  [styles.disabled]: optionDisabled,
                })}
                onClick={() => {
                  if (!optionDisabled) {
                    onClick(key);
                    setOpen(false);
                  }
                }}
              >
                {value}
              </div>
            ))
          ) : (
            <div className={styles.dropdownItem}>
              No options available
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckUsageDropDown;
