import cn from 'classnames';
import * as React from 'react';
import { useEffect } from 'react';
// import { AlertTriangle, Check, X } from 'src/common/icons';
import Button from '../Button';
import styles from './Input.module.scss';

// TODO: Replace with real icons

const AlertTriangle = ({ className, onClick }: { className?: string; onClick?: () => void }) => <i />;
const Check = ({ className }: { className: string }) => <i />;
const X = ({ className }: { className: string }) => <i />;

type InputProps = {
  placeholder?: string;
  type?: string;
  label?: string;
  icon?: React.ReactElement;
  limit?: number;
  error?: string;
  disabled?: boolean;
  editable?: boolean;
  showControls?: boolean;
  initialFocus?: boolean;
  onChange?: (value: string) => void;
  onEnter?: (value: string) => void;
  onSuccess?: () => void;
  onCancel?: () => void;
  onLimitClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (key: string) => void;
  className?: string;
  value?: string | number;
  min?: number;
  max?: number;
  step?: number;
  variant?: 'primary' | 'secondary';
};

export const Input: React.FC<InputProps> = ({
  placeholder = 'Start typing...',
  type = 'text',
  label,
  icon,
  limit,
  error = null,
  disabled = false,
  editable = false,
  showControls = false,
  initialFocus = false,
  onChange = () => {},
  onEnter = () => {},
  onSuccess = () => {},
  onCancel = () => {},
  onLimitClick = () => {},
  onFocus,
  onBlur,
  onKeyDown,
  className,
  value,
  min,
  max,
  step,
  children,
  variant = 'primary',
  ...rest
}) => {
  const characterOverLimit = limit ? String(value).length > limit : false;
  const limitNumber = limit ? String(value).length - limit : undefined;
  const inputElement = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initialFocus) {
      inputElement?.current?.focus();
    }
  }, []);

  const handleKeyDown = ({ key, target }: React.KeyboardEvent) => {
    if (onKeyDown) {
      onKeyDown(key);
    }
    if (key === 'Enter') {
      onEnter((target as HTMLInputElement).value);
      inputElement?.current?.blur();
    }
  };

  const handleOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(target.value);
  };

  return (
    <div className={styles.inputWrapper}>
      <label>
        {label && <p>{label}</p>}
        <input
          ref={inputElement}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={cn([
            styles.Input,
            styles[variant],
            className,
            {
              [styles.error]: error,
              [styles.inputWithIcon]: icon,
            },
          ])}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          min={min}
          max={max}
          step={step}
          {...rest}
        />

        {icon && (
          <span
            className={cn([styles.inputIcon, { [styles.inputIconDisabled]: disabled, [styles.inputIconError]: error }])}
          >
            {icon}
          </span>
        )}
      </label>

      {children}

      {(editable || limit) && showControls && (
        <div className={styles.actionWrapper}>
          {editable && (
            <div
              aria-label="Editable Controls"
              className={cn([styles.editContainer, { [styles.active]: showControls }])}
            >
              <Button
                aria-label="success"
                mode="flat"
                className={styles.editButton}
                icon={<Check className={cn([styles.editIcon, styles.checkIcon])} />}
                onClick={onSuccess}
              />
              <Button
                aria-label="cancel"
                mode="flat"
                className={styles.editButton}
                icon={<X className={cn([styles.editIcon, styles.xIcon])} />}
                onClick={onCancel}
              />
            </div>
          )}

          {limit && (
            <div className={styles.limitContainer}>
              <span
                className={cn(styles.limitWrapper, {
                  [styles.warning]: characterOverLimit,
                })}
              >
                {characterOverLimit && <AlertTriangle onClick={onLimitClick} />}
                <span className={styles.limitText}>{`${characterOverLimit ? `+${limitNumber}` : -limitNumber!}`}</span>
              </span>
            </div>
          )}
        </div>
      )}

      {error && (
        <div className={styles.errorContainer}>
          <AlertTriangle className={styles.errorIcon} />
          <span className={styles.errorText}>{error}</span>
        </div>
      )}
    </div>
  );
};

export default Input;
