import { Loader } from 'src/components';
import BaseLayout from './BaseLayout';

const Loading = () => {
  return (
    <BaseLayout>
      <h1>Please wait...</h1>
      <Loader />
    </BaseLayout>
  );
};

export default Loading;
