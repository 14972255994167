import * as React from 'react';

function SvgCheckboxEmpty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <rect opacity={0.2} x={0.5} y={1} width={23} height={23} rx={2.5} stroke="currentColor" />
    </svg>
  );
}

export default SvgCheckboxEmpty;
