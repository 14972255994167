import * as React from 'react';
import cn from 'classnames';
import styles from './Paper.module.scss';
import messages from './messages';

type PaperProps = {
  className?: string;
};

export const Paper: React.FC<PaperProps> = ({ children, className, ...rest }) => {
  return <div className={cn([styles.Paper, className])}>{children}</div>;
};

export default Paper;
