import { atom, WritableAtom } from 'jotai';
import { isEmpty, isNil, omitBy } from 'lodash';
import { addonsSettingsAtom, getAddonsSettings, updatedAddonsSettingsAtom } from './addons';
import { ACTION_TYPES } from './base';
import { databaseSettingsAtom, getDatabaseSettings, updatedDatabaseSettingsAtom } from './database';
import { getImageSettings, imageSettingsAtom, updatedImageSettingsAtom } from './image';
import { getSecuritySettings, securitySettingsAtom, updatedSecuritySettingsAtom } from './security';
import { getStorageSettings, storageSettingsAtom, updatedStorageSettingsAtom } from './storage';
import { getVideoSettings, updatedVideoSettingsAtom, videoSettingsAtom } from './video';
import { getWatermarkSettings, updatedWatermarkSettingsAtom, watermarkSettingsAtom } from './watermark';
import { webhookSettingsAtom, updatedWebhookSettingsAtom, getWebhookSettings } from './webhook';




type Atom = {

  // Name of the settings group
  name: string;

  // Atom that holds the new settings
  updatedAtom: WritableAtom<any, any, void>;

  // Atom that holds the current settings
  combinedAtom: WritableAtom<any, any, void>;

  // Function to get the current settings
  getter: (get: any) => any;
};


type Dict<T> = {

  // Entry in the dictionary
  [key: string]: T;

};


/**
 * The list of all settings atoms and their getters.
 * 
 * @type {Atom[]}
 * 
 * 
 */
const atomsList: Atom[] = [
  {
    name: 'addons',
    updatedAtom: updatedAddonsSettingsAtom,
    combinedAtom: addonsSettingsAtom,
    getter: getAddonsSettings,
  },
  {
    name: 'database',
    updatedAtom: updatedDatabaseSettingsAtom,
    combinedAtom: databaseSettingsAtom,
    getter: getDatabaseSettings,
  },
  {
    name: 'image',
    updatedAtom: updatedImageSettingsAtom,
    combinedAtom: imageSettingsAtom,
    getter: getImageSettings,
  },
  {
    name: 'auth',
    updatedAtom: updatedSecuritySettingsAtom,
    combinedAtom: securitySettingsAtom,
    getter: getSecuritySettings,
  },
  {
    name: 'storage',
    updatedAtom: updatedStorageSettingsAtom,
    combinedAtom: storageSettingsAtom,
    getter: getStorageSettings,
  },
  {
    name: 'video',
    updatedAtom: updatedVideoSettingsAtom,
    combinedAtom: videoSettingsAtom,
    getter: getVideoSettings,
  },
  {
    name: 'watermark',
    updatedAtom: updatedWatermarkSettingsAtom,
    combinedAtom: watermarkSettingsAtom,
    getter: getWatermarkSettings,
  },
  {
    name: 'webhook',
    updatedAtom: updatedWebhookSettingsAtom,
    combinedAtom: webhookSettingsAtom,
    getter: getWebhookSettings,
  },
];


export const settingsAtom = atom<any, any>(



  /**
   * 
   * @param get 
   * @returns 
   */
  (get) => {
    let state = {};

    atomsList.forEach((atom) => {
      const { name, combinedAtom } = atom;
      const atomState = get(combinedAtom);
      state = { ...state, [name]: atomState };
    });

    state = omitBy(state, isNil);
    return isEmpty(state) ? null : state;
  },



  /**
   * 
   * @param get
   * @param set
   * @param action
   * 
   * @returns 
   */
  (get, set, action) => {
    const { type, payload } = action;

    atomsList.forEach((atom) => {
      const { name, combinedAtom } = atom;

      if (
        type === ACTION_TYPES.SAVE_CHANGES ||
        type === ACTION_TYPES.UNDO_CHANGES ||
        type === ACTION_TYPES.CLEAR_STATE
      ) {
        set(combinedAtom, { type });
        return;
      }

      if (!payload[name]) return;

      set(combinedAtom, {
        type: type,
        payload: payload[name],
      });
    });
  },

);



/**
 * 
 * @name updatedSettingsAtom
 * 
 * @description
 * This atom is used to store the updated settings.
 * 
 */
export const updatedSettingsAtom = atom<any>(


  // This function is used to get the updated settings.
  (get) => {
    let state: Dict<unknown> = {};
    let numberOfValues = 0

    for (const atom of atomsList) {
      const atomState = atom.getter(get);
      if (isNil(atomState)) continue;
      state[atom.name] = atomState;
      numberOfValues += 1;
    }

    return numberOfValues === 0 ? null : state;
  }

);



