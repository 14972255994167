import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import React, { useState, useMemo, useReducer, useEffect, useRef } from 'react';
import {
  CollectionCard,
  PageTitle,
  Tag,
  Button,
  LayoutView,
  CollectionRow,
  LayoutViewSwitcher,
  Dropdown,
  SearchBar,
  SearchFiltersSidebar,
  Pager,
  CreateCollectionModal,
} from 'src/components';
import { useSearchCollectionsQuery } from 'src/api/queries/collectionsQueries';

import { Collection } from 'src/types/TCollection';
import styles from './Collections.module.scss';
import messages from './messages';

import useModal from 'src/hooks/useModal';

import { CollectionQuery } from 'src/api/functions/collectionsApi';
import { sortAtom } from 'src/shared/sort';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { filtersVisibleAtom } from 'src/shared/filters';
import { useAtom } from 'jotai';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { isRefetchingAtom, refreshAtom } from 'src/shared/refresh';
import { searchAtom } from 'src/shared/search';
import { TSorting } from 'src/types/TSorting';

const initialSearchQuery: CollectionQuery = {
  sort_by: ['last_update DESC'],
  offset: 0,
  limit: 30,
};

const searchQueryReducer = (state: CollectionQuery, payload: CollectionQuery) => ({ ...state, ...payload });

export const Collections = () => {
  const [itemsPerPage, setItemsPerPage] = useLocalStorage('collections.itemsPerPage', '30');
  const [sorting, setSorting] = useState<TSorting>(['last_update', 'DESC']);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const refreshValue = useAtomValue(refreshAtom);
  const setIsRefetching = useUpdateAtom(isRefetchingAtom);

  const [searchQuery, dispatchSearchQuery] = useReducer(searchQueryReducer, initialSearchQuery, (state) => {
    const limit = Number(itemsPerPage);
    return { ...state, limit: limit };
  });

  const currentPage = useMemo(() => {
    if (!searchQuery.offset || !searchQuery.limit) return 1;

    return Math.floor(searchQuery.offset / searchQuery.limit) + 1;
  }, [searchQuery]);

  const {
    data: allCollectionsResult,
    refetch,
    isLoading,
    isRefetching,
    error,
  } = useSearchCollectionsQuery(searchQuery, {
    onSuccess: (results) => {
      // TODO: handle pagination
      setTotalPages(Math.ceil(results.data.total_collections / (searchQuery.limit ?? 1)));
    },
  });

  const filtersSidebarRef = useRef<HTMLDivElement>(null);

  const { showModal } = useModal();

  const handleOnCreateCollection = () => window.location.reload();

  const showCreateCollectionModal = () =>
    showModal(<CreateCollectionModal onCreateCollection={handleOnCreateCollection} />);

  const handleSearchCollection = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    dispatchSearchQuery({
      keyword: searchTerm,
    });
  };

  const handleApplyFilters = (filters: any) => {
    dispatchSearchQuery({
      ...filters,
    });
    setFiltersVisible(false);
  };

  const handlePageChange = (page: number) => {
    dispatchSearchQuery({
      offset: (page - 1) * (searchQuery.limit ?? 30),
    });
  };

  const handleItemsPerPageChange = (newLimit: number) => {
    setItemsPerPage(newLimit.toString());
    dispatchSearchQuery({
      limit: newLimit,
      offset: 0,
    });
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    dispatchSearchQuery({
      keyword: '',
    });
  };

  const handleClearDateFilter = (date: 'start' | 'end') => {
    const oppositeDate = date === 'start' ? 'end' : 'start';

    dispatchSearchQuery({
      last_update_range: {
        [date]: undefined,
        [oppositeDate]: searchQuery.last_update_range?.[oppositeDate],
      },
    });
  };

  useEffect(() => {
    dispatchSearchQuery({
      sort_by: [sorting.join(' ')],
    });
  }, [sorting]);

  useEffect(() => {
    if (isRefetching) return;
    refetch();
  }, [refreshValue]);

  useEffect(() => {
    setIsRefetching(isRefetching);
  }, [isRefetching]);

  return (
    <div className={styles.pageWrapper}>
      <PageTitle
        title={'Collections'}
        className={styles.pageTitle}
        counter={allCollectionsResult?.data?.total_collections}
        rightContainer={
          <Button size="small" onClick={() => showCreateCollectionModal()} data-test="add-collection">
            <FormattedMessage {...messages.add} />
          </Button>
        }
      />
      <SearchBar
        search={searchTerm}
        sortKeys={[
          { key: 'last_update', value: 'Last Update' },
          { key: 'name', value: 'Name' },
          { key: 'group_access', value: 'Private' },
        ]}
        onSelectSort={setSorting}
        searchButton
        onClickSearchButton={handleSearchCollection}
        onClickFiltersButton={() => setFiltersVisible(!filtersVisible)}
      />
      <section className={styles.pageContainer}>
        {filtersVisible && (
          <div ref={filtersSidebarRef} className={styles.filterSidebarWrapper}>
            <SearchFiltersSidebar
              onApplyFilters={handleApplyFilters}
              filters={[
                {
                  title: 'Collection Access',
                  key: 'private_only',
                  inputs: {
                    type: 'checkbox',
                    label: 'Private Only',
                    initialValue: searchQuery.private_only,
                  },
                },
                {
                  title: 'Last Updated Date Range',
                  key: 'last_update',
                  inputs: [
                    {
                      key: 'start',
                      type: 'date',
                      label: 'Start Date',
                      initialValue: searchQuery.last_update_range?.start,
                    },
                    {
                      key: 'end',
                      type: 'date',
                      label: 'End Date',
                      initialValue: searchQuery.last_update_range?.end,
                    },
                  ],
                },
              ]}
            />
          </div>
        )}

        <div className={cn([styles.pageContent])}>
          <div className={styles.actionsWrapper}>
            <div className={styles.tags}>
              {searchQuery.keyword !== '' && (
                <>
                  {searchQuery.keyword && searchQuery.keyword !== '' && (
                    <Tag text={searchQuery.keyword} onClose={handleClearSearch} />
                  )}
                  {searchQuery.private_only && (
                    <Tag text="Private Only" onClose={() => dispatchSearchQuery({ private_only: false })} />
                  )}
                  {searchQuery.last_update_range && (
                    <>
                      {searchQuery.last_update_range.start && (
                        <Tag
                          text={`Start Date: ${searchQuery.last_update_range.start}`}
                          onClose={() => handleClearDateFilter('start')}
                        />
                      )}
                      {searchQuery.last_update_range.end && (
                        <Tag
                          text={`End Date: ${searchQuery.last_update_range.end}`}
                          onClose={() => handleClearDateFilter('end')}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div>
              <LayoutViewSwitcher />
            </div>
          </div>
          <LayoutView
            emptyDataMessage="No collections found"
            isLoading={isLoading || isRefetching}
            data={allCollectionsResult?.data?.collections}
            cardView={(collection: Collection) => <CollectionCard key={collection.id} collection={collection} />}
            listView={(collection: Collection) => <CollectionRow key={collection.id} collection={collection} />}
            classNames={{
              cardView: 'listingGridWrapper',
              listView: styles.listWrapper,
            }}
          />

          {allCollectionsResult?.data?.total_collections > Number(itemsPerPage) && (
            <div className={styles.pagerWrapper}>
              <Pager
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={handlePageChange}
                itemsPerPage={Number(itemsPerPage)}
                onItemsPerPageChange={handleItemsPerPageChange}
                showItemsPerPage={true}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};
