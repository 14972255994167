import { defineMessages } from 'react-intl';
export default defineMessages({
  search: {
    id: 'comp.SearchBar.search',
    defaultMessage: 'Search',
  },
  basket1: {
    id: 'comp.SearchBar.basketTop',
    defaultMessage: 'ASSETS IN',
  },
  basket2: {
    id: 'comp.SearchBar.basketBottom',
    defaultMessage: 'BASKET',
  },
  refresh: {
    id: 'comp.SearchBar.refresh',
    defaultMessage: 'Refresh page for updates',
  },
  trash: {
    id: 'comp.SearchBar.trash',
    defaultMessage: 'Assets in Recycle bin',
  },
  download: {
    id: 'comp.SearchBar.download',
    defaultMessage: 'Download basket assets',
  },
  basket: {
    id: 'comp.SearchBar.basket',
    defaultMessage: 'Assets in Basket',
  },
});
