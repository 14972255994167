import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './FileUpload.module.scss';
import messages from './messages';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { CloudArrowUp } from 'src/common/icons';
import { humanFileSize } from 'src/utils/transform';
import { useAlerts } from 'src/common/AlertManager';

type FileUploadProps = {
  className?: string;
  options?: DropzoneOptions;
  onDrop: (files: File[]) => void;
};

export const FileUpload = ({ className, options, onDrop }: FileUploadProps) => {
  const { showAlert } = useAlerts();

  const onDropFile = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 0) {
      // TODO: Improve error handling
      const [file] = rejectedFiles;
      showAlert(file.errors[0].message);
    }

    if (acceptedFiles.length > 0) {
      onDrop(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropFile,
    ...options,
  });

  const acceptedMedia = useMemo(() => {
    if (typeof options?.accept === 'string') {
      return options?.accept.split('/')[0];
    }

    if (options?.accept instanceof Array) {
      return options?.accept.map((accept) => accept.split('/')[0]).join(', ');
    }

    return options?.maxFiles === 1 ? 'file' : 'files';
  }, [options]);

  const acceptedFormats = useMemo(() => {
    if (typeof options?.accept === 'string') {
      return options?.accept.split('/')[1].replace('*', 'any');
    }

    if (options?.accept instanceof Array) {
      return options?.accept.map((accept) => accept.split('/')[1]).join(', ');
    }

    return 'any';
  }, [options]);

  return (
    <div className={cn(styles.FileUpload, className, isDragActive ? styles.dragging : undefined)} {...getRootProps()}>
      <input {...getInputProps()} />
      <CloudArrowUp />
      <h4>
        Drag your {acceptedMedia} here or <span>browse</span>
      </h4>
      <h5>
        max size {humanFileSize(options?.maxSize as number)} - {acceptedFormats}
      </h5>
    </div>
  );
};

export default FileUpload;
