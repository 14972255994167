import { defineMessages } from 'react-intl';
export default defineMessages({
	assets: {
		id: 'nav.assets',
		defaultMessage: 'Assets',
	},
	collections: {
		id: 'nav.collections',
		defaultMessage: 'Collections',
	},
	assetTransfers: {
		id: 'nav.assetTransfers',
		defaultMessage: 'Asset Transfers',
	},
	account: {
		id: 'nav.account',
		defaultMessage: 'Account',
	},
	preferences: {
		id: 'nav.preferences',
		defaultMessage: 'Preferences',
	},
	logout: {
		id: 'nav.logout',
		defaultMessage: 'Logout',
	},
});
