import * as React from 'react';
import cn from 'classnames';
import styles from './SettingsNavigation.module.scss';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import Button from 'src/components/Button';
import { useSettingsTemplateMutation } from 'src/api/mutations/userMutations';
import { useSettingsQuery } from 'src/api/queries/userQueries';


// import Dialog from './Dialog';

/*
interface CustomNavLinkProps {
  children: React.ReactNode;
  to: string;
  // onClick: () => void;
}


const CustomNavLink = (props: CustomNavLinkProps) => {

  const [showDialog, setShowDialog] = React.useState(false);

  const handleClick = (e: any) => {
    e.preventDefault();
    setShowDialog(true);
  }

  const handleDialogClick = async (clickedYes: boolean) => {

    if (clickedYes) {

    }

    setShowDialog(false);
  }

  if (showDialog) {
    return <Dialog
      word="a"
      onClick={handleDialogClick}
      message="changes, are you sure you want to continue?"
    />
  }

  return (
    <>
      <NavLink onClick={handleClick} to={props.to} activeClassName={styles.active}>
        {props.children}
      </NavLink>
    </>
  )
}

*/



type SettingsNavigationProps = {
  className?: string;
};

export const SettingsNavigation: React.FC<SettingsNavigationProps> = ({ className }) => {
  const settingsMutation = useSettingsTemplateMutation();

  const handleSaveTemplate = async () => {
    await settingsMutation.mutateAsync(null);
  };

  const { isLoading, data } = useSettingsQuery();
  return (
    <div className={cn([styles.SettingsNavigation, className])}>
      <nav>
        <NavLink to={'/admin/settings/storage'} >
          <FormattedMessage {...messages.storage} />
        </NavLink>
        <NavLink to={'/admin/settings/security'} activeClassName={styles.active}>
          <FormattedMessage {...messages.security} />
        </NavLink>
        <NavLink to={'/admin/settings/image'} activeClassName={styles.active}>
          <FormattedMessage {...messages.image} />
        </NavLink>
        <NavLink to={'/admin/settings/video'} activeClassName={styles.active}>
          <FormattedMessage {...messages.video} />
        </NavLink>
        <NavLink to={'/admin/settings/webhook'} activeClassName={styles.active}>
          <FormattedMessage {...messages.webhook} />
        </NavLink>
        <NavLink to={'/admin/settings/database'} activeClassName={styles.active}>
          <FormattedMessage {...messages.database} />
        </NavLink>
        <NavLink to={'/admin/settings/addons'} activeClassName={styles.active}>
          <FormattedMessage {...messages.addons} />
        </NavLink>
        {!isLoading && data?.addons?.AUDIO_SUBTITLES?.added === true ?
          <NavLink to={'/admin/settings/tools'} activeClassName={styles.active}>
            <FormattedMessage {...messages.tools} />
          </NavLink> : ''
        }

      </nav>
      {/*<Button mode="tertiary" size="small" onClick={handleSaveTemplate} loading={settingsMutation.isLoading}>
        <FormattedMessage {...messages.saveTemplate} />
  </Button>*/}
    </div>
  );
};

export default SettingsNavigation;
