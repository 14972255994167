import { isEmpty } from 'lodash';
import { FetchResult } from 'src/types/TApiQueries';
import { config } from 'src/utils/config';
import { getStandardHeaders, performFetchWithLogging } from '../utils/helpers';

export async function getUsers(searchFilters: any, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/users`;

  const offset = (searchFilters.page - 1) * 30;

  const filters: any = {
    offset,
  };

  Object.entries(searchFilters).forEach(([key, value]) => {
    if (!isEmpty(value)) {
      filters[key] = value;
    }
  });

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: JSON.stringify(filters),
  });
}

export async function getUser(id: number, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/users/${id}`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export async function searchUsers(filters: any, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/users`;

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: JSON.stringify(filters),
  });
}

export async function addUser(user: any, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/users/new`;

  user.role_id = parseInt(user.role_id);

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: JSON.stringify(user),
  });
}

export async function editUser(id: number, data: any, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/users/${id}`;

  if (data.role_id) {
    data.role_id = parseInt(data.role_id);
  }

  return performFetchWithLogging(url, {
    method: 'PATCH',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: JSON.stringify(data),
  });
}
