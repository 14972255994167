import cn from 'classnames';
import * as React from 'react';
import { Bell } from 'src/common/icons';
import styles from './SystemAlert.module.scss';

// TODO: Replace with real icons

const Check = () => <i />;
const Info = () => <i />;
const X = () => <i />;
const XCircle = () => <i />;

const iconsMapping = {
  success: Check,
  warning: Bell,
  danger: XCircle,
  info: Info,
};

type miliseconds = number;

type SystemAlertProps = {
  id: string;
  variant: 'success' | 'warning' | 'danger' | 'info';
  timeout?: miliseconds;
  pauseOnHover?: boolean;
  onClose?: (id: string) => void;
  className?: string;
};

export const SystemAlert: React.FC<SystemAlertProps> = ({
  id,
  variant,
  timeout,
  pauseOnHover,
  onClose,
  className,
  children,
  ...rest
}) => {
  const hasTimeout = typeof timeout !== 'undefined' && timeout > 0;

  return (
    <div
      className={cn([styles.SystemAlert, styles[variant], { [styles.pauseOnHover]: pauseOnHover }, className])}
      {...rest}
    >
      <span className={cn([styles.icon])}>{React.createElement(iconsMapping[variant])}</span>

      {children && <span className={cn([styles.text])}>{children}</span>}

      <button
        aria-label="Close"
        onClick={() => {
          onClose?.(id);
        }}
        className={cn([styles.closeIcon])}
      >
        <X />
      </button>

      {hasTimeout && (
        <span
          role="timer"
          style={{ animationDuration: `${timeout}ms` }}
          className={cn([styles.progressBar])}
          onAnimationEnd={() => onClose?.(id)}
        />
      )}
    </div>
  );
};

export default SystemAlert;
