import cn from 'classnames';
import * as React from 'react';
import styles from './Loader.module.scss';

type LoaderProps = {
  size?: 'small' | 'large';
  className?: string;
  color?: string;
};

export const Loader: React.FC<LoaderProps> = ({ size = 'large', className, color, ...rest }) => {
  return (
    <div className={cn([styles.Loader, className, styles[size]])}>
      <span style={{ borderColor: color ?? 'currentColor' }} {...rest}></span>
    </div>
  );
};

export default Loader;
