import { createAtom } from './base';
import { merge } from 'lodash';
import { getSettings } from './utils';

export const { currentWebhookSettingsAtom, updatedWebhookSettingsAtom, webhookSettingsAtom } = createAtom('webhook', {
  updater: (currentAtom: any, updatedAtom: any, get: any, set: any, action: any) => {
    const { payload } = action;

    const state = get(updatedAtom);

    const mergedState = merge({}, state, payload);

    set(updatedAtom, mergedState);
  },
});

export const getWebhookSettings = (get: any) => getSettings(get, [webhookSettingsAtom, updatedWebhookSettingsAtom]);
