import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { useEffect, useRef, useState, useReducer } from 'react';
import { useAtom } from 'jotai';
import { useDebounce } from 'use-debounce';

import { searchAtom } from 'src/shared/search';
import { filtersVisibleAtom } from 'src/shared/filters';
import {
  SearchBar,
  ListingCard,
  ListingRow,
  Input,
  PageTitle,
  Tag,
  Pager,
  Checkbox,
  BatchActions,
  LayoutViewSwitcher,
  LayoutView,
  Select,
} from 'src/components';
import { useSearchQuery } from 'src/api/queries/assetsQueries';
import { getThumbnailUrl } from 'src/utils/previews';
import { useAccessToken } from 'src/hooks/useAccessToken';

import { CaretRight, CaretDown } from 'src/common/icons';
import { DashboardFilters } from 'src/types/TApiQueries';
import styles from './styles.module.scss';
import { isRefetchingAtom, refreshAtom } from 'src/shared/refresh';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import { selectedAssetsAtom } from 'src/shared/selectedAssets';
import { sortAtom } from 'src/shared/sort';
import useLayoutSwitcher from 'src/hooks/useLayoutSwitcher';
import { hasTrashedAssetsAtom } from 'src/shared/trash';
import { TSorting } from 'src/types/TSorting';
import {statusOptions} from 'src/api/queries/assetsQueries';

const itemsPerPageLocalKey = 'fs.assets.itemsPerPage';

// special filter for trash, see Dashboard/constants.ts for wider filters config
const initialFilters = {
  page: 1,
  hash: '',
  limit_per_page: 30,
  keyword: '',
  extended_result: true,
  sort_by: ['upload_time_range_DESC'],
  content_type: '',
  file_name: '',
  upload_time_range: {
    start: undefined,
    end: undefined,
  },
  trashed: true,
  status: '',
};

function filtersReducer(state: DashboardFilters, payload: { [key: string]: any }) {
  return { ...state, ...payload };
}

const FoldableFilter: React.FC<{ title: string }> = ({ title, children }) => {
  const [isRevealed, setIsRevealed] = useState(true);
  return (
    <div className={styles.foldableContainer}>
      <h4 onClick={() => setIsRevealed(!isRevealed)}>
        {isRevealed ? <CaretDown /> : <CaretRight />} {title}
      </h4>
      {isRevealed && <div className={styles.filterChildren}>{children}</div>}
    </div>
  );
};

const Trash = () => {
  const accessToken = useAccessToken();

  const [sorting, setSorting] = useState<TSorting>(['upload_time_range', 'DESC']);
  const [totalPages, setTotalPages] = useState(1);

  const [search, setSearch] = useState('');
  const [filtersVisible, setFiltersVisible] = useState(false);

  const refreshValue = useAtomValue(refreshAtom);
  const setIsRefetching = useUpdateAtom(isRefetchingAtom);
  const [fileName, setFileName] = useState('');
  const [isPurgingAssets, setIsPurgingAssets] = useState(false);
  const [searchDebounced] = useDebounce(search, 800);
  const [fileNameDebounced] = useDebounce(fileName, 800);
  const [filters, dispatchFilter] = useReducer(filtersReducer, initialFilters, (state) => {
    const limit = localStorage.getItem(itemsPerPageLocalKey)
      ? Number(localStorage.getItem(itemsPerPageLocalKey))
      : state.limit_per_page;
    return { ...state, limit_per_page: limit, trashed: true };
  });
  const searchHash = useRef('');
  const { showSuccess, showAlert } = useAlerts();
  const { getErrorMessage } = useErrorMessages();
  const setHasTrashedAssets = useUpdateAtom(hasTrashedAssetsAtom);

  const [selectedAssets, updateSelectedAssets] = useAtom(selectedAssetsAtom);

  const { data, isLoading, refetch, isRefetching } = useSearchQuery(filters, {
    onSuccess: (results) => {
      totalPages !== results.total_pages && setTotalPages(results.total_pages);
      searchHash.current = results.search_result_id;

      setHasTrashedAssets(results.total_files > 0);
    },
  });

  const handleSelectAllAssets = (selected: boolean) => {
    const newSelectedAssets = selected ? data.result : [];
    updateSelectedAssets(newSelectedAssets);
  };

  useEffect(() => {
    localStorage.setItem(itemsPerPageLocalKey, String(filters.limit_per_page));
  }, [filters.limit_per_page]);

  useEffect(() => {
    dispatchFilter({ sort_by: [sorting.join('_')] });
  }, [sorting]);

  useEffect(() => {
    dispatchFilter({ keyword: searchDebounced, page: 1, hash: '' });
  }, [searchDebounced]);

  useEffect(() => {
    dispatchFilter({ file_name: fileNameDebounced, page: 1, hash: '' });
  }, [fileNameDebounced]);

  // needs bridge Layer for filters change (with debounce?), commit each change with hash -> '' and page -> 1

  useEffect(() => {
    return () => setSearch('');
  }, []);

  useEffect(() => {
    if (isRefetching) return;
    refetch();
  }, [refreshValue]);

  useEffect(() => {
    setIsRefetching(isRefetching);
  }, [isRefetching]);

  const handleOnAssetSelectChange = (assetId: string) => {
    const newSelectedAssets = selectedAssets.includes(assetId)
      ? selectedAssets.filter((id) => id !== assetId)
      : [...selectedAssets, assetId];
    updateSelectedAssets(newSelectedAssets);
  };

  useEffect(() => {
    return () => {
      if (selectedAssets.length > 0) {
        updateSelectedAssets([]);
      }
    };
  }, []);

  return (
    <div className={styles.pageWrapper}>
      {filtersVisible && (
        <div className={cn([styles.filtersContent])}>
          <FoldableFilter title={'File Name'}>
            <Input value={fileName} onChange={(v) => setFileName(v)} />
          </FoldableFilter>
          <FoldableFilter title={'Content Type'}>
            <Checkbox
              label="Image"
              checked={filters.content_type === 'image'}
              onChange={() => {
                filters.content_type === 'image'
                  ? dispatchFilter({ content_type: '', page: 1, hash: '' })
                  : dispatchFilter({ content_type: 'image', page: 1, hash: '' });
              }}
            />
            <Checkbox
              label="Video"
              checked={filters.content_type === 'video'}
              onChange={() => {
                filters.content_type === 'video'
                  ? dispatchFilter({ content_type: '', page: 1, hash: '' })
                  : dispatchFilter({ content_type: 'video', page: 1, hash: '' });
              }}
            />
            <Checkbox
              label="Audio"
              checked={filters.content_type === 'audio'}
              onChange={() => {
                filters.content_type === 'audio'
                  ? dispatchFilter({ content_type: '', page: 1, hash: '' })
                  : dispatchFilter({ content_type: 'audio', page: 1, hash: '' });
              }}
            />
          </FoldableFilter>
          <FoldableFilter title={'Upload Date Range'}>
            <div className={styles.rangeValues}>
              <Input
                type="date"
                value={filters.upload_time_range.start?.substring(0, 10)}
                onChange={(v) =>
                  dispatchFilter({
                    upload_time_range: { start: new Date(v).toISOString(), end: filters.upload_time_range.end },
                  })
                }
              />
              <Input
                type="date"
                value={filters.upload_time_range.end?.substring(0, 10)}
                onChange={(v) =>
                  dispatchFilter({
                    upload_time_range: {
                      end: new Date(new Date(v).getTime() + 86400000 - 1).toISOString(),
                      start: filters.upload_time_range.start,
                    },
                  })
                }
              />
            </div>

            {/* TODO: 1. DOCUMENT DATES FILTER, 2. Dates manipulations needs proper abstraction */}
          </FoldableFilter>
          <FoldableFilter title={'Status'}>
            <Select
              className={styles.Select}
              options={statusOptions}
              value={filters.status}
              onChange={(v) => {
                dispatchFilter({
                  status: v,
                });
              }}
            />
          </FoldableFilter>
        </div>
      )}
      <div className={cn([styles.pageContent])}>
        <PageTitle title={'Deleted Assets'} counter={data?.total_files} />
        <SearchBar
          className={styles.searchBar}
          search={search}
          sortKeys={[
            { key: 'file_name', value: 'File Name' },
            { key: 'content_type', value: 'Content Type' },
            { key: 'upload_time_range', value: 'Upload Time' },
            { key: 'file_size_range', value: 'File Size' },
          ]}
          onSelectSort={setSorting}
          onClickFiltersButton={() => setFiltersVisible(!filtersVisible)}
          onSearch={(value) => setSearch(value)}
        />
        <div className={styles.pageActions}>
          <BatchActions showSelectAll={data?.result.length > 0} onSelectAll={handleSelectAllAssets} />
          <LayoutViewSwitcher />
        </div>
        {search && (
          <div className={styles.filtersWrapper}>
            <div className={styles.tags}>
              {search !== '' && <Tag text={search} onClose={() => setSearch('')} />}
              {filters.content_type !== '' && (
                <Tag
                  text={`Content Type: ${filters.content_type}`}
                  onClose={() => dispatchFilter({ content_type: '', page: 1, hash: '' })}
                />
              )}
              {/*<Button size="small" mode="flat">
              <FormattedMessage {...messages.saveSearch} />
            </Button>*/}
            </div>
          </div>
        )}
        <LayoutView
          data={data?.result}
          emptyDataMessage="No deleted assets found"
          isLoading={isLoading}
          classNames={{
            cardView: 'listingGridWrapper',
          }}
          cardView={(assetId: string) => (
            <ListingCard
              key={assetId}
              id={assetId}
              siblings={data?.result}
              imageUrl={getThumbnailUrl({ width: 300, height: 300, id: assetId, jwt: accessToken })}
              name={data?.extended_result[assetId]?.name}
              size={data?.extended_result[assetId]?.size}
              contentType={data?.extended_result[assetId]?.content_type}
              trashed={data?.extended_result[assetId]?.trashed}
              status={data?.extended_result[assetId]?.status}
              onSelectChange={() => handleOnAssetSelectChange(assetId)}
              isSelected={selectedAssets.includes(assetId)}
              error={data?.extended_result[assetId]?.errors}
            />
          )}
          listView={(assetId: string) => (
            <ListingRow
              key={assetId}
              id={assetId}
              siblings={data?.result}
              imageUrl={getThumbnailUrl({ width: 100, height: 100, id: assetId, jwt: accessToken })}
              name={data?.extended_result[assetId]?.name}
              size={data?.extended_result[assetId]?.size}
              uploadTime={data?.extended_result[assetId]?.upload_time}
              contentType={data?.extended_result[assetId]?.content_type}
              trashed={data?.extended_result[assetId]?.trashed}
              status={data?.extended_result[assetId]?.status}
              onSelectChange={() => handleOnAssetSelectChange(assetId)}
              isSelected={selectedAssets.includes(assetId)}
              error={data?.extended_result[assetId]?.errors}
            />
          )}
        />

        {totalPages > 1 && (
          <div className={styles.pagerWrapper}>
            <Pager
              currentPage={filters.page}
              totalPages={totalPages}
              onChange={(page) => dispatchFilter({ page, hash: searchHash.current })}
              itemsPerPage={filters.limit_per_page}
              onItemsPerPageChange={(newLimit) => dispatchFilter({ page: 1, hash: '', limit_per_page: newLimit })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Trash;
