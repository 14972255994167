import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEditUserManagementMutation } from 'src/api/mutations/userManagementMutation';
import { useAlerts } from 'src/common/AlertManager';
import { Button, Checkbox, Input, Paper, Select } from 'src/components';
import BaseModal from 'src/components/BaseModal';
import { BaseModalProps } from 'src/components/BaseModal/BaseModal';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import useForm from 'src/hooks/useForm';

import * as yup from 'yup';

import styles from '../styles.module.scss';
import messages from '../messages';
import useModal from 'src/hooks/useModal';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';

interface EditUserModalProps extends BaseModalProps {
  user: any;
  onEditUser: () => void;
}

const options = [
  {
    label: 'ADMINISTRATOR',
    value: '1',
  },
  {
    label: 'MANAGER',
    value: '2',
  },
  {
    label: 'USER',
    value: '3',
  },
  {
    label: 'CREATOR',
    value: '4',
  },
];

const EditUserSchema = yup.object().shape({
  email: yup.string().email().label('Email'),
  first_name: yup.string().min(1).label('First name'),
  last_name: yup.string().min(1).label('Last name'),
  role_id: yup.number().label('Role'),
});

const EditUserModal = ({ user, onEditUser, ...rest }: EditUserModalProps) => {
  const { form, handleChange, setForm } = useForm({
    email: user?.email,
    first_name: user?.first_name,
    last_name: user?.last_name,
  });

  const [errors, setErrors] = useState();
  const { closeModal } = useModal();
  const auth = useAuthUser();

  const x = useAuthHeader();

  const { showSuccess, showAlert } = useAlerts();

  const { getErrorMessage } = useErrorMessages();
  const { formatMessage } = useIntl();

  const editUserMutation = useEditUserManagementMutation(user?.id ?? -1, {
    onSuccess: () => {
      showSuccess(formatMessage(messages.userEditedSuccessfully, { email: user?.email }));
      onEditUser();
    },
    onError: (error: any) => {
      showAlert(getErrorMessage(error));
    },
  });

  const handleCloseModal = () => closeModal();

  const getUpdatedFields = () => {
    const fields = Object.keys(form);
    const data = { ...form };

    fields.forEach((field) => {
      if (data[field] === user?.[field]) {
        delete data[field];
      }

      if (field === 'new_password' && data[field] === '') {
        delete data[field];
      }
    });

    return data;
  };

  const hasEdited = useMemo(() => {
    const data = getUpdatedFields();
    return Object.keys(data).length > 0;
  }, [form]);

  const isSameUser = useMemo(() => {
    const authenticatedUser = auth();

    if (!authenticatedUser) return false;

    return authenticatedUser?.userId === user?.id;
  }, [user]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!hasEdited) return;

    const data = getUpdatedFields();

    try {
      await EditUserSchema.validate(data);

      editUserMutation.mutateAsync({ ...data });

      closeModal();
    } catch (error: any) {
      setErrors(error.message);
    }
  };

  useEffect(() => {
    setForm({
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
      role_id: user?.role_id,
      new_password: '',
      enabled: user?.enabled,
      group_asset_access: user?.group_asset_access,
    });
  }, [user]);

  return (
    <BaseModal {...rest}>
      <h3>
        <FormattedMessage {...messages.editingUserModalTitle} values={{ email: user.email }} />
      </h3>
      <form onSubmit={handleSubmit}>
        <Paper>
          <Input
            label="Email"
            type="email"
            value={form.email}
            onChange={(value) => handleChange('email', value)}
            variant="secondary"
          />
          <div className={styles.inlineGroup}>
            <Input
              label={formatMessage(messages.firstName)}
              value={form.first_name}
              onChange={(value) => handleChange('first_name', value)}
              variant="secondary"
            />
            <Input
              label={formatMessage(messages.lastName)}
              value={form.last_name}
              onChange={(value) => handleChange('last_name', value)}
              variant="secondary"
            />
          </div>

          <Input
            label={formatMessage(messages.newPassword)}
            type="password"
            variant="secondary"
            value={form.new_password}
            onChange={(value) => handleChange('new_password', value)}
            className={styles.formSpacing}
          />
          {!isSameUser && (
            <div className={styles.formSpacing}>
              <p>
                <FormattedMessage {...messages.role} />
              </p>
              <Select options={options} value={form.role_id} onChange={(value) => handleChange('role_id', value)} />
            </div>
          )}

          {!isSameUser && (
            <div className={styles.inlineGroup}>
              <Checkbox
                label={formatMessage(messages.enabled)}
                checked={form.enabled}
                onChangeCapture={() => handleChange('enabled', !form.enabled)}
                className={styles.formSpacing}
              />

              <Checkbox
                label={formatMessage(messages.allowUserGroupAssets)}
                checked={form.group_asset_access}
                onChangeCapture={() => handleChange('group_asset_access', !form.group_asset_access)}
                className={styles.formSpacing}
              />
            </div>
          )}
        </Paper>

        <Paper>
          {errors && <p className={styles.errorMessage}>{errors}</p>}
          <div className={styles.groupedButtons}>
            <Button type="submit" mode="primary" size="small" disabled={!hasEdited}>
              <FormattedMessage {...messages.submit} />
            </Button>
            <Button type="button" mode="tertiary" onClick={handleCloseModal} size="small">
              <FormattedMessage {...messages.cancel} />
            </Button>
          </div>
        </Paper>
      </form>
    </BaseModal>
  );
};

export default EditUserModal;
