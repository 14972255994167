import * as React from 'react';

function SvgSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M27.5 21.916V10.084a1 1 0 00-.508-.871l-10.5-5.935a1 1 0 00-.984 0l-10.5 5.935a1 1 0 00-.508.87v11.833a1 1 0 00.508.871l10.5 5.935a1.001 1.001 0 00.984 0l10.5-5.935a1 1 0 00.508-.87v0z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 20.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgSettings;
