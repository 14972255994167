import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './BaseModal.module.scss';
import messages from './messages';
import { Portal } from 'src/common/Portal';
import { X } from 'src/common/icons';
import Button from '../Button';
import useModal from 'src/hooks/useModal';

export interface BaseModalProps {
  className?: string;
  containerStyle?: object;
}

export const BaseModal: React.FC<BaseModalProps> = ({ children, className, containerStyle }) => {
  const { closeModal } = useModal();

  const handleCloseModal = () => closeModal();

  return (
    <div className={cn([styles.BaseModalContainer, className])}>
      <div className={styles.BaseModalBackground} onClick={handleCloseModal} />
      <div className={cn([styles.BaseModal, className])} style={{ ...containerStyle }}>
        <div className={styles.CloseModal}>
          <Button icon={<X />} mode="flat" size="small" onClick={handleCloseModal}></Button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default BaseModal;
