import { useState, useEffect, Dispatch, SetStateAction } from 'react';

// TODO: Mock this function for jest
// TODO: To consider: pass allowed values also and check on init?
export function useLocalStorage(key: string, defaultValue: string): [string, Dispatch<SetStateAction<string>>] {
  const [value, setValue] = useState(localStorage.getItem(`fs.${key}`) ?? defaultValue);

  useEffect(() => {
    localStorage.setItem(`fs.${key}`, value);
  }, [value]);

  return [value, setValue];
}
