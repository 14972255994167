import * as React from 'react';
import cn from 'classnames';
import styles from './ConfirmDuplicateCollectionModal.module.scss';
import messages from './messages';
import { useHistory } from 'react-router-dom';
import { useDuplicateCollectionMutation } from 'src/api/mutations/collectionMutations';
import { useAlerts } from 'src/common/AlertManager';
import useForm from 'src/hooks/useForm';
import useModal from 'src/hooks/useModal';
import { ValidationError } from 'yup';
import BaseModal from '../BaseModal';
import Button from '../Button';
import Input from '../Input';
import Paper from '../Paper';
import Textarea from '../Textarea';
import CollectionSchema from 'src/schemas/collection';
import Checkbox from '../Checkbox';

type ConfirmDuplicateCollectionModalProps = {
  sourceId: number;
  title: string;
  name?: string;
  groupAccess?: boolean;
  description?: string;
};

export const ConfirmDuplicateCollectionModal: React.FC<ConfirmDuplicateCollectionModalProps> = ({
  sourceId,
  name = '',
  description = '',
  groupAccess = false,
  title,
}: ConfirmDuplicateCollectionModalProps) => {
  const { showSuccess } = useAlerts();
  const { form, handleChange, formErrors, clearFormErrors, setFieldError } = useForm({
    name,
    description,
    groupAccess,
  });

  const { closeModal } = useModal();
  const history = useHistory();

  const duplicateCollectionMutation = useDuplicateCollectionMutation({
    onSuccess: () => {
      showSuccess('Successfully created a collection from basket.');
      closeModal();
      history.push('/collections');
    },
  });

  const handleCreateCollection = () => {
    clearFormErrors();
    try {
      CollectionSchema.validateSync(form, { abortEarly: false });

      duplicateCollectionMutation.mutateAsync({
        source_id: sourceId,
        name: form.name,
        description: form.description,
        group_access: form.groupAccess,
      });
    } catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach((err) => {
          if (!err.path) return;

          setFieldError(err.path, err.message);
        });
      }
    }
  };

  return (
    <BaseModal>
      <h3>{title}</h3>
      <Paper>
        <Input
          label="Name"
          value={form.name}
          onChange={(value) => handleChange('name', value)}
          error={formErrors.name}
          variant="secondary"
          placeholder="Collection's name..."
        />

        <Textarea
          className={styles.formGroup}
          label="Description"
          onChange={(value) => handleChange('description', value)}
          error={formErrors.error}
          placeholder="Collection's description..."
        />

        <Checkbox
          label="Group Collection"
          checked={form.groupAccess}
          onChange={() => handleChange('groupAccess', !form.groupAccess)}
          className={styles.formGroup}
        />
      </Paper>
      <div className={styles.modalActions}>
        <Button size="small" mode="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="small" onClick={handleCreateCollection}>
          Create Collection
        </Button>
      </div>
    </BaseModal>
  );
};

export default ConfirmDuplicateCollectionModal;
