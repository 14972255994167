import * as React from 'react';
import cn from 'classnames';
import styles from './Select.module.scss';
import messages from './messages';

type SelectProps = {
  label?: string;
  className?: string;
  value: string;
  onChange: (value: string) => void;
  options: SelectOption[];
};

type SelectOption = {
  label: string;
  value: string;
};

export const Select: React.FC<SelectProps> = ({ children, className, value, label, onChange, options, ...rest }) => {
  return (
    <div style={{marginTop:'15px'}}>
      {label?<label htmlFor={label}>{label}</label>:''}
      <select className={cn([styles.Select, className])} value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
