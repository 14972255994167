import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { queryFnFetchErrorWrapper, throwFetchError } from 'src/api/utils/errorHandlers';
import { mergeQueryOptions } from 'src/api/utils/helpers';
import { queryNames } from 'src/api/utils/queryNames';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { CustomQueryOptions, FetchError, DashboardFilters } from 'src/types/TApiQueries';
import { getSchema } from '../functions/schemaApi';

export function useAssetSchemaQuery(
	schemaId: number,
	options?: CustomQueryOptions,
	queryOptions?: UseQueryOptions<any[], FetchError>,
) {
	const actualQueryOptions = mergeQueryOptions(options, queryOptions);
	const accessToken = useAccessToken();

	const queryFn = useMemo(
		() => queryFnFetchErrorWrapper(() => getSchema(accessToken, schemaId)),
		[schemaId, accessToken],
	);

	const query = useQuery<any, FetchError>([queryNames.schema, schemaId], queryFn, actualQueryOptions);

	if (options?.handleErrors && query.status === 'error') {
		if (query.isRefetchError) {
			Sentry.captureMessage('Failed refetching schema: ' + schemaId, Sentry.Severity.Error);
		} else {
			throwFetchError(query.error);
		}
	}

	return query;
}
