import cn from 'classnames';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { updateWatermark } from 'src/api/functions/userApi';
import { useAlerts } from 'src/common/AlertManager';

import CloudArrowUp from 'src/common/icons/CloudArrowUp';
import { AdminSettingsSection, Loader, Paper, Select, Slider } from 'src/components';
import { useAccessToken } from 'src/hooks/useAccessToken';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import { ACTION_TYPES } from 'src/shared/settings/base';
import { watermarkSettingsAtom } from 'src/shared/settings/watermark';
import { config } from 'src/utils/config';
import messages from '../messages';

import styles from '../styles.module.scss';

type TWatermark = {};
const timestamp = Date.now();

export const Watermark: React.FC<TWatermark> = () => {
  const [watermarkFile, setWatermarkFile] = useState<any>(null);
  const watermarkPreviewRef = useRef<HTMLImageElement>(null);
  const watermarkBackgroundPreviewRef = useRef<HTMLImageElement>(null);
  const accessToken = useAccessToken();

  const [watermarkSettings, updateWatermarkSettings] = useAtom(watermarkSettingsAtom);
  const [isLoading, setLoading] = useState(false);
  const { showSuccess, showAlert } = useAlerts();
  const { formatMessage } = useIntl();

  const { getErrorMessage } = useErrorMessages();

  const handleChange = (name: string, value: string | number) => {
    updateWatermarkSettings({ type: ACTION_TYPES.UPDATE_STATE, payload: { [name]: value } });
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);

    const [file] = acceptedFiles;

    try {
      const result = await updateWatermark(file, accessToken);

      if (result.status === 400) {
        showAlert(getErrorMessage(result.error));
        return;
      }

      setWatermarkFile(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          watermarkKeyFile: result.watermarkFileKey,
        }),
      );

      updateWatermarkSettings({
        type: ACTION_TYPES.UPDATE_STATE,
        payload: { watermarkFileKey: result.watermarkFileKey },
      });

      showSuccess(formatMessage(messages.successfulyUploadedWatermark));
    } catch (error) {
      showAlert(getErrorMessage('system.errorMessages.unknownError'));
    } finally {
      setLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    maxSize: 500 * 1024,
  });

  useEffect((): void => {
    if (!watermarkPreviewRef.current) return;

    // reset the top left right and bottom style properties
    watermarkPreviewRef.current.style.top = '';
    watermarkPreviewRef.current.style.right = '';
    watermarkPreviewRef.current.style.bottom = '';
    watermarkPreviewRef.current.style.left = '';
    watermarkPreviewRef.current.style.transform = '';

    switch (watermarkSettings.watermarkPlacement) {
      case 'top-left':
        watermarkPreviewRef.current.style.top = '0';
        watermarkPreviewRef.current.style.left = '0';
        break;
      case 'top-right':
        watermarkPreviewRef.current.style.top = '0';
        watermarkPreviewRef.current.style.right = '0';
        break;
      case 'bottom-left':
        watermarkPreviewRef.current.style.bottom = '0';
        watermarkPreviewRef.current.style.left = '0';
        break;
      case 'bottom-right':
        watermarkPreviewRef.current.style.bottom = '0';
        watermarkPreviewRef.current.style.right = '0';
        break;
      case 'center':
        watermarkPreviewRef.current.style.top = '50%';
        watermarkPreviewRef.current.style.left = '50%';
        watermarkPreviewRef.current.style.transform = 'translate(-50%, -50%)';
        break;
    }
  }, [watermarkSettings.watermarkPlacement]);

  const setWatermarkHeightSize = () => {
    if (!watermarkPreviewRef.current || !watermarkBackgroundPreviewRef.current) return;

    const { watermarkScale } = watermarkSettings;

    watermarkPreviewRef.current.style.height = `${watermarkBackgroundPreviewRef.current.height * watermarkScale}px`;
  };

  useEffect(() => {
    setWatermarkHeightSize();
  }, [watermarkSettings.watermarkScale, watermarkPreviewRef, watermarkBackgroundPreviewRef]);

  return (
    <>
      <h3 className={styles.sectionSubTitle}>Watermark file</h3>

      <Paper className={styles.watermarkFile}>
        <div className={cn(styles.dragAndDrop, isDragActive ? styles.dragging : undefined)} {...getRootProps()}>
          <input {...getInputProps()} />
          <CloudArrowUp fontSize={32} />
          <h4>
            Drag your image here or <span>browse</span>
          </h4>
          <h5>max size 500kb - jpeg, png, webp</h5>
        </div>
        <div className={styles.previewWatermark}>
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              <img
                src={
                  watermarkFile
                    ? watermarkFile.preview
                    : `${config.hostUrl}${watermarkSettings.watermarkFile}?_cb=${timestamp}`
                }
                alt="watermark"
              />
            )}
          </div>
        </div>
      </Paper>

      <h3 className={styles.sectionSubTitle}>Options</h3>
      <Paper className={styles.watermarkOptions}>
        <div className={styles.watermarkOptionsRow}>
          <div>
            <h5>Watermark placement</h5>
            <Select
              value={watermarkSettings.watermarkPlacement}
              onChange={(value) => handleChange('watermarkPlacement', value)}
              options={[
                {
                  value: 'top-left',
                  label: 'Top left corner',
                },
                {
                  value: 'top-right',
                  label: 'Top right corner',
                },
                {
                  value: 'bottom-left',
                  label: 'Bottom left corner',
                },
                {
                  value: 'bottom-right',
                  label: 'Bottom right corner',
                },
                {
                  value: 'center',
                  label: 'Center',
                },
              ]}
            />
            <p>Where to place the watermark on image conversions and video transcodes</p>
          </div>
          <div>
            <h5>Watermark Scaling</h5>
            <Slider
              min="0.1"
              max="1.0"
              step="0.01"
              value={watermarkSettings.watermarkScale}
              onChange={(value) => handleChange('watermarkScale', value)}
            />
            <p>
              A value of 0.1 would place a watermark that is not larger than 10% in width and height of the target image
            </p>
          </div>
        </div>

        <div className={styles.watermarkOptionsPreview}>
          <img ref={watermarkBackgroundPreviewRef} src="/wm_preview_bg.png" alt="watermark" onLoad={setWatermarkHeightSize} />
          <img
            ref={watermarkPreviewRef}
            className={styles.watermarkPositionPreview}
            src={
              watermarkFile
                ? watermarkFile.preview
                : `${config.hostUrl}${watermarkSettings.watermarkFile}?cb=${timestamp}`
            }
            alt="watermark location"
          />
        </div>
      </Paper>
    </>
  );
};
