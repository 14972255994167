import { createContext, useReducer } from 'react';
import { Portal } from 'src/common/Portal';

export enum ModalActionType {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  SET_COMPONENT = 'SET_COMPONENT',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
}

type ModalState = {
  visible: boolean;
  component?: React.ReactNode;
};

type ModalAction = {
  type: ModalActionType;
  payload?: any;
};

type ModalReducer = (state: ModalState, action: ModalAction) => ModalState;

const DEFAULT_STATE: ModalState = {
  visible: false,
  component: null,
};

const reducer: ModalReducer = (state, action) => {
  switch (action.type) {
    case ModalActionType.OPEN_MODAL:
      return { ...state, visible: true, component: action.payload };
    case ModalActionType.CLOSE_MODAL:
      return { ...state, visible: false };
    case ModalActionType.SET_COMPONENT:
      return { ...state, component: action.payload };
    case ModalActionType.TOGGLE_MODAL:
      return { ...state, visible: !state.visible };
    default:
      return DEFAULT_STATE;
  }
};

export const ModalContext = createContext<[ModalState, React.Dispatch<ModalAction>]>([DEFAULT_STATE, () => {}]);

const ModalProvider = ({ children }: any) => {
  const [state, dispatcher] = useReducer(reducer, DEFAULT_STATE);

  return (
    <ModalContext.Provider value={[state, dispatcher]}>
      {children}
      {state.visible && Portal({ children: state.component })}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
