import { defineMessages } from 'react-intl';

export default defineMessages({
  unsavedChanges: {
    id: 'component.saveChangesBar.unsavedChanges',
    defaultMessage: 'Unsaved changes',
  },
  cancel: {
    id: 'component.saveChangesBar.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'component.saveChangesBar.save',
    defaultMessage: 'Save',
  },
  successfulySaved: {
    id: 'component.saveChangesBar.success',
    defaultMessage: 'Settings successfully updated',
  },
});
