
import SubstitutionWordSchema from 'src/schemas/wordSubstitution';
import { ValidationError } from 'yup';

import useComponentHooks from './hooks';
import ConfirmSubtitlesWordSubstitutionModalProps from './types';


/**
 * This function is used to generate all the event handlers to the component.
 * 
 * @param props
 * @param hooks
 * @returns object containing all the handlers
 * 
 **/
const generateHandlers = (
  props: ConfirmSubtitlesWordSubstitutionModalProps,
  hooks: ReturnType<typeof useComponentHooks>
) => {
  return {

    /* 
    * This function is used to handle the change of the word input.
    *
    * @param value
    * @returns void
    **/
    changeValueOfWord: (value: string) => hooks.form.handleChange('word', value),


    handleSubmit: () => {

      hooks.form.clearFormErrors();

      try {
        SubstitutionWordSchema.validateSync(hooks.form.form, { abortEarly: false });
      } catch (error) {
        const isValidationError = error instanceof ValidationError;
        if (!isValidationError) throw error;
        for (const err of error.inner) {
          if (!err.path) continue;
          hooks.form.setFieldError(err.path, err.message);
        }
        return
      }

      if (props.type === 'add') {

        if (hooks.form.form.word as string in hooks.wordSubstitutionState.value.words) {
          hooks.form.setFieldError("word", "Word already exists.");
          return
        }

        const dataToSend = { ...hooks.wordSubstitutionState.value }
        dataToSend.words[hooks.form.form.word] = hooks.form.form.variants.split(",")
        hooks.wordSubstitutionState.setValue(dataToSend)
        hooks.modal.closeModal()
        return

      }

      if (props.type === 'edit') {
        const dataToSend = { ...hooks.wordSubstitutionState.value }
        delete dataToSend.words[props.word]
        dataToSend.words[hooks.form.form.word] = hooks.form.form.variants.split(",")
        hooks.wordSubstitutionState.setValue(dataToSend)
        hooks.modal.closeModal()
        return
      }
    }

  }
}

export default generateHandlers
