import { config } from 'src/utils/config';
import { getStandardHeaders, performFetchWithLogging } from '../utils/helpers';

export async function getApiStats(accessToken: string, period: number | null, user_id: number | null) {
  const queryParams = new URLSearchParams();

  if (period !== null) queryParams.append('period', period.toString());
  if (user_id !== null) queryParams.append('user_id', user_id.toString());
  const url = `${config.hostUrl}/api/v1/stats/api?${queryParams.toString()}`;

  try {
    return await performFetchWithLogging(
      url,
      {
        method: 'GET',
        headers: { ...getStandardHeaders(accessToken) },
        credentials: 'include',
      },
      'json',
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getCdnStats(accessToken: string, period: number | null) {
  try {
    const queryParams = new URLSearchParams();

    if (period !== null) queryParams.append('period', period.toString());

    const url = `${config.hostUrl}/api/v1/stats/cdn?${queryParams.toString()}`;
    return performFetchWithLogging(
      url,
      {
        method: 'GET',
        headers: { ...getStandardHeaders(accessToken) },
        credentials: 'include',
      },
      'json',
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTranscodeStats(accessToken: string, period: number | null, user_id: number | null) {
  const queryParams = new URLSearchParams();

  if (period !== null) queryParams.append('period', period.toString());
  if (user_id !== null) queryParams.append('user_id', user_id.toString());
  const url = `${config.hostUrl}/api/v1/stats/transcodes?${queryParams.toString()}`;

  try {
    return await performFetchWithLogging(
      url,
      {
        method: 'GET',
        headers: { ...getStandardHeaders(accessToken) },
        credentials: 'include',
      },
      'json',
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAddonStats(accessToken: string, period: number | null, user_id: number | null) {
  const queryParams = new URLSearchParams();

  if (period !== null) queryParams.append('period', period.toString());
  if (user_id !== null) queryParams.append('user_id', user_id.toString());
  const url = `${config.hostUrl}/api/v1/stats/addons?${queryParams.toString()}`;

  try {
    return await performFetchWithLogging(
      url,
      {
        method: 'GET',
        headers: { ...getStandardHeaders(accessToken) },
        credentials: 'include',
      },
      'json',
    );
  } catch (error) {
    console.log(error);
  }
}
