import { FetchResult, DashboardFilters, APISchemaUpdate } from 'src/types/TApiQueries';
import { config } from 'src/utils/config';
import { getStandardHeaders, getUploadHeaders, performFetchWithLogging } from '../utils/helpers';

export async function getSearch(filtersData: DashboardFilters, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/search`;
  const { page, hash, ...filters } = filtersData; // do not send unneeded properties
  const payload = JSON.stringify(filters);

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

export async function getSearchByHash(
  { hash, page }: DashboardFilters,
  accessToken: string,
): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/search/${hash}/${page}`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export async function getAssetDetails(assetId: string, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/${assetId}/data`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export async function getAssetMediaUrl(
  assetId: string,
  key: string,
  accessId: string,
  deliveryMode: 'display' | 'download',
  accessToken: string,
): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/${assetId}/get_link?key=${key}&expiry=${config.JWTExpiration}&delivery=${deliveryMode}&accessId=${accessId}`;

  return performFetchWithLogging(
    url,
    {
      method: 'GET',
      headers: getStandardHeaders(accessToken),
      credentials: 'include',
    },
    'text',
  );
}

export async function getAssetVisionTags(assetId: string, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/${assetId}/addons/image_analysis`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export async function updateAssetMetadata(
  assetId: string,
  data: APISchemaUpdate<any>,
  accessToken: string,
): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/${assetId}/data/update`;
  const payload = JSON.stringify(data);

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

export async function updateAssetMedia(assetId: string, data: any, accessToken: string): Promise<FetchResult<any>> {
  const form = new FormData();
  form.append('file', data.file, data.filename);

  const url = `${config.hostUrl}/api/v1/assets/${assetId}/content/original/replace`;

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getUploadHeaders(accessToken),
    credentials: 'include',
    body: form,
  });
}

export async function updateAssetBatchReprocess(data: any, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/content/original/reprocess`;
  const payload = JSON.stringify(data);

  return performFetchWithLogging(
    url,
    {
      method: 'POST',
      headers: getStandardHeaders(accessToken),
      credentials: 'include',
      body: payload,
    },
    'text',
  );
}

export async function getBatchAssetLinks(
  data: any,
  accessToken: string,
  content_key: string,
  expiry: number,
  is_expiry_max: boolean,
  delivery_mode: string,
  accessId: string,
): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/get_link?key=${content_key}&expiry=${
    is_expiry_max ? 'MAX' : expiry
  }&delivery=${delivery_mode}&accessId=${accessId}`;

  const payload = JSON.stringify(data);

  return performFetchWithLogging(
    url,
    {
      method: 'POST',
      headers: { ...getStandardHeaders(accessToken) },
      credentials: 'include',
      body: payload,
    },
    'json',
  );
}

export async function getJobs(
  limit: number,
  offset: number,
  accessToken: string,
  status: string,
  start_time: string,
  end_time: string,
) {
  let url = `${config.hostUrl}/api/v1/jobs?limit=${limit}&offset=${offset}`;
  if (status !== 'ALL' && status !== null) {
    url += `&status=${status}`.replace('PROCESSING', 'IN_PROGRESS');
  }
  if (start_time !== '' && start_time !== 'YYYY-MM-DDThh:mm:ssZ') {
    url += `&start_time=${start_time}:00Z`;
  }
  if (end_time !== '' && end_time !== 'YYYY-MM-DDThh:mm:ssZ') {
    url += `&end_time=${end_time}:00Z`;
  }
  return performFetchWithLogging(
    url,
    {
      method: 'GET',
      headers: { ...getStandardHeaders(accessToken) },
      credentials: 'include',
    },
    'json',
  );
}

export async function getJobStatus(jobId: string, accessToken: string) {
  const url = `${config.hostUrl}/api/v1/jobs/${jobId}`;
  return performFetchWithLogging(
    url,
    {
      method: 'GET',
      headers: { ...getStandardHeaders(accessToken) },
      credentials: 'include',
    },
    'json',
  );
}

export async function batchCDNPrefetch(data: any, accessToken: string) {
  const url = `${config.hostUrl}/api/v1/cdn/prefetch`;
  const payload = JSON.stringify(data);
  return performFetchWithLogging(
    url,
    {
      method: 'POST',
      headers: { ...getStandardHeaders(accessToken) },
      credentials: 'include',
      body: payload,
    },
    'text',
  );
}

export async function batchCDNPurge(data: any, accessToken: string) {
  const url = `${config.hostUrl}/api/v1/cdn/purge`;
  const payload = JSON.stringify(data);
  return performFetchWithLogging(
    url,
    {
      method: 'POST',
      headers: { ...getStandardHeaders(accessToken) },
      credentials: 'include',
      body: payload,
    },
    'text',
  );
}

//TODO: conversionName must be supplied from caller
export async function updateAssetConversionMedia(
  assetId: string,
  conversionName: string,
  data: any,
  accessToken: string,
): Promise<FetchResult<any>> {
  const form = new FormData();
  form.append('file', data.file, data.filename);

  const url = `${config.hostUrl}/api/v1/assets/${assetId}/content/${conversionName}`;

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getUploadHeaders(accessToken),
    credentials: 'include',
    body: form,
  });
}

export async function purgeAssets(accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/trash/purge`;

  return performFetchWithLogging(url, {
    method: 'DELETE',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export async function deleteAsset(assetId: string, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/${assetId}/delete`;

  const payload = JSON.stringify({ keys: ['original'] });

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

export async function restoreAsset(assetId: string, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/trash/${assetId}/undelete`;

  const payload = JSON.stringify({ keys: ['original'] });

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}
