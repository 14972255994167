import React, { useContext, useEffect } from 'react';
import { ModalActionType, ModalContext } from 'src/providers/ModalProvider';

const useModal = () => {
  const [state, dispatcher] = useContext(ModalContext);

  const { visible } = state;

  const toggleModal = () => dispatcher({ type: ModalActionType.TOGGLE_MODAL });
  const showModal = (modalComponent?: React.ReactNode) =>
    dispatcher({ type: ModalActionType.OPEN_MODAL, payload: modalComponent });

  const closeModal = () => dispatcher({ type: ModalActionType.CLOSE_MODAL });
  const setComponent = (component: React.ReactNode) =>
    dispatcher({ type: ModalActionType.SET_COMPONENT, payload: component });

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [visible]);

  return {
    toggleModal,
    showModal,
    closeModal,
    visible,
    setComponent,
  };
};

export default useModal;
