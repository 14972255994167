import cn from 'classnames';
import * as React from 'react';
import styles from './Shimmer.module.scss';

type ShimmerProps = {
  className?: string;
  w: string | number;
  h: string | number;
  radius?: string | number;
};

export const Shimmer: React.FC<ShimmerProps> = ({ className, w, h, radius = '.5rem', ...rest }) => {
  return (
    <div
      className={cn([styles.Shimmer, className])}
      style={{
        width: w,
        height: h,
        borderRadius: radius,
      }}
    />
  );
};

export default Shimmer;
