import cn from 'classnames';
import { useSettingsQuery } from 'src/api/queries/userQueries';
import { AdminSettingsSidebar, Button, Input, SaveChangesBar, SettingsNavigation } from 'src/components';
import { SecuritySection } from './components/SecuritySection';
import styles from './styles.module.scss';
import { useAtom } from 'jotai';
import { securitySettingsAtom } from 'src/shared/settings/security';
import { ACTION_TYPES } from 'src/shared/settings/base';
import PrivateField from 'src/components/PrivateField';
import { v4 as uuidv4 } from 'uuid';

export const SecuritySettings = () => {
  const { isLoading } = useSettingsQuery();

  const [securitySettings, updateSecuritySettings] = useAtom(securitySettingsAtom);

  const handleChange = (name: string, value: string) =>
    updateSecuritySettings({ type: ACTION_TYPES.UPDATE_STATE, payload: { [name]: value } });

  const handleChangeAPIKey = () => {
    const key = uuidv4().replace(/-/g, '');
    handleChange('api_key', key);
  };

  if (isLoading) {
    return null;
  }
  return (
    <div className={styles.pageWrapper}>
      <SettingsNavigation />
      <SaveChangesBar />

      <div className={styles.container}>
        <div className={styles.sidebar}>
          <AdminSettingsSidebar
            items={[
              {
                key: 'uploadKey',
                label: 'Upload Key',
              },
              {
                key: 'authorisedDomains',
                label: 'Authorised domains',
              },
              {
                key: 'accessId',
                label: 'Access Id',
              },
              {
                key: 'apiKey',
                label: 'API Key',
              },
              {
                key: 'dropboxAppKey',
                label: 'Dropbox App Key',
              },
              {
                key: 'dropboxAppSecret',
                label: 'Dropbox App Secret',
              },
            ]}
          />
        </div>
        <div className={styles.content}>
          <SecuritySection id="uploadKey" title="Upload Key" description="Upload key for File Picker">
            <PrivateField fieldName="Upload Key">
              {(visible, style) => <p className={cn(styles.clearMargin, style)}>{securitySettings.upload_key}</p>}
            </PrivateField>
          </SecuritySection>
          <SecuritySection
            id="authorisedDomains"
            title="Authorised domains"
            description="Authorized domains for File Picker uploads. Provide comma seperated naked domain names"
          >
            <Input
              variant="secondary"
              value={securitySettings.authorized_domains}
              onChange={(value: string) => handleChange('authorized_domains', value)}
              placeholder=""
            />
          </SecuritySection>
          <SecuritySection id="accessId" title="Access Id" description="Access Id for creating signed asset URLs">
            <PrivateField fieldName="Access ID">
              {(visible, style) => <p className={cn(styles.clearMargin, style)}>{securitySettings.access_id}</p>}
            </PrivateField>
          </SecuritySection>
          <SecuritySection
            id="apiKey"
            title="API Key"
            description="Press to generate a new API Key. You need to save after this step to apply the change."
          >
            <PrivateField fieldName="API Key">
              {(visible: boolean, style: string | undefined) => (
                <div className={styles.inlineButton}>
                  <p className={cn(styles.clearMargin, style)}>{securitySettings.api_key}</p>
                  {visible && (
                    <Button mode="primary" size="small" onClick={handleChangeAPIKey}>
                      Change API key
                    </Button>
                  )}
                </div>
              )}
            </PrivateField>
          </SecuritySection>
          <SecuritySection
            id="dropboxAppKey"
            title="Dropbox App Key"
            description="Dropbox App Key from your Dropbox application"
          >
            <Input
              variant="secondary"
              value={securitySettings.dropbox_app_key}
              onChange={(value: string) => handleChange('dropbox_app_key', value)}
              placeholder=""
            />
          </SecuritySection>
          <SecuritySection
            id="dropboxAppSecret"
            title="Dropbox App Secret"
            description="Dropbox App Secret from your Dropbox application"
          >
            <Input
              variant="secondary"
              value={securitySettings.dropbox_app_secret}
              onChange={(value: string) => handleChange('dropbox_app_secret', value)}
              placeholder=""
            />
          </SecuritySection>
        </div>
      </div>
    </div>
  );
};
