import React from 'react';
import cn from 'classnames';
import styles from './SchemaForm.module.scss';
import messages from './messages';
import { ISubmitEvent, withTheme } from '@rjsf/core';
import Button from '../Button';
import InfoIcon from 'src/common/icons/Info';
import { motion, useAnimation, useMotionValue } from 'framer-motion';
import { FormattedMessage } from 'react-intl';

type SchemaFormProps = {
  className?: string;
  schema?: any;
  formData: any;
  onSubmit: ((e: ISubmitEvent<any>, nativeEvent: React.FormEvent<HTMLFormElement>) => any) | undefined;
  onCancel: () => void;
};

const constructUiSchema = (json: any) => {
  const uiSchema: { [key: string]: any } = {};
  // TODO: Replace en with actual language or fallback to browser or fallback to en
  Object.entries(json?.properties ?? {}).forEach(([key, value]: [string, any]) => {
    uiSchema[key] = {
      'ui:placeholder': value?.filespin_properties?.placeholder?.en,
      'ui:help': value?.filespin_properties?.hint?.en,
      'ui:disabled': value?.filespin_properties?.ui?.disabled,
      'ui:hidden': value?.filespin_properties?.ui?.hidden,
      'ui:title': value?.filespin_properties?.title?.en || value?.title,
    };
  });
  uiSchema['ui:order'] = Object.entries(json?.properties ?? {})
    .sort(
      ([key_a, value_a]: [string, any], [key_b, value_b]: [string, any]) =>
        value_a?.filespin_properties?.ui?.order - value_b?.filespin_properties?.ui?.order,
    )
    .map(([key]) => key);

  return uiSchema;
};

const ObjectFieldTemplate = ({ properties, title }: { TitleField: any; properties: any; title: string }) => {
  return (
    <React.Fragment>
      <h3 className={styles.schemaFormTitle}>{title}</h3>
      <div className={styles.formContent}>
        {properties.map((prop: any) => (
          <React.Fragment key={prop.content.key}>{prop.content}</React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
};

const hintAnimation = {
  visible: {
    height: 'auto',
    opacity: 1,
  },
  hidden: {
    height: 0,
    opacity: 0,
  },
};

function CustomFieldTemplate(props: any) {
  const { label, help, description, errors, children, displayLabel, schema } = props;

  const showHint = useMotionValue('hidden');

  const controls = useAnimation();

  const toggleHint = () => {
    controls.start(showHint.get() === 'hidden' ? 'visible' : 'hidden');
    showHint.set(showHint.get() === 'hidden' ? 'visible' : 'hidden');
  };

  if (schema.type === 'object') {
    return children;
  }

  return (
    <div className={styles.formGroup}>
      {displayLabel && (
        <label className={styles.formLabel}>
          {label}
          {help && (
            <>
              <button type="button" onClick={toggleHint}>
                <InfoIcon />
              </button>
            </>
          )}
        </label>
      )}
      {help && (
        <motion.div initial={'hidden'} animate={controls} variants={hintAnimation} style={{ overflow: 'hidden' }}>
          <div className={styles.formHelp}>
            <InfoIcon fontSize={24} />
            {help}
          </div>
        </motion.div>
      )}
      {children}
      {description && <div className={styles.formDescription}>{description}</div>}
      {errors && <div className={styles.formErrors}>{errors}</div>}
    </div>
  );
}

export const SchemaForm: React.FC<SchemaFormProps> = ({ className, schema, formData, onSubmit, onCancel, ...rest }) => {
  const ThemedForm = withTheme({
    className: 'schemaForm',
  });

  return (
    <div className={cn([styles.schemaFormContainer, className])}>
      <ThemedForm
        ObjectFieldTemplate={ObjectFieldTemplate}
        schema={schema}
        uiSchema={constructUiSchema(schema)}
        formData={formData}
        noValidate={false}
        onSubmit={onSubmit}
        FieldTemplate={CustomFieldTemplate}
        noHtml5Validate
        showErrorList={false}
      >
        <div className={styles.formButtonsContainer}>
          <Button size="small" mode="tertiary" type="button" onClick={onCancel}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button type="submit" size="small" mode="primary">
            <FormattedMessage {...messages.submit} />
          </Button>
        </div>
      </ThemedForm>
    </div>
  );
};

export default SchemaForm;
