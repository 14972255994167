type GetSettingsArgs = (get: any, [stateAtom, updatedStateAtom]: [any, any], callback?: (state: any) => any) => any;

export const getSettings: GetSettingsArgs = (get, atoms, callback) => {
  const [stateAtom, updatedStateAtom] = atoms;
  const state = get(stateAtom);
  const updatedState = get(updatedStateAtom);

  if (!updatedState) return undefined;

  return callback ? callback(state) : state;
};
