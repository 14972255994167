import { useHistory } from 'react-router-dom';
import { useAlerts } from 'src/common/AlertManager';
import useForm from 'src/hooks/useForm';
import useModal from 'src/hooks/useModal';
import { wordSubstitutionPayloadAtom } from '../../state'
import { useAtom } from 'jotai'


/**
 *  This hook is used to generate all the hooks to the component
 * 
 * 
 * @param word 
 * @param variants 
 * @returns object containing all the hooks
 */
const useComponentHooks = (word: string, variants: string[]) => {

  const [
    currentWordSubstitutionList,
    setCurrentWordSubstitutionList
  ] = useAtom(wordSubstitutionPayloadAtom)

  return {
    alerts: useAlerts(),
    modal: useModal(),
    history: useHistory(),
    form: useForm({ word, variants: variants.join(",") }),
    wordSubstitutionState:{
      value: currentWordSubstitutionList,
      setValue: setCurrentWordSubstitutionList
    }
  }
}

export default useComponentHooks
