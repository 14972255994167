import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useMemo } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { ACTION_TYPES } from 'src/shared/settings/base';
import { settingsAtom, updatedSettingsAtom } from 'src/shared/settings/index';

import { FetchError } from 'src/types/TApiQueries';
import { updateSettings, saveSettingsAsTemplate, updateWatermark } from '../functions/userApi';
import { queryFnFetchErrorWrapper } from '../utils/errorHandlers';


const prepareRequest = (data: any) => {
  const updateRequestsData: any[] = [];

  Object.keys(data).forEach((key) => {
    updateRequestsData.push({
      key: key,
      value: data[key],
    });
  });



  return updateRequestsData;
};

export function useSettingsMutation(mutationOptions?: UseMutationOptions<any, FetchError, any>) {



  const accessToken = useAccessToken();
  const updatedSettings = useAtomValue(updatedSettingsAtom);
  const setSettingsAtom = useUpdateAtom(settingsAtom);

  const mutationFn = useMemo(
    () =>
      queryFnFetchErrorWrapper(() =>
        Promise.allSettled(prepareRequest(updatedSettings).map((data) => updateSettings(data, accessToken))),
      ),
    [accessToken, updatedSettings],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      // cache the updated values to our state
      setSettingsAtom({ type: ACTION_TYPES.SAVE_CHANGES });


      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}

export function useSettingsTemplateMutation(mutationOptions?: UseMutationOptions<any, FetchError, any>) {
  const accessToken = useAccessToken();
  const combinedSettings = useAtomValue(settingsAtom);

  const mutationFn = useMemo(
    () => queryFnFetchErrorWrapper(() => saveSettingsAsTemplate(combinedSettings, accessToken)),
    [accessToken, combinedSettings],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      //TODO: Given that the API might return cached values
      // the local cache should be updated with the new values

      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}
