export const config = {
  env: process.env.REACT_APP_ENV,
  //apiUrl: process.env.REACT_APP_API_URL,
  cdnUrl: process.env.REACT_APP_CDN_URL,
  hostUrl: process.env.REACT_APP_HOST,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  sentryRelease: process.env.REACT_APP_SENTRY_RELEASE,
  JWTExpiration: process.env.REACT_APP_JWT_EXPIRATION,
  filespinLoader: process.env.REACT_APP_FILESPIN_LOADER ?? '',
  notificationsRefetchInterval: parseInt(process.env.REACT_APP_NOTIFICATION_REFETCH_INTERVAL ?? '10000'),
  defaultSchemaId: 0,
};
