import { User } from '@sentry/react';
import { useMemo } from 'react';
import { UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { FetchError, CustomQueryOptions } from 'src/types/TApiQueries';
import { activateAccount } from '../functions/accessApi';
import { queryFnFetchErrorWrapper, throwFetchError } from '../utils/errorHandlers';
import { mergeQueryOptions } from '../utils/helpers';
import { queryNames } from '../utils/queryNames';
import * as Sentry from '@sentry/react';

export function useActivateAccountQuery(
  code: string,
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<any[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions<User[]>(options, queryOptions);
  const accessToken = useAccessToken();

  const queryFn = useMemo(
    () => queryFnFetchErrorWrapper(() => activateAccount(code, accessToken)),
    [code, accessToken],
  );

  const query = useQuery<any, FetchError>([queryNames.activate, code], queryFn, actualQueryOptions);

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching settings', Sentry.Severity.Error);
    } else {
      throwFetchError(query.error);
    }
  }
  return query;
}
