import { defineMessages } from 'react-intl';

export default defineMessages({
  checkUsage:{
   id:'navr.usage',
   defaultMessage:"Check Usage"
  },
  settings: {
    id: 'admin.navbar.settings',
    defaultMessage: 'Settings',
  },
  assets: {
    id: 'admin.navbar.assets',
    defaultMessage: 'Assets',
  },
  collections: {
    id: 'nav.collections',
    defaultMessage: 'Collections',
  },
  assetTransfers: {
    id: 'nav.assetTransfers',
    defaultMessage: 'Asset Transfers',
  },
  users: {
    id: 'admin.navbar.users',
    defaultMessage: 'Users',
  },
  jobs: {
    id: 'nav.jobs',
    defaultMessage: 'Jobs',
  },
  account: {
    id: 'nav.account',
    defaultMessage: 'Account',
  },
  preferences: {
    id: 'nav.preferences',
    defaultMessage: 'Preferences',
  },
  logout: {
    id: 'nav.logout',
    defaultMessage: 'Logout',
  },
});
