export const convertISOToCustomFormat = (isoDate: string) => {
  return isoDate && isoDate.replace('T', ' ').replace('Z', ' UTC');
};

export const getStatusColor = (status: string) => {
  if (status?.toUpperCase() === 'QUEUED') {
    return '#0fecf5';
  } else if (status?.toUpperCase() === 'COMPLETED') {
    return '#34C700';
  } else if (status?.toUpperCase() === 'IN_PROGRESS') {
    return 'var(--color-prime-400)';
  } else if (status?.toUpperCase() === 'ERROR') {
    return '#FF4F37';
  }
};

export const get_job_map_value = (key: string) => {
  switch (key) {
    case 'VIDEO_TRANSCODE': {
      return 'VIDEO TRANSCODE';
    }
    case 'COMPLETED': {
      return 'COMPLETED';
    }
    case 'ERROR': {
      return 'ERROR';
    }
    case 'QUEUED': {
      return 'QUEUED';
    }
    case 'IN_PROGRESS': {
      return 'PROCESSING';
    }
    default: {
      return null;
    }
  }
};
