import { config } from './config';

type GetThumbnailParams = {
  width: number;
  height: number;
  id: string;
  jwt: string;
};

export const getThumbnailUrl = ({ width, height, id, jwt }: GetThumbnailParams) => {
  return `${config.cdnUrl}/api/v1/assets/${id}/conversions?resize=${width},${height}&_jwt=${jwt.replace('Bearer ', '')}`;
};

export const isPreviewPlayable = (datatype: string) => {
  return ['video', 'audio'].includes(datatype.split('/')[0]);
};
