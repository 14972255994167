import { merge } from 'lodash';
import { createAtom } from './base';
import { getSettings } from './utils';

export const { currentAddonsSettingsAtom, updatedAddonsSettingsAtom, addonsSettingsAtom } = createAtom('addons', {
  updater: (currentAtom: any, updatedAtom: any, get: any, set: any, action: any) => {
    const { payload } = action;

    const state = get(updatedAtom);

    const mergedState = merge({}, state, payload);

    set(updatedAtom, mergedState);
  },
});

export const getAddonsSettings = (get: any) =>
  getSettings(get, [addonsSettingsAtom, updatedAddonsSettingsAtom], (state: any) => {
    const filteredState: any = {};

    Object.keys(state).forEach((key) => {
      const { added, enabled } = state[key];
      filteredState[key] = {
        enabled,
        added,
      };
    });

    return filteredState;
  });
