import { Footer, Input, Paper } from 'src/components';
import { useOnboardingContext } from '../context';
import StepBar from '../components/StepBar';

import styles from '../styles.module.scss';
import { useAuthUser } from 'react-auth-kit';
import { useUserQuery } from 'src/api/queries/userManagementQueries';
import useForm from 'src/hooks/useForm';
import { useEffect } from 'react';
import { useEditUserManagementMutation } from 'src/api/mutations/userManagementMutation';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import { useParams } from 'react-router-dom';
import UserSchema from 'src/schemas/user';
import PasswordSchema from 'src/schemas/password';

import * as Yup from 'yup';

type OnboardingParams = {
  code: string;
};

const UserOnboardingSchema = UserSchema.concat(PasswordSchema).omit(['oldPassword']);

const Profile = () => {
  const { goToNextStep, goToPreviousStep } = useOnboardingContext();
  const auth = useAuthUser();
  const authenticatedUser = auth();

  const { code } = useParams<OnboardingParams>();

  const { showAlert } = useAlerts();
  const { getErrorMessage } = useErrorMessages();

  const { data: userData, isLoading } = useUserQuery(authenticatedUser?.userId);

  const { form, setForm, handleChange, formErrors, setFieldError, clearFormErrors } = useForm({
    email: '',
    firstName: '',
    lastName: '',
    newPassword: '',
    confirmPassword: '',
  });

  const editUserMutation = useEditUserManagementMutation(authenticatedUser?.userId, {
    onSuccess: () => {
      goToNextStep();
    },
    onError: (error) => {
      showAlert(getErrorMessage(error.message));
    },
  });

  const handleGoToNextStep = () => {
    clearFormErrors();
    try {
      UserOnboardingSchema.validateSync(form, { abortEarly: false });

      editUserMutation.mutateAsync({
        email: form.email,
        first_name: form.firstName,
        last_name: form.lastName,
        old_password: code,
        new_password: form.newPassword,
      });
      goToNextStep();
    } catch (error) {
      //validation error here
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((err) => {
          if (!err.path) return;

          setFieldError(err.path, err.message);
        });
      }
    }
  };

  useEffect(() => {
    if (!userData || !userData.data) return;

    setForm({
      ...form,
      email: userData.data.email,
      firstName: userData.data.first_name,
      lastName: userData.data.last_name,
    });
  }, [userData]);

  return (
    <main className={styles.step}>
      <StepBar
        onGoToNextStep={handleGoToNextStep}
        onGoToPreviousStep={goToPreviousStep}
        loadingNextStep={editUserMutation.isLoading}
        hasUnsavedChanges
      />
      <section className={styles.container}>
        <p className={styles.stepInfo}>
          Please update name, email and password
        </p>
        <Paper className={styles.content}>
          <div>
            <Input
              label="First Name"
              variant="secondary"
              value={form.firstName}
              onChange={(value) => handleChange('firstName', value)}
              error={formErrors.firstName}
            />
            <Input
              label="Last Name"
              variant="secondary"
              value={form.lastName}
              onChange={(value) => handleChange('lastName', value)}
              error={formErrors.lastName}
            />
          </div>

          <Input
            label="Email"
            variant="secondary"
            value={form.email}
            onChange={(value) => handleChange('email', value)}
            error={formErrors.email}
          />

          <Input
            type="password"
            label="Password"
            variant="secondary"
            value={form.newPassword}
            onChange={(value) => handleChange('newPassword', value)}
            error={formErrors.newPassword}
          />
          <Input
            type="password"
            label="Confirm Password"
            variant="secondary"
            value={form.confirmPassword}
            onChange={(value) => handleChange('confirmPassword', value)}
            error={formErrors.confirmPassword}
          />
        </Paper>
      </section>
      <Footer />
    </main>
  );
};

export default Profile;
