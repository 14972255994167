import { clone, cloneDeep, isEmpty, merge, omitBy, rest } from 'lodash';
import { createAtom } from './base';
import { getSettings } from './utils';
import { v4 as uuidv4 } from 'uuid';

const updateConversions = (currentAtom: any, updatedAtom: any, get: any, set: any, action: any) => {
  const originalState = get(currentAtom);
  const updatedState = get(updatedAtom);

  const {
    payload: {
      conversions: { id, ...rest },
    },
  } = action;

  if (!updatedState || !updatedState.conversions) {
    const conversionIndex = originalState.conversions.findIndex((conversion: any) => conversion.id === id);
    if (conversionIndex === -1) return;

    const updatedConversions = [...originalState.conversions];

    updatedConversions[conversionIndex] = merge({}, updatedConversions[conversionIndex], rest);

    set(updatedAtom, { ...originalState, conversions: updatedConversions });

    return;
  }

  const conversionIndex = updatedState.conversions.findIndex((conversion: any) => conversion.id === id);

  updatedState.conversions[conversionIndex] = merge({}, updatedState.conversions[conversionIndex], rest);

  set(updatedAtom, { ...updatedState });
};

export const { currentImageSettingsAtom, updatedImageSettingsAtom, imageSettingsAtom } = createAtom('image', {
  updater: (currentAtom: any, updatedAtom: any, get: any, set: any, action: any) => {
    const { payload } = action;

    if (payload.conversions) {
      return updateConversions(currentAtom, updatedAtom, get, set, action);
    }

    const state = get(updatedAtom);

    const mergedState = merge({}, state, payload);

    set(updatedAtom, mergedState);
  },
  setter: (currentAtom: any, set: any, action: any) => {
    const {
      payload,
      payload: { conversions },
    } = action;

    const mappedConversions = conversions.map((conversion: any) => ({
      ...conversion,
      id: uuidv4(),
      size:
        conversion.value.width && conversion.value.height ? `${conversion.value.width}x${conversion.value.height}` : '',
    }));

    set(currentAtom, { ...payload, conversions: mappedConversions });
  },
});

export const getImageSettings = (get: any) =>
  getSettings(get, [imageSettingsAtom, updatedImageSettingsAtom], (state) => {
    const { conversions } = state;

    let mappedConversions = cloneDeep(conversions);

    mappedConversions = mappedConversions.map((conversion: any) => {
      const { value, size } = conversion;

      delete conversion.size;

      if (isEmpty(size)) {
        return {
          ...conversion,
          value: {
            ...value,
            width: null,
            height: null,
          },
        };
      }

      if (!size.match(/^\d+x\d+$/)) return conversion;

      const [width, height] = size.split('x');

      return {
        ...conversion,
        value: {
          ...value,
          width: parseInt(width),
          height: parseInt(height),
        },
      };
    });

    return {
      ...state,
      conversions: mappedConversions,
    };
  });
