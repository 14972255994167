import { atom  } from 'jotai'

export interface SubstitutionsList {
  lang: string;
  domain: string;
  words: {
    [key: string]: string[];
  };
}

export const originalWordSubstitutionPayloadAtom = atom<SubstitutionsList>(
  { lang: '', domain: '', words: {} }
)

export const originalWriteWordSubstitutionPayloadAtom = atom(
  null, // it's a convention to pass `null` for the first argument
  (get, set, update: SubstitutionsList) => {
    // `update` is any single value we receive for updating this atom
    set(originalWordSubstitutionPayloadAtom, update)
  }
)

export const wordSubstitutionPayloadAtom = atom<SubstitutionsList>(
  { lang: '', domain: '', words: {} }
)

export const writeWordSubstitutionPayloadAtom = atom(
  null, // it's a convention to pass `null` for the first argument
  (get, set, update: SubstitutionsList) => {
    // `update` is any single value we receive for updating this atom

    set(wordSubstitutionPayloadAtom, update)
  }
)

export const wordsAtom = atom(
  (get) => get(wordSubstitutionPayloadAtom).words
)

export const wordsListAtom = atom(
  (get) => Object.keys(get(wordSubstitutionPayloadAtom).words)
)



