import React, { useState, useContext, createContext, useCallback } from 'react';
import cn from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useAtom } from 'jotai';
import SystemAlert from 'src/components/SystemAlert';
import { alertsAtom, alertsPushedStateAtom } from 'src/shared/alert';

export type Alert = {
  id?: string;
  type: 'success' | 'warning' | 'danger' | 'info';
  timeout?: number;
  message: string;
};

type AlertHelperFn = (message: string, payload?: Omit<Alert, 'type' | 'message'>) => void;

const AlertsWrapper: React.FC<{}> = () => {
  const [alerts, setAlerts] = useAtom(alertsAtom);
  const [isPushed] = useAtom(alertsPushedStateAtom);

  const removeAlert = (id: string) => {
    setAlerts((old) => [...old.filter((a) => a.id !== id)]);
  };

  return (
    <div className={cn(['alerts-wrapper', { pushed: isPushed }])}>
      <AnimatePresence initial={false}>
        {alerts.map((e, i) => (
          <motion.div
            key={e.id}
            transition={{ type: 'spring' }}
            initial={{ opacity: 0.6, y: 0, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.85, transition: { duration: 0.15 } }}
          >
            <SystemAlert
              id={e.id!}
              variant={e.type}
              timeout={e.timeout || 1000}
              pauseOnHover
              onClose={removeAlert}
              data-test={`alert-${e.type}`}
            >
              {e.message}
            </SystemAlert>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

const useAlerts: () => {
  showWarning: AlertHelperFn;
  showAlert: AlertHelperFn;
  showSuccess: AlertHelperFn;
  showInfo: AlertHelperFn;
  changePosition: (pushedState: boolean) => void;
} = () => {
  const [alerts, setAlerts] = useAtom(alertsAtom);
  const [isPushed, setIsPushed] = useAtom(alertsPushedStateAtom);
  const MAX_ALERTS_ON_STACK = 10;

  const showWarning: AlertHelperFn = (message, payload?) => {
    createAlert({ message, ...payload, type: 'warning', timeout: 5000 });
  };
  const showAlert: AlertHelperFn = (message, payload?) => {
    createAlert({ message, ...payload, type: 'danger', timeout: 5000 });
  };
  const showSuccess: AlertHelperFn = (message, payload?) => {
    createAlert({ message, ...payload, type: 'success', timeout: 5000 });
  };
  const showInfo: AlertHelperFn = (message, payload?) => {
    createAlert({ message, ...payload, type: 'info', timeout: 5000 });
  };

  const changePosition = (pushedState: boolean) => {
    setIsPushed(pushedState);
  };

  const createAlert = (alert: Alert) => {
    if (!alert.hasOwnProperty('id')) {
      alert = { ...alert, id: `${new Date().valueOf()}-${Math.ceil(Math.random() * 10000)}` };
      setAlerts((old) => [alert, ...old].slice(0, MAX_ALERTS_ON_STACK));
    } else {
      setAlerts((old) => [...old.filter((a) => a.id !== alert.id)]);
      setTimeout(() => setAlerts((old) => [alert, ...old].slice(0, MAX_ALERTS_ON_STACK)), 0);
    }
  };

  const removeAlert = (id: string) => {
    setAlerts((old) => [...old.filter((a) => a.id !== id)]);
  };

  return { showWarning, showAlert, showSuccess, showInfo, changePosition };
};

export { AlertsWrapper, useAlerts };
