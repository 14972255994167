import * as React from 'react';

function CloudArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M12 25.998H9a6.998 6.998 0 01-5.963-10.655 7 7 0 017.703-3.127M10 15.998a10 10 0 1118 6"
        stroke="currentColor"
        strokeOpacity={0.4}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.757 20.24L19 15.999l4.243 4.243M19 25.998v-10"
        stroke="currentColor"
        strokeOpacity={0.4}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CloudArrowUp;
