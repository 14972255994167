import React, { useCallback } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from '@sentry/react';
import { PrivateRoute } from 'react-auth-kit';

import { CriticalErrorFallback } from 'src/layouts/error';
import { DefaultLayout } from 'src/layouts/default';
import { AdminSettingsLayout } from 'src/layouts/admin';

import { Login } from './pages/Login/Login';
import { Dashboard } from './pages/Dashboard';
import { Collections } from './pages/Collections/Collections';
import { Modal as AssetModal } from './pages/Asset/Modal';
import { StorageSettings } from './admin-pages/StorageSettings/StorageSettings';
import { SecuritySettings } from './admin-pages/SecuritySettings/SecuritySettings';
import { ImageSettings } from './admin-pages/ImageSettings/ImageSettings';
import { WebhookSettings } from './admin-pages/WebhookSettings/WebhookSettings';
import { DatabaseSettings } from './admin-pages/DatabaseSettings/DatabaseSettings';
import { VideoSettings } from './admin-pages/VideoSettings/VideoSettings';
import { AddonsSettings } from './admin-pages/AddonsSettings/AddonsSettings';
import { ToolsSettings } from './admin-pages/ToolsSettings/ToolsSettings';
import Users from './admin-pages/Users/Users';
import Trash from './pages/Trash/Trash';
import Basket from './pages/Basket/Basket';
import FileSpinPickerProvider from './providers/FileSpinPickerProvider';
import Collection from './pages/Collection/Collection';
import Profile from './pages/Profile/Profile';
import Onboarding from './pages/Onboarding/Onboarding';
import JobList from './pages/Jobs/List';
import JobStatus from './pages/Jobs/Status';
import CheckUsageComponent from './pages/CheckUsage/Component';

const Router = () => {
  const location = useLocation<any>();
  let background = location?.state?.background;

  return (
    <>
      <ErrorBoundary fallback={CriticalErrorFallback}>
        <Switch location={background || location}>
          <Route exact path="/login" component={Login} />

          <Route exact path="/signup/:code" component={Onboarding} />

          <Route
            path={[
              '/assets',
              '/asset/:id',
              '/collection',
              '/collections',
              '/collection/:id',
              '/asset-transfers',
              '/settings',
              '/notifications',
              '/me',
              '/search',
              '/trash',
              '/basket',
              '/jobs',
              '/usage'
            ]}
          >
            <FileSpinPickerProvider>
              <DefaultLayout>
                <Switch>
                  <PrivateRoute path="/assets" loginPath="/login" component={Dashboard} exact />
                  <PrivateRoute path="/asset/:id" loginPath="/login" component={AssetModal} exact />
                  <PrivateRoute path="/collection" loginPath="/login" component={() => <div>TBI</div>} exact />
                  <PrivateRoute path="/collections" loginPath="/login" component={Collections} exact />
                  <PrivateRoute path="/collection/:id" loginPath="/login" component={Collection} exact />
                  <PrivateRoute path="/asset-transfers" loginPath="/login" component={() => <div>TBI</div>} exact />
                  <PrivateRoute path="/settings" loginPath="/login" component={() => <div>TBI</div>} exact />
                  <PrivateRoute path="/notifications" loginPath="/login" component={() => <div>TBI</div>} exact />
                  <PrivateRoute path="/me" loginPath="/login" component={Profile} exact />
                  <PrivateRoute path="/search" loginPath="/login" component={() => <div>TBI</div>} exact />
                  <PrivateRoute path="/trash" loginPath="/login" component={Trash} exact />
                  <PrivateRoute path="/basket" loginPath="/login" component={Basket} exact />
                  <PrivateRoute path="/jobs" loginPath="/login" component={JobList} exact />
                  <PrivateRoute path="/jobs/:id" loginPath="/login" component={JobStatus} exact />
                  <PrivateRoute path="/usage" loginPath='/login' component={CheckUsageComponent} exact/>
                </Switch>
              </DefaultLayout>
            </FileSpinPickerProvider>
          </Route>
          <Route path={['/admin/users']}>
            <DefaultLayout>
              <Switch>
                <PrivateRoute path="/admin/users" loginPath="/login" component={Users} exact />
              </Switch>
            </DefaultLayout>
          </Route>
          <Route path={['/admin']}>
            <AdminSettingsLayout>
              <Switch>
                <PrivateRoute
                  path="/admin/settings/"
                  loginPath="/login"
                  component={() => <Redirect to="/admin/settings/storage" />}
                  exact
                />
                <PrivateRoute path="/admin/settings/storage" loginPath="/login" component={StorageSettings} exact />
                <PrivateRoute path="/admin/settings/security" loginPath="/login" component={SecuritySettings} exact />
                <PrivateRoute path="/admin/settings/image" loginPath="/login" component={ImageSettings} exact />
                <PrivateRoute path="/admin/settings/video" loginPath="/login" component={VideoSettings} exact />
                <PrivateRoute path="/admin/settings/webhook" loginPath="/login" component={WebhookSettings} exact />
                <PrivateRoute path="/admin/settings/database" loginPath="/login" component={DatabaseSettings} exact />
                <PrivateRoute path="/admin/settings/addons" loginPath="/login" component={AddonsSettings} exact />
                <PrivateRoute path="/admin/settings/tools" loginPath="/login" component={ToolsSettings} exact />
              </Switch>
            </AdminSettingsLayout>
          </Route>
          <Route path={['/shared/:id']}>shared collection placeholder</Route>
          <Redirect from="/" to="/assets" />
        </Switch>

        {background && <PrivateRoute path="/asset/:id" loginPath="/login" component={AssetModal} />}
      </ErrorBoundary>

      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </>
  );
};

export default Router;
