import * as React from 'react';
import cn from 'classnames';

import Shimmer from '../Shimmer';

import styles from './ListingCardShimmer.module.scss';
import messages from './messages';

export const ListingCardShimmer: React.FC<{}> = ({ ...rest }) => {
  return (
    <div className={cn([styles.ListingCardShimmer])}>
      <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
      <div className={styles.titleWrapper}>
        <div className={styles.checkbox}>
          <Shimmer w={20} h={20} radius={3} />
        </div>
        <div className={styles.title}>
          <Shimmer w="80%" h={22} radius={3} />
          <Shimmer w="50%" h={14} radius={3} />
        </div>
      </div>
    </div>
  );
};

export default ListingCardShimmer;
