import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { IntlProvider } from 'react-intl';
import { AuthProvider } from 'react-auth-kit';
import { QueryClientProvider, QueryClient } from 'react-query';

import { AlertsWrapper } from './common/AlertManager';
import Router from 'src/Router';
import { config } from './utils/config';
import { sentryIntegrations } from './utils/sentry';

import 'src/index.scss';
import 'pintura/pintura.css';
import reportWebVitals from 'src/utils/reportWebVitals';
import ModalProvider from './providers/ModalProvider';

if (process.env.NODE_ENV === 'production' && config.env !== 'local') {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.env,
    release: config.sentryRelease,
    integrations: sentryIntegrations,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale="en" defaultLocale="en" key="en">
      <AuthProvider authType={'localstorage'} authName={'fs.auth.'}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ModalProvider>
              <Router />
            </ModalProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </AuthProvider>
      <AlertsWrapper />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('filespin'),
);

// TODO: Await experimental react-dom features, eg concurrent mode
// ReactDOM.createRoot(document.getElementById('root')).render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
