import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import styles from './BatchActions.module.scss';
import Dropdown from '../Dropdown';
import { SelectInputOption } from '../Dropdown/Dropdown';
import Checkbox from '../Checkbox';
import TrashActions from './TrashActions';
import BasketActions from './BasketActions';
import { useAtom } from 'jotai';
import { selectedAssetsAtom } from 'src/shared/selectedAssets';
import useRoleManager from 'src/hooks/useRoleManager';

type PageActions = {
  options: SelectInputOption[];
  onClick: (option: string) => void;
};

type BatchActionsProps = {
  className?: string;
  basket?: boolean;
  trash?: boolean;
  pageActions?: PageActions;
  onSelectAll?: (selected: boolean) => void;
  showSelectAll?: boolean;
};

export const BatchActions: React.FC<BatchActionsProps> = ({
  className,
  onSelectAll,
  pageActions,
  basket = true,
  trash = true,
  showSelectAll,
  ...rest
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedAssets, updateSelectedAssets] = useAtom(selectedAssetsAtom);
  const { isUser } = useRoleManager();

  const handleOnSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(e.target.checked);
    onSelectAll && onSelectAll(e.target.checked);
  };

  return (
    <div className={cn([styles.BatchActions, className])}>
      <div>
        {showSelectAll && (
          <Checkbox label="Select" checked={selectAll} onChange={handleOnSelectChange} className={styles.selectAll} />
        )}
        {pageActions && (
          <div className={styles.batchAction}>
            <Dropdown
              className={styles.batchActionDropdown}
              title="Actions"
              options={pageActions.options}
              onClick={pageActions.onClick}
            >
              {selectedAssets.length > 0 && <span className={styles.badge}>{selectedAssets.length}</span>}
              <span>With Selected</span>
            </Dropdown>
          </div>
        )}
        {basket && <BasketActions />}
      </div>
      <div>{!isUser && trash && <TrashActions />}</div>
    </div>
  );
};

export default BatchActions;
