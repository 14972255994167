import { useAtom } from 'jotai';
import React, { useMemo, useState } from 'react';
import { AdminSettingsSection, Checkbox, Input, Table } from 'src/components';
import { ACTION_TYPES } from 'src/shared/settings/base';
import { imageSettingsAtom } from 'src/shared/settings/image';

import styles from '../styles.module.scss';

type TCustomImageConversions = {};

export const CustomImageConversions: React.FC<TCustomImageConversions> = () => {
  const [imageSettings, updateImageSettings] = useAtom(imageSettingsAtom);
  const [errors, setErrors] = useState<any>(null);

  const conversions = useMemo(() => imageSettings.conversions, [imageSettings.conversions]);

  const handleChanges = (id: number, property: string, value: string | boolean) => {
    updateImageSettings({
      type: ACTION_TYPES.UPDATE_STATE,
      payload: {
        conversions: {
          id,
          value: {
            [property]: value,
          },
        },
      },
    });
  };

  const handleKeyChange = (id: number, value: string) => {
    const image = conversions.find((img: any) => img.key === value);
    if (image) {
      setErrors({
        [id]: {
          ...(errors && errors[id]),
          key: `${value} already exists`,
        },
      });
      return;
    }

    updateImageSettings({
      type: ACTION_TYPES.UPDATE_STATE,
      payload: {
        conversions: {
          id,
          key: value,
        },
      },
    });
  };

  const validateSize = (id: number, value: string) => {
    if (!value) return;
    if (!value.match(/^\d+x\d+$/g)) {
      setErrors({
        [id]: {
          ...(errors && errors[id]),
          size: 'Size must be in format "WIDTHxHEIGHT"',
        },
      });
      return;
    }
  };

  const handleSizeChange = (id: number, value: string) => {
    setErrors((state: any) => {
      if (!state) return state;

      delete state[id];

      return state;
    });

    updateImageSettings({
      type: ACTION_TYPES.UPDATE_STATE,
      payload: {
        conversions: {
          id,
          size: value,
        },
      },
    });
  };

  return (
    <AdminSettingsSection title="Custom Image Conversions">
      <Table headers={['Key', 'size (wxH)', 'watermark', 'public', 'create on upload']}>
        {conversions.map(({ key, value, size, id }: any) => (
          <React.Fragment key={id}>
            <tr>
              <td>
                <Input variant="secondary" value={key} onChange={(value) => handleKeyChange(id, value)} />
              </td>
              <td>
                <Input
                  variant="secondary"
                  value={size}
                  onChange={(value) => handleSizeChange(id, value)}
                  onBlur={() => validateSize(id, size)}
                />
              </td>
              <td>
                <Checkbox
                  className={styles.centeredCheckbox}
                  checked={value.watermark}
                  onChange={(e) => handleChanges(id, 'watermark', e.target.checked)}
                />
              </td>
              <td>
                <Checkbox
                  className={styles.centeredCheckbox}
                  checked={value.public}
                  onChange={(e) => handleChanges(id, 'public', e.target.checked)}
                />
              </td>
              <td>
                <Checkbox
                  className={styles.centeredCheckbox}
                  checked={value.enabled}
                  onChange={(e) => handleChanges(id, 'enabled', e.target.checked)}
                />
              </td>
            </tr>
            {errors && errors[id] && (
              <tr className={styles.error}>
                <td>{errors[id]['key']}</td>
                <td>{errors[id]['size']}</td>
                <td colSpan={3}></td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </Table>
    </AdminSettingsSection>
  );
};
