import * as React from 'react';
import cn from 'classnames';
import styles from './CollectionRow.module.scss';
import messages from './messages';
import { Collection } from 'src/types/TCollection';
import Button from '../Button';
import { Copy, FileArrowDown, Stack, Trash, User, UserCircle, Users } from 'src/common/icons';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { getThumbnailUrl } from 'src/utils/previews';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { getCollectionDownloadUrl } from 'src/api/functions/collectionsApi';
import { useAlerts } from 'src/common/AlertManager';
import { formatMessage } from '@formatjs/intl';
import { useDeleteCollectionMutation, useDuplicateCollectionMutation } from 'src/api/mutations/collectionMutations';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import { useIntl } from 'react-intl';
import SvgWrench from 'src/common/icons/Wrench';
import Pencil from 'src/common/icons/Pencil';
import { useAuthUser } from 'react-auth-kit';
import useModal from 'src/hooks/useModal';
import ConfirmDuplicateCollectionModal from '../ConfirmDuplicateCollectionModal';

type CollectionRowProps = {
  className?: string;
  collection: Collection;
};

export const CollectionRow = ({ className, collection }: CollectionRowProps) => {
  const auth = useAuthUser();
  const userEmail = auth() ? auth()!.userEmail : '';
  const { id, name, assets, last_update, group_access, description } = collection;
  const history = useHistory();
  const accessToken = useAccessToken();
  const [isPreparingDownload, setIsPreparingDownload] = React.useState(false);
  const { formatMessage } = useIntl();
  const { showAlert, showSuccess } = useAlerts();
  const { getErrorMessage } = useErrorMessages();
  const duplicateCollectionMutation = useDuplicateCollectionMutation();
  const { showModal } = useModal();

  const handleGoToCollection = () => history.push(`/collection/${id}`);

  const handleDownloadCollection = async () => {
    if (isPreparingDownload) return;

    setIsPreparingDownload(true);
    const url: any = await getCollectionDownloadUrl(id, accessToken);

    if (url.status >= 404) {
      showAlert(`Download not available at the moment. Please try again later.`);
      setIsPreparingDownload(false);
      return;
    }

    window.open(url);
    setIsPreparingDownload(false);
  };

  const deleteCollectionMutation = useDeleteCollectionMutation(id, {
    onSuccess: () => {
      showSuccess(formatMessage(messages.collectionDeleted));
    },
    onError: (error) => {
      showAlert(getErrorMessage(error.message));
    },
  });

  const handleDeleteCollection = () => deleteCollectionMutation.mutateAsync(undefined);

  const handleDuplicateCollection = () =>
    showModal(
      <ConfirmDuplicateCollectionModal
        title={`Duplicate collection ${name}`}
        sourceId={id}
        name={`${name} (copy)`}
        description={description}
        groupAccess={group_access}
      />,
    );

  return (
    <div className={cn([styles.CollectionRow, className])} data-test="collection-row">
      <div>
        {assets.length === 0 && <Stack className={styles.cover} onClick={handleGoToCollection} />}
        {assets.length > 0 && (
          <img
            className={styles.cover}
            src={getThumbnailUrl({ width: 75, height: 75, id: assets[0], jwt: accessToken })}
            alt="Asset In Collection"
            onClick={handleGoToCollection}
          />
        )}

        <div className={styles.content}>
          <Link className={styles.title} to={`/collection/${id}`} data-test="collection-row-name">
            {name}
          </Link>

          {description && <p className={styles.description}>{description}</p>}
        </div>
      </div>

      <div>
        <p>{`${assets.length} ${assets.length > 1 ? 'assets' : 'asset'}`}</p>
        {/* TODO: Implement Collection User's Avatar */}
        {false && (
          <div className={styles.users}>
            {new Array(5).fill(0).map((_, index) => (
              <UserCircle key={index} />
            ))}
          </div>
        )}

        <span className={styles.groupCollection}>
          {group_access ? (
            <>
              <Users />
              <span>Group</span>
            </>
          ) : (
            <>
              <User />
              <span>Private</span>
            </>
          )}
        </span>

        <p className={styles.lastUpdate}>
          Updated <br />
          {moment(last_update).fromNow()}
        </p>
        <div className={styles.actions} data-test="collection-row-actions">
          {/*   
          <Button size="small" mode="flat" icon={< Pencil />}
            tooltip="Edit"
            disabled={group_access}
          />
          */}
          <Button icon={<Copy />} mode="flat" size="small" tooltip="Duplicate" onClick={handleDuplicateCollection} />
          <Button
            icon={<FileArrowDown fontSize={24} />}
            mode="flat"
            size="small"
            onClick={handleDownloadCollection}
            disabled={assets.length === 0}
            tooltip="Download"
          />
          {userEmail === collection.user_email && (
            <Button
              icon={<Trash fontSize={24} />}
              mode="flat"
              size="small"
              tooltip="Delete"
              onClick={handleDeleteCollection}
              data-test="collection-row-delete"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectionRow;
