import { useState } from 'react';

type TInitialState = {
  [key: string]: any;
};

const useForm = (initialState: TInitialState) => {
  const [form, setForm] = useState(initialState);
  const [formErrors, setFormErrors] = useState<any>(
    Object.keys(initialState).reduce((acc, key) => ({ ...acc, [key]: undefined }), {}),
  );

  const handleChange = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const setFieldError = (field: string, error: string | undefined) => {
    setFormErrors((currentErrors: any) => ({
      ...currentErrors,
      [field]: error,
    }));
  };

  const clearFieldError = (field: string) => setFieldError(field, undefined);

  const clearFormErrors = () =>
    setFormErrors(Object.keys(initialState).reduce((acc, key) => ({ ...acc, [key]: undefined }), {}));

  return {
    form,
    formErrors,
    setForm,
    handleChange,
    setFieldError,
    clearFieldError,
    clearFormErrors,
  };
};

export default useForm;
