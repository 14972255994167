import * as React from 'react';
import cn from 'classnames';
import styles from './CreateCollectionModal.module.scss';
import messages from './messages';
import { useCreateCollectionMutation } from 'src/api/mutations/collectionMutations';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import useModal from 'src/hooks/useModal';
import CollectionSchema from 'src/schemas/collection';
import BaseModal from '../BaseModal';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Input from '../Input';
import Paper from '../Paper';
import Textarea from '../Textarea';
import { useState } from 'react';
import { useAtom } from 'jotai';
import { Trash } from 'src/common/icons';
import { selectedAssetsAtom } from 'src/shared/selectedAssets';

type CreateCollectionModalProps = {
  onCreateCollection?: (collection: any) => void;
};

export const CreateCollectionModal = ({ onCreateCollection }: CreateCollectionModalProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [groupAccess, setGroupAccess] = useState(false);

  const { showSuccess, showAlert } = useAlerts();
  const { getErrorMessage } = useErrorMessages();
  const [selectedAssets, setSelectedAssets] = useAtom(selectedAssetsAtom);

  const { closeModal } = useModal();

  const createCollectionMutation = useCreateCollectionMutation({
    onSuccess: (result) => {
      showSuccess('Collection created successfully');
      closeModal();
      onCreateCollection && onCreateCollection(result);
    },
    onError: (error) => {
      showAlert(getErrorMessage(error.message));
    },
  });

  const isValid = React.useMemo(() => {
    try {
      CollectionSchema.validateSync({ name, description, group_access: groupAccess });
      return true;
    } catch (error) {
      return false;
    }
  }, [name, description, groupAccess]);

  const handleCreateCollection = () => {
    if (!isValid) return;

    createCollectionMutation.mutateAsync({
      name,
      description,
      group_access: groupAccess,
      assets: selectedAssets,
    });
  };

  return (
    <BaseModal>
      <h3>Create Collection</h3>

      <section>
        <Paper>
          <Input
            variant="secondary"
            label="Collection Name"
            placeholder="Name Collection"
            value={name}
            onChange={(value) => setName(value)}
            className={styles.modalInput}
            data-test="collection-name-input"
          />

          <Textarea
            className={styles.formGroup}
            label="Description"
            placeholder="Collection's description..."
            rows={4}
            value={description}
            onChange={(value) => setDescription(value)}
            data-test="collection-description-input"
          />

          <Checkbox
            label="Group Collection"
            checked={groupAccess}
            onChange={() => setGroupAccess(!groupAccess)}
            className={styles.formGroup}
            data-test="collection-group-access-checkbox"
          />
        </Paper>

        {selectedAssets.length > 0 && (
          <Paper>
            <p>You have selected {selectedAssets.length} assets to add to this collection.</p>
          </Paper>
        )}
      </section>

      <div className={styles.modalActions}>
        <Button size="small" mode="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          size="small"
          mode="primary"
          onClick={handleCreateCollection}
          disabled={!isValid}
          data-test="collection-submit-button"
        >
          Create Collection
        </Button>
      </div>
    </BaseModal>
  );
};

export default CreateCollectionModal;
