export const queryNames = {
  search: 'search',
  asset: 'asset',
  assetVision: 'assetVision',
  collection: 'collection',
  schema: 'schema',
  settings: 'settings',
  userManagement: 'userManagement',
  user: 'user',
  activate: 'activate',
  notification: 'notification',
  substitution: 'substitution'
};
