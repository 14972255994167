import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'login.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'login.password',
    defaultMessage: 'Password',
  },
  rememberMe: {
    id: 'login.rememberMe',
    defaultMessage: 'remember me',
  },
  forgotPassword: {
    id: 'login.forgotPassword',
    defaultMessage: 'forgot password?',
  },
  signIn: {
    id: 'login.signIn',
    defaultMessage: 'Sign In',
  },
  newTo: {
    id: 'login.newTo',
    defaultMessage: 'New to filespin?',
  },
  createAcc: {
    id: 'login.createAcc',
    defaultMessage: 'Create an account',
  },
});
