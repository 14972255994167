import * as React from 'react';
import cn from 'classnames';
import styles from './AdminSettingsSidebar.module.scss';
import messages from './messages';

type AdminSettingsSidebarProps = {
  className?: string;
  items: SidebarItem[];
  onClick?: (item: SidebarItem) => void;
};

type SidebarItem = {
  key: string;
  label: string;
};

export const AdminSettingsSidebar: React.FC<AdminSettingsSidebarProps> = ({ className, items, onClick, ...rest }) => {
  return (
    <nav className={cn([styles.AdminSettingsSidebar, className])}>
      {items.map((item) => (
        <button key={item.key} type="button" onClick={() => onClick?.(item)}>
          {item.label}
        </button>
      ))}
    </nav>
  );
};

export default AdminSettingsSidebar;
