import * as React from 'react';
import cn from 'classnames';
import styles from './AddAssetsToCollections.module.scss';
import messages from './messages';
import BaseModal, { BaseModalProps } from '../BaseModal/BaseModal';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAllCollectionsQuery } from 'src/api/queries/collectionsQueries';
import Paper from '../Paper';
import Checkbox from '../Checkbox';
import { Collection } from 'src/types/TCollection';
import Button from '../Button';
import useModal from 'src/hooks/useModal';
import { useAtomValue } from 'jotai/utils';
import { selectedAssetsAtom } from 'src/shared/selectedAssets';
import Shimmer from '../Shimmer';
import { useCollectionsMutation } from 'src/api/mutations/collectionMutations';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import Input from '../Input';
import { useDebounce } from 'use-debounce/lib';
import { Panel, User, Users } from 'src/common/icons';
import Badge from '../Badge';
import { useHistory } from 'react-router-dom';

type AddAssetsToCollectionsProps = {
  className?: string;
};

export const AddAssetsToCollections = (props: BaseModalProps) => {
  const { data: allCollectionsResult, isLoading } = useAllCollectionsQuery();
  const [checkedCollections, setCheckedCollections] = React.useState<number[]>([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchTermDebounced] = useDebounce(searchTerm, 250);
  const { closeModal } = useModal();
  const selectedAssets = useAtomValue(selectedAssetsAtom);
  const { showAlert, showSuccess } = useAlerts();
  const { getErrorMessage } = useErrorMessages();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const filteredCollections = React.useMemo(() => {
    if (!allCollectionsResult) {
      return [];
    }

    if (!searchTermDebounced) return allCollectionsResult.data.collections;

    return allCollectionsResult.data.collections.filter((collection: Collection) => {
      return collection.name.toLowerCase().includes(searchTermDebounced.toLowerCase());
    });
  }, [allCollectionsResult, searchTermDebounced, checkedCollections]);

  const collectionMutation = useCollectionsMutation({
    onSuccess: () => {
      showSuccess(
        formatMessage(messages.success, {
          numberOfAssets: selectedAssets.length,
        }),
      );
      closeModal();

      history.push('/collections');
    },
    onError: (error) => {
      showAlert(getErrorMessage(error.message));
    },
  });

  const handleAddAssetsToCollections = () => {
    collectionMutation.mutateAsync({
      ids: checkedCollections,
      additions: selectedAssets,
    });
  };

  const handleCheckboxChange = (collectionId: number) => {
    const newCheckedCollections = checkedCollections.includes(collectionId)
      ? checkedCollections.filter((id) => id !== collectionId)
      : [...checkedCollections, collectionId];
    setCheckedCollections(newCheckedCollections);
  };

  return (
    <BaseModal {...props}>
      <h3>
        <FormattedMessage {...messages.title} />
      </h3>

      <p>
        <FormattedMessage {...messages.description} values={{ numberOfAssets: selectedAssets?.length }} />
      </p>

      <Input
        className={styles.search}
        placeholder="Search Collection"
        variant="secondary"
        value={searchTerm}
        onChange={setSearchTerm}
      />
      <section className={styles.collections}>
        {isLoading &&
          (Array.from(Array(10).keys()) as number[]).map((_, i) => (
            <Shimmer key={i} w="100%" h="4.5rem" radius="0.25rem" />
          ))}
        {filteredCollections.map((collection: Collection) => (
          <Paper key={collection.id} className={styles.collection}>
            <Checkbox
              className={styles.checkbox}
              label={collection.name}
              onChange={() => handleCheckboxChange(collection.id)}
              checked={checkedCollections.includes(collection.id)}
            />

            <span className={styles.assetsCount}>{`${collection.assets.length} assets`}</span>
            <Badge>
              {collection.group_access ? (
                <>
                  <Users />
                  <span>Group</span>
                </>
              ) : (
                <>
                  <User />
                  <span>Private</span>
                </>
              )}
            </Badge>
          </Paper>
        ))}
      </section>

      <section className={styles.actions}>
        <Button size="small" mode="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          size="small"
          mode="primary"
          onClick={handleAddAssetsToCollections}
          disabled={checkedCollections.length === 0}
        >
          Add Assets
        </Button>
      </section>
    </BaseModal>
  );
};

export default AddAssetsToCollections;
