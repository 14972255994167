export const setBodyScroll = (height: string, overflow: string) => {
  const body = document.querySelector('body');

  if (!body) return;

  body.style.height = height;
  body.style.overflow = overflow;
};

export const lockScroll = () => setBodyScroll('100vh', 'hidden');
export const unlockScroll = () => setBodyScroll('', '');
