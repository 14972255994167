

import { wordSubstitutionPayloadAtom } from '../../state';
import { useAtom } from 'jotai';
import useListingRowHooks from './hooks'
import WordsListingRowProps from './types'

import ConfirmSubtitlesWordSubstitutionModal from '../ConfirmSubstitutionWordModal';


/**
 * This is a custom to generate the event handlers .
 * It is used to separate the logic from the view.
 * 
 * @param props
 * @param hooks
 * 
 * @returns
 * 
 * */
const useHandlers = (props: WordsListingRowProps, hooks: ReturnType<typeof useListingRowHooks>) => {

  const [
    wordSubstitutionPayload,
    setWordSubstitutionPayload,
  ] = useAtom(wordSubstitutionPayloadAtom);

  return {
    dialog: {
      open: () => hooks.dialog.setIsOpen(true),
      close: () => hooks.dialog.setIsOpen(false),
      click: (clickedYes: boolean) => {
        if (!clickedYes) {
          hooks.dialog.setIsOpen(false)
          return
        }
        const dataToSend = { ...wordSubstitutionPayload }
        delete dataToSend.words[props.word]
        setWordSubstitutionPayload(dataToSend)
        hooks.dialog.setIsOpen(false)
      }
    },
    actions: {
      edit: () => hooks.modal.showModal(
        <ConfirmSubtitlesWordSubstitutionModal
          title="Edit word."
          type='edit'
          word={props.word}
          variants={props.variants}
        />
      )
    }
  }
}


export default useHandlers