import { FormattedMessage } from 'react-intl';
import { useSettingsQuery } from 'src/api/queries/userQueries';
import { Checks } from 'src/common/icons';
import { Input, SaveChangesBar, SettingsNavigation } from 'src/components';
import messages from './messages';
import styles from './styles.module.scss';
import { useAtom } from 'jotai';
import { storageSettingsAtom } from 'src/shared/settings/storage';
import { ACTION_TYPES } from 'src/shared/settings/base';

export const StorageSettings = () => {
  const { isLoading } = useSettingsQuery();

  const [storageSettings, updateStorageSettings] = useAtom(storageSettingsAtom);

  const handleChange = (name: string, value: string) => {
    updateStorageSettings({ type: ACTION_TYPES.UPDATE_STATE, payload: { [name]: value } });
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.pageWrapper}>
      <SettingsNavigation />
      <SaveChangesBar />

      <div className={styles.container}>
        <table className={styles.filesTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage {...messages.filesHeader} />
              </th>
              <th>
                <FormattedMessage {...messages.storageBucket} />
              </th>
              <th>
                <FormattedMessage {...messages.storagePathPrefix} />
              </th>
              <th>
                <FormattedMessage {...messages.verified} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <FormattedMessage {...messages.originalFiles} />
              </td>
              <td>
                <Input
                  placeholder={messages.storageBucketPlaceholder.defaultMessage}
                  variant="secondary"
                  value={storageSettings.originals_root || ''}
                  onChange={(value: string) => handleChange('originals_root', value)}
                />
              </td>
              <td>
                <Input
                  variant="secondary"
                  value={storageSettings.originals_root_folder || ''}
                  onChange={(value: string) => handleChange('originals_root_folder', value)}
                />
              </td>
              <td>
                {storageSettings.originals_root_ok ? <Checks color="var(--color-prime-400)" fontSize={24} /> : null}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage {...messages.imageConversions} />
              </td>
              <td>
                <Input
                  placeholder={messages.storageBucketPlaceholder.defaultMessage}
                  variant="secondary"
                  value={storageSettings.derivatives_root || ''}
                  onChange={(value: string) => handleChange('derivatives_root', value)}
                />
              </td>
              <td>
                <Input
                  variant="secondary"
                  value={storageSettings.derivatives_root_folder || ''}
                  onChange={(value: string) => handleChange('derivatives_root_folder', value)}
                />
              </td>
              <td>
                {storageSettings.derivatives_root_ok ? <Checks color="var(--color-prime-400)" fontSize={24} /> : null}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage {...messages.videoTranscodes} />
              </td>
              <td>
                <Input
                  placeholder={messages.storageBucketPlaceholder.defaultMessage}
                  variant="secondary"
                  value={storageSettings.transcodes_root || ''}
                  onChange={(value: string) => handleChange('transcodes_root', value)}
                />
              </td>
              <td>
                <Input
                  variant="secondary"
                  value={storageSettings.transcodes_root_folder || ''}
                  onChange={(value: string) => handleChange('transcodes_root_folder', value)}
                />
              </td>
              <td>
                {storageSettings.transcodes_root_ok ? <Checks color="var(--color-prime-400)" fontSize={24} /> : null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
