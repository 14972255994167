import React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';
import styles from '../Modal.module.scss';

export const TabTrigger = ({
	keyName,
	activeKey,
	setKey,
	icon,
}: {
	keyName: string;
	activeKey: string;
	setKey: React.Dispatch<React.SetStateAction<string>>;
	icon?: React.ReactNode;
}) => (
	<button type="button" onClick={() => setKey(keyName)} className={cn({ [styles.active]: activeKey === keyName })}>
		{icon ?? <FormattedMessage {...messages[keyName as 'metadata' | 'core' | 'conversions' | 'addon']} />}
	</button>
);
