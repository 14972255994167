import { FormattedMessage } from 'react-intl';
import { Portal } from 'src/common/Portal';
import { Paper, PrivateField } from 'src/components';
import BaseModal from 'src/components/BaseModal';

import { BaseModalProps } from 'src/components/BaseModal/BaseModal';

import styles from '../styles.module.scss';
import messages from '../messages';

interface KeyModalProps extends BaseModalProps {
  user: any;
  visible?: boolean;
}

const KeyModal = ({ user, ...rest }: KeyModalProps) => {
  if (!user) return null;

  const { uploadKey, access_id, apikey } = user;

  return (
    <BaseModal {...rest}>
      <h3>
        <FormattedMessage {...messages.keysModalTitle} values={{ email: user.email }} />
      </h3>
      {access_id && (
        <Paper>
          <p>Access Key</p>
          <PrivateField fieldName="Access Key">{(visible, style) => <p className={style}>{access_id}</p>}</PrivateField>
        </Paper>
      )}

      {uploadKey && (
        <Paper>
          <p>Upload Key</p>
          <PrivateField fieldName="Upload Key">{(visible, style) => <p className={style}>{uploadKey}</p>}</PrivateField>
        </Paper>
      )}
      {apikey && (
        <Paper>
          <p>API Key</p>
          <PrivateField fieldName="API Key">{(visible, style) => <p className={style}>{apikey}</p>}</PrivateField>
        </Paper>
      )}
    </BaseModal>
  );
};

export default KeyModal;
