import { merge } from 'lodash';
import { createAtom } from './base';
import { getSettings } from './utils';

export const { currentDatabaseSettingsAtom, updatedDatabaseSettingsAtom, databaseSettingsAtom } = createAtom(
  'database',
  {
    updater: (currentAtom: any, updatedAtom: any, get: any, set: any, action: any) => {
      const { payload } = action;

      const state = get(updatedAtom);

      const mergedState = merge({}, state, payload);

      set(updatedAtom, mergedState);
    },
  },
);

export const getDatabaseSettings = (get: any) => getSettings(get, [databaseSettingsAtom, updatedDatabaseSettingsAtom]);
