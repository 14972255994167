import * as React from 'react';

function SvgUsers(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M8.25 15a4.875 4.875 0 100-9.75 4.875 4.875 0 000 9.75zM14.57 5.432A4.875 4.875 0 1115.893 15M1.5 18.506a8.252 8.252 0 0113.5 0M15.893 15a8.24 8.24 0 016.75 3.505"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgUsers;
