import * as React from 'react';
import cn from 'classnames';
import styles from './MetadataForm.module.scss';
import messages from './messages';
import SchemaForm from '../SchemaForm';
import { ISubmitEvent } from '@rjsf/core';
import validateFormData from '@rjsf/core/lib/validate';
import * as Sentry from '@sentry/react';
import { useIntl } from 'react-intl';

type MetadataFormProps = {
  className?: string;
  schema?: any;
  metadata: any;
  onSubmit: ((e: ISubmitEvent<any>, nativeEvent: React.FormEvent<HTMLFormElement>) => any) | undefined;
  onCancel: () => void;
};

const generateSchemaFromData = (title: string, data: any) => {
  const keys = Object.keys(data);

  const schema: any = {
    type: 'object',
    properties: {},
    title,
  };

  keys.forEach((key: string) => {
    schema.properties[key] = {
      type: 'string',
      title: key,
    };
  });

  return schema;
};

export const MetadataForm: React.FC<MetadataFormProps> = ({
  className,
  schema,
  metadata,
  onSubmit,
  onCancel,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  let formSchema = schema;

  if (!schema) {
    const schemaTitle = formatMessage(messages.customSchema);
    formSchema = generateSchemaFromData(schemaTitle, metadata);
  } else {
    const validation = validateFormData(metadata, schema);

    if (validation.errors.length > 0) {
      const schemaTitle = formatMessage(messages.customSchema);
      formSchema = generateSchemaFromData(schemaTitle, metadata);
      Sentry.captureMessage(`Schema Errors: ${validation.errors.toString()}`, Sentry.Severity.Error);
    }
  }

  return (
    <div className={cn([styles.MetadataForm, className])}>
      <SchemaForm schema={formSchema} formData={metadata} onSubmit={onSubmit} onCancel={onCancel} />
    </div>
  );
};

export default MetadataForm;
