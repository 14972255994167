import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteAsset, restoreAsset, purgeAssets } from 'src/api/functions/assetsApi';
import { useAlerts } from 'src/common/AlertManager';
import { Trash, CaretDown, ArrowCounterClockwise } from 'src/common/icons';
import { PERMISSIONS } from 'src/common/roles';
import { useAccessToken } from 'src/hooks/useAccessToken';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import useRoleManager from 'src/hooks/useRoleManager';
import { deletedAssetsAtom } from 'src/shared/deletedAssets';
import { selectedAssetsAtom } from 'src/shared/selectedAssets';
import { hasTrashedAssetsAtom } from 'src/shared/trash';
import Button from '../Button';
import Dropdown from '../Dropdown';
import { SelectInputOption } from '../Dropdown/Dropdown';
import styles from './BatchActions.module.scss';

const TrashActions = () => {
  const [isDeletingAssets, setIsDeletingAssets] = useState(false);
  const [isRestoringAssets, setIsRestoringAssets] = useState(false);
  const [selectedAssets, updateSelectedAssets] = useAtom(selectedAssetsAtom);
  const [deletedAssets, updateDeletedAssets] = useAtom(deletedAssetsAtom);
  const hasTrashedAssets = useAtomValue(hasTrashedAssetsAtom);
  const { hasPermission } = useRoleManager();
  const history = useHistory();
  const location = useLocation();
  const accessToken = useAccessToken();
  const { showAlert, showSuccess } = useAlerts();
  const { getErrorMessage } = useErrorMessages();

  const handleGoToTrash = () => history.push('/trash');

  const trashDropdownOptions = useMemo(() => {
    const options: Array<SelectInputOption> = [];

    if (location.pathname === '/trash') {
      options.push(
        {
          key: 'restore',
          value: 'Restore Selected Assets',
          icon: <ArrowCounterClockwise />,
          optionDisabled: selectedAssets.length === 0,
        },
        {
          key: 'purge',
          value: 'Purge Trash',
          noBorderBottom: true,
          icon: <Trash />,
          optionDisabled: !hasTrashedAssets || !hasPermission(PERMISSIONS.PURGE_TRASH),
        },
      );
    }

    return options;
  }, [selectedAssets, hasTrashedAssets]);

  const handleRestoreSelectedAssets = async () => {
    if (isRestoringAssets || isDeletingAssets) return;

    setIsRestoringAssets(true);

    try {
      const response: any = await Promise.all(selectedAssets.map((assetId) => restoreAsset(assetId, accessToken)));
      if (typeof response.status === 'number' && !([200,202].includes(response.status))) {
        throw response;
      }

      showSuccess(`Successfully restored selected assets`);
    } catch (e: any) {
      showAlert(getErrorMessage(e.message));
    } finally {
      setIsRestoringAssets(false);
      updateSelectedAssets([]);
    }
  };

  const handlePurgeAssets = async () => {
    try {
      await purgeAssets(accessToken);
      showSuccess(`Asset purging has started, this might take a while.`);
    } catch (e: any) {
      showAlert(getErrorMessage(e.message));
    }
  };

  const handleTrashMenuClick = (key: string) => {
    switch (key) {
      case 'restore':
        handleRestoreSelectedAssets();
        break;
      case 'purge':
        handlePurgeAssets();
        break;
    }
  };

  return (
    <div className={styles.batchAction}>
      <Button
        mode="tertiary"
        size="small"
        className={styles.batchActionPrimaryButton}
        onClick={handleGoToTrash}
        disabled={!hasPermission(PERMISSIONS.DELETE_UNDELETE_ASSETS)}
        icon={<Trash />}
        data-no-dropdown={trashDropdownOptions.length === 0}
      >
        Trash
      </Button>

      {trashDropdownOptions.length > 0 && (
        <Dropdown
          className={styles.batchActionDropdown}
          icon={<CaretDown />}
          options={trashDropdownOptions}
          onClick={handleTrashMenuClick}
          title="Trash"
          titleLink="/trash"
          dropdownAnchor="right"
        />
      )}
    </div>
  );
};

export default TrashActions;
