import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { useEffect, useState, useMemo } from 'react';
import {
  SearchBar,
  ListingCard,
  ListingRow,
  Input,
  PageTitle,
  Tag,
  Pager,
  Button,
  Checkbox,
  BatchActions,
  LayoutViewSwitcher,
  LayoutView,
  AddAssetsToCollections,
  Select,
} from 'src/components';
import { useSearchQuery } from 'src/api/queries/assetsQueries';
import { getThumbnailUrl } from 'src/utils/previews';
import { CaretRight, CaretDown, FolderPlus, Stack, Trash } from 'src/common/icons';
import styles from './styles.module.scss';
import messages from './messages';
import useFileSpinPicker from 'src/hooks/useFileSpinPicker';
import { lockScroll, unlockScroll } from 'src/utils/bodyScroll';
import { useCollectionMutation } from 'src/api/mutations/collectionMutations';
import { queryNames } from 'src/api/utils/queryNames';
import { CollectionResponse } from 'src/types/TCollection';
import { deleteAsset } from 'src/api/functions/assetsApi';
import CreateCollectionModal from 'src/components/CreateCollectionModal';
import { useCachePreviewImages } from './hooks';
import useComponentHooks from './hooks';
import { ITEMS_PER_PAGE_LOCAL_KEY, PAGE_ACTIONS } from './constants';
import { statusOptions } from 'src/api/queries/assetsQueries';

/**
 *  @name Dashboard
 *  @module pages/Dashboard
 *
 *  @route GET /
 *  @route GET /assets
 *
 *  @description The Dashboard page.
 *
 *  @summary
 *  This page is the main page of the application. It displays the assets of the user.
 *  It is possible to filter the assets by using the search bar, the filters and the sorting.
 *  It is also possible to add assets to a collection, delete assets and create a new collection.
 *  The assets are displayed in a list or in a grid. The user can switch between the two views.
 *  The user can also select assets to perform batch actions on them.
 *  The user can also navigate between the pages of the assets.
 *
 *  @param {void}
 *
 *  @returns {JSX.Element} The Dashboard page as a JSX.Element.
 *
 */
export const Dashboard = (): JSX.Element => {
  const hooks = useComponentHooks();

  const {
    data: fetchedData,
    isLoading,
    refetch,
    isRefetching,
  } = useSearchQuery(hooks.reducer.filters, {
    onSuccess: (results) => {
      hooks.state.totalPages !== results.total_pages && hooks.state.setTotalPages(results.total_pages);
      hooks.refs.searchHash.current = results.search_result_id;
    },
  });

  const basketMutation = useCollectionMutation(Number(hooks.queries.collections.data?.basketData?.data?.id), {
    onError: (error, newData, context: any) => {
      hooks.queryClient.setQueryData([queryNames.collection, null], context.previousData);

      hooks.alerts.showAlert(hooks.errorMessages.getErrorMessage(error.message));
    },
    onMutate: (data) => {
      const previousData = hooks.queryClient.getQueryData<CollectionResponse>([queryNames.collection, null]);
      hooks.queryClient.setQueryData<CollectionResponse | undefined>(
        [queryNames.collection, null],
        (old: CollectionResponse | undefined) => {
          if (typeof old !== 'undefined') {
            old.data.assets = (old?.data?.assets ?? [])
              .filter((id) => !(data?.deletions ?? []).includes(id))
              .concat(data?.additions ?? []);
          }
          return old;
        },
      );
      return { previousData };
    },
    onSuccess: () => {
      hooks.alerts.showSuccess('Request succeeded. Refreshing data...');
    },
  });

  const { uploadContent } = useFileSpinPicker({
    events: {
      complete: (d) => {
        console.log(data, d);
        // TODO: Implement a global search & pagination state handler
        refetch();
      },
      close: () => unlockScroll(),
      open: () => lockScroll(),
    },
  });

  useEffect(() => {
    hooks.state.updateDeletedAssets([]);
  }, []);

  const data = useMemo(() => {
    const result = fetchedData?.result.filter((asset: string) => !hooks.state.deletedAssets.includes(asset));
    return { ...fetchedData, result };
  }, [fetchedData, hooks.state.deletedAssets]);

  useCachePreviewImages(data);

  const handleAddAsset = () => {
    uploadContent();
  };

  const handleAddSelectedAssetsToBasket = () => {
    if (basketMutation.isLoading) return;
    basketMutation.mutateAsync({ additions: hooks.state.selectedAssets });
    hooks.state.updateSelectedAssets([]);
  };

  useEffect(() => {
    localStorage.setItem(ITEMS_PER_PAGE_LOCAL_KEY, String(hooks.reducer.filters.limit_per_page));
  }, [hooks.reducer.filters.limit_per_page]);

  useEffect(() => {
    hooks.reducer.dispatchFilter({ sort_by: [hooks.state.sorting.join('_')] });
  }, [hooks.state.sorting]);

  useEffect(() => {
    hooks.reducer.dispatchFilter({ keyword: hooks.searchDebounced, page: 1, hash: '' });
  }, [hooks.searchDebounced]);

  useEffect(() => {
    hooks.reducer.dispatchFilter({ file_name: hooks.fileNameDebounced, page: 1, hash: '' });
  }, [hooks.fileNameDebounced]);

  // needs bridge Layer for filters change (with debounce?), commit each change with hash -> '' and page -> 1

  useEffect(() => {
    if (isRefetching) return;
    refetch();
  }, [hooks.state.refreshValue]);

  useEffect(() => {
    hooks.state.setIsRefetching(isRefetching);
  }, [isRefetching]);

  const handleOnAssetSelectChange = (assetId: string) => {
    const newSelectedAssets = hooks.state.selectedAssets.includes(assetId)
      ? hooks.state.selectedAssets.filter((id) => id !== assetId)
      : [...hooks.state.selectedAssets, assetId];
    hooks.state.updateSelectedAssets(newSelectedAssets);
  };

  const handleSelectAllAssets = (selected: boolean) => {
    const newSelectedAssets = selected ? data.result : [];
    hooks.state.updateSelectedAssets(newSelectedAssets);
  };

  const handleMoveToTrash = async () => {
    if (hooks.state.isDeletingAssets) return;

    hooks.state.setIsDeletingAssets(true);

    try {
      const response: any = await Promise.all(
        hooks.state.selectedAssets.map((assetId) => deleteAsset(assetId, hooks.accessToken)),
      );

      if (typeof response.status === 'number' && !([200,202].includes(response.status))) {
        throw response;
      }

      const newDeletedAssets = [...hooks.state.deletedAssets, ...hooks.state.selectedAssets];

      hooks.state.updateDeletedAssets(newDeletedAssets);
      hooks.alerts.showSuccess(`Successfully deleted selected assets`);
    } catch (error: any) {
      const errorMessage = hooks.errorMessages.getErrorMessage(error.message);
      hooks.alerts.showAlert(errorMessage);
    } finally {
      hooks.state.setIsDeletingAssets(false);
      hooks.state.updateSelectedAssets([]);
    }
  };

  useEffect(() => {
    return () => {
      console.log('clearing selected assets');
      hooks.state.updateSelectedAssets([]);
    };
  }, []);

  const handleOnClickAction = (option: string) => {
    switch (option) {
      case PAGE_ACTIONS.ADD_TO_COLLECTIONS:
        const addAssetsToCollectionsElement = <AddAssetsToCollections />;
        return hooks.modal.showModal(addAssetsToCollectionsElement);
      case PAGE_ACTIONS.ADD_TO_BASKET:
        return handleAddSelectedAssetsToBasket();
      case PAGE_ACTIONS.MOVE_TO_TRASH:
        return handleMoveToTrash();
      case PAGE_ACTIONS.CREATE_COLLECTION:
        const routeToCollections = () => hooks.history.push('/collections');
        const element = <CreateCollectionModal onCreateCollection={routeToCollections} />;
        return hooks.modal.showModal(element);
    }
  };

  const pageActions = useMemo(() => {
    const actions = [
      {
        key: PAGE_ACTIONS.ADD_TO_COLLECTIONS,
        value: 'Add to Collections',
        noBorderBottom: false,
        icon: <FolderPlus />,
        optionDisabled: hooks.state.selectedAssets.length === 0,
      },
      {
        key: PAGE_ACTIONS.CREATE_COLLECTION,
        value: 'Create new Collection',
        noBorderBottom: false,
        icon: <Trash />,
        optionDisabled: hooks.state.selectedAssets.length === 0,
      },
      {
        key: PAGE_ACTIONS.ADD_TO_BASKET,
        value: 'Add to Basket',
        noBorderBottom: false,
        icon: <Stack />,
        optionDisabled: hooks.state.selectedAssets.length === 0,
      },
    ];

    if (!hooks.roleManager.isUser) {
      actions.push({
        key: PAGE_ACTIONS.MOVE_TO_TRASH,
        value: 'Move to Trash',
        noBorderBottom: false,
        icon: <Trash />,
        optionDisabled: hooks.state.selectedAssets.length === 0,
      });
    }

    return actions;
  }, [hooks.roleManager.isUser, hooks.state.selectedAssets]);

  const handleFilenameChange = (value: string) => hooks.state.setFileName(value);

  return (
    <div className={styles.pageWrapper}>
      {hooks.state.filtersVisible && (
        <div className={cn([styles.filtersContent])}>
          <FoldableFilter title={'File Name'}>
            <Input value={hooks.state.fileName} onChange={handleFilenameChange} />
          </FoldableFilter>
          <FoldableFilter title={'Content Type'}>
            <Checkbox
              label="Image"
              checked={hooks.reducer.filters.content_type === 'image'}
              onChange={() => {
                hooks.reducer.filters.content_type === 'image'
                  ? hooks.reducer.dispatchFilter({ content_type: '', page: 1, hash: '' })
                  : hooks.reducer.dispatchFilter({ content_type: 'image', page: 1, hash: '' });
              }}
            />
            <Checkbox
              label="Video"
              checked={hooks.reducer.filters.content_type === 'video'}
              onChange={() => {
                hooks.reducer.filters.content_type === 'video'
                  ? hooks.reducer.dispatchFilter({ content_type: '', page: 1, hash: '' })
                  : hooks.reducer.dispatchFilter({ content_type: 'video', page: 1, hash: '' });
              }}
            />
            <Checkbox
              label="Audio"
              checked={hooks.reducer.filters.content_type === 'audio'}
              onChange={() => {
                hooks.reducer.filters.content_type === 'audio'
                  ? hooks.reducer.dispatchFilter({ content_type: '', page: 1, hash: '' })
                  : hooks.reducer.dispatchFilter({ content_type: 'audio', page: 1, hash: '' });
              }}
            />
          </FoldableFilter>
          <FoldableFilter title={'Upload Date Range'}>
            <div className={styles.rangeValues}>
              <Input
                type="date"
                value={hooks.reducer.filters.upload_time_range.start?.substring(0, 10)}
                onChange={(v) =>
                  hooks.reducer.dispatchFilter({
                    upload_time_range: {
                      start: new Date(v).toISOString(),
                      end: hooks.reducer.filters.upload_time_range.end,
                    },
                  })
                }
              />
              <Input
                type="date"
                value={hooks.reducer.filters.upload_time_range.end?.substring(0, 10)}
                onChange={(v) =>
                  hooks.reducer.dispatchFilter({
                    upload_time_range: {
                      end: new Date(new Date(v).getTime() + 86400000 - 1).toISOString(),
                      start: hooks.reducer.filters.upload_time_range.start,
                    },
                  })
                }
              />
            </div>
            {/* TODO: 1. DOCUMENT DATES FILTER, 2. Dates manipulations needs proper abstraction */}
          </FoldableFilter>
          <FoldableFilter title={'Status'}>
            <Select
              className={styles.Select}
              options={statusOptions}
              value={hooks.reducer.filters.status}
              onChange={(v) => {
                hooks.reducer.dispatchFilter({
                  status: v,
                });
              }}
            />
          </FoldableFilter>
        </div>
      )}

      <div className={cn([styles.pageContent])}>
        <PageTitle
          title={'Assets'}
          counter={data?.total_files}
          rightContainer={
            hooks.roleManager.isUser ? null : (
              <Button size="small" onClick={handleAddAsset}>
                <FormattedMessage {...messages.add} />
              </Button>
            )
          }
        />
        <SearchBar
          className={styles.searchBar}
          search={hooks.state.search}
          sortKeys={[
            { key: 'upload_time_range', value: 'Upload Time' },
            { key: 'file_name', value: 'File Name' },
            { key: 'content_type', value: 'Content Type' },
            { key: 'file_size_range', value: 'File Size' },
          ]}
          onSelectSort={(sortKey) => hooks.state.setSorting(sortKey)}
          onSearch={(searchTerm) => hooks.state.setSearch(searchTerm)}
          onClickFiltersButton={() => hooks.state.setFiltersVisible(!hooks.state.filtersVisible)}
        />
        <div className={styles.pageActions}>
          <BatchActions
            showSelectAll={data?.result?.length > 0}
            onSelectAll={handleSelectAllAssets}
            pageActions={{
              options: pageActions,
              onClick: handleOnClickAction,
            }}
          />
          <LayoutViewSwitcher />
        </div>
        {hooks.state.search !== '' && (
          <div className={styles.filtersWrapper}>
            <div className={styles.tags}>
              {hooks.state.search !== '' && <Tag text={hooks.state.search} onClose={() => hooks.state.setSearch('')} />}
              {hooks.reducer.filters.content_type !== '' && (
                <Tag
                  text={`Content Type: ${hooks.reducer.filters.content_type}`}
                  onClose={() => hooks.reducer.dispatchFilter({ content_type: '', page: 1, hash: '' })}
                />
              )}
              {/*<Button size="small" mode="flat">
              <FormattedMessage {...messages.saveSearch} />
            </Button>*/}
            </div>
          </div>
        )}
        <LayoutView
          data={data?.result}
          emptyDataMessage="No assets found"
          // isLoading={fetchedData === undefined}
          //isLoading={isLoading}
          isLoading={isLoading || isRefetching}
          classNames={{
            cardView: 'listingGridWrapper',
          }}
          cardView={(assetId: string) => (
            <ListingCard
              key={assetId}
              id={assetId}
              siblings={data?.result}
              imageUrl={getThumbnailUrl({ width: 300, height: 300, id: assetId, jwt: hooks.accessToken })}
              name={data?.extended_result[assetId]?.name}
              size={data?.extended_result[assetId]?.size}
              contentType={data?.extended_result[assetId]?.content_type}
              trashed={data?.extended_result[assetId]?.trashed}
              status={data?.extended_result[assetId]?.status}
              onSelectChange={() => handleOnAssetSelectChange(assetId)}
              isSelected={hooks.state.selectedAssets.includes(assetId)}
              error={data?.extended_result[assetId]?.errors}
            />
          )}
          listView={(assetId: string) => (
            <ListingRow
              key={assetId}
              id={assetId}
              siblings={data?.result}
              imageUrl={getThumbnailUrl({ width: 100, height: 100, id: assetId, jwt: hooks.accessToken })}
              name={data?.extended_result[assetId]?.name}
              size={data?.extended_result[assetId]?.size}
              uploadTime={data?.extended_result[assetId]?.upload_time}
              contentType={data?.extended_result[assetId]?.content_type}
              trashed={data?.extended_result[assetId]?.trashed}
              status={data?.extended_result[assetId]?.status}
              onSelectChange={() => handleOnAssetSelectChange(assetId)}
              isSelected={hooks.state.selectedAssets.includes(assetId)}
              error={data?.extended_result[assetId]?.errors}
            />
          )}
        />

        {hooks.state.totalPages > 1 && (
          <div className={styles.pagerWrapper}>
            <Pager
              currentPage={hooks.reducer.filters.page}
              totalPages={hooks.state.totalPages}
              onChange={(page) => hooks.reducer.dispatchFilter({ page, hash: hooks.refs.searchHash.current })}
              itemsPerPage={hooks.reducer.filters.limit_per_page}
              onItemsPerPageChange={(newLimit) =>
                hooks.reducer.dispatchFilter({ page: 1, hash: '', limit_per_page: newLimit })
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const FoldableFilter: React.FC<{ title: string }> = ({ title, children }) => {
  const [isRevealed, setIsRevealed] = useState(true);
  return (
    <div className={styles.foldableContainer}>
      <h4 onClick={() => setIsRevealed(!isRevealed)}>
        {isRevealed ? <CaretDown /> : <CaretRight />} {title}
      </h4>
      {isRevealed && <div className={styles.filterChildren}>{children}</div>}
    </div>
  );
};
