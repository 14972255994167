import * as React from 'react';

function SvgWarningCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M12 21a9 9 0 100-18 9 9 0 000 18zM12 7.5v5.25"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 17.063a.937.937 0 100-1.875.937.937 0 000 1.874z" fill="currentColor" />
    </svg>
  );
}

export default SvgWarningCircle;
