import { useOnboardingContext } from '../context';
import StepBar from '../components/StepBar';
import styles from '../styles.module.scss';

import { Watermark as WatermarkSettings } from '../../../admin-pages/ImageSettings/components/Watermark';
import { Footer, Loader } from 'src/components';
import { useAtomValue } from 'jotai/utils';
import { updatedSettingsAtom } from 'src/shared/settings';
import { useSettingsMutation } from 'src/api/mutations/userMutations';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import { useSettingsQuery } from 'src/api/queries/userQueries';

const Watermark = () => {
  const { goToNextStep, goToPreviousStep } = useOnboardingContext();
  const updatedSettings = useAtomValue(updatedSettingsAtom);

  const hasChanges = !!updatedSettings;

  const { isLoading } = useSettingsQuery();

  const { showAlert } = useAlerts();
  const { getErrorMessage } = useErrorMessages();

  const settingsMutation = useSettingsMutation({
    onError: (error) => {
      showAlert(getErrorMessage(error.message));
    },
    onSuccess: () => {
      goToNextStep();
    },
  });

  const handleGoToNextStep = () => {
    if (!hasChanges) return goToNextStep();

    settingsMutation.mutateAsync(null);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main className={styles.step}>
      <StepBar
        onGoToNextStep={handleGoToNextStep}
        onGoToPreviousStep={goToPreviousStep}
        loadingNextStep={settingsMutation.isLoading}
        hasUnsavedChanges={hasChanges}
      />
      <section className={styles.container}>
        <p className={styles.stepInfo}>
          Setup an optional watermark image. This will be automatically used when we need to provide a watermarked image or video asset.
          This watermark image can be updated in your account settings page at any time.
        </p>
        <WatermarkSettings />
      </section>
      <Footer />
    </main>
  );
};

export default Watermark;
