import { defineMessages } from 'react-intl';

export default defineMessages({
  addUser: {
    id: 'page.admin.users.adduser',
    defaultMessage: 'Add User',
  },
  searchFilter: {
    id: 'page.admin.users.searchfilter',
    defaultMessage: 'Search Filter',
  },
  firstName: {
    id: 'page.admin.users.firstname',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'page.admin.users.lastname',
    defaultMessage: 'Last Name',
  },
  enabledOnly: {
    id: 'page.admin.users.enabledonly',
    defaultMessage: 'Enabled Only',
  },
  searchPlaceholder: {
    id: 'page.admin.users.searchplaceholder',
    defaultMessage: 'Search by email',
  },
  pageTitle: {
    id: 'page.admin.users.title',
    defaultMessage: 'Users',
  },
  loadingMessage: {
    id: 'page.admin.users.loading',
    defaultMessage: 'Loading users...',
  },
  clickToView: {
    id: 'page.admin.users.clicktoview',
    defaultMessage: 'Click to view',
  },
  enabled: {
    id: 'page.admin.users.enabled',
    defaultMessage: 'Enabled',
  },
  disabled: {
    id: 'page.admin.users.disabled',
    defaultMessage: 'Disabled',
  },
  role: {
    id: 'page.admin.users.role',
    defaultMessage: 'Role',
  },
  keys: {
    id: 'page.admin.users.keys',
    defaultMessage: 'Keys',
  },
  status: {
    id: 'page.admin.users.status',
    defaultMessage: 'Status',
  },
  options: {
    id: 'page.admin.users.options',
    defaultMessage: 'Options',
  },
  notificationEmail: {
    id: 'page.admin.users.notificationemail',
    defaultMessage: 'Notification Email',
  },
  allowUserGroupAssets: {
    id: 'page.admin.users.allowusergroupassets',
    defaultMessage: 'Allow User Group Assets',
  },
  submit: {
    id: 'page.admin.users.submit',
    defaultMessage: 'Submit',
  },
  cancel: {
    id: 'page.admin.users.cancel',
    defaultMessage: 'Cancel',
  },
  newUserModalTitle: {
    id: 'page.admin.users.newusermodaltitle',
    defaultMessage: 'New User',
  },
  userAddedSuccessfully: {
    id: 'page.admin.users.useraddedsuccessfully',
    defaultMessage: 'User added successfully, please wait for the user to be processed by our systems...',
  },
  userEditedSuccessfully: {
    id: 'page.admin.users.usereditedsuccessfully',
    defaultMessage: 'User {email} updated successfully',
  },
  editingUserModalTitle: {
    id: 'page.admin.users.editingusermodaltitle',
    defaultMessage: 'Editing {email}',
  },
  newPassword: {
    id: 'page.admin.users.newpassword',
    defaultMessage: 'New Password',
  },
  keysModalTitle: {
    id: 'page.admin.users.keysmodaltitle',
    defaultMessage: `Viewing {email}'s keys`,
  },
});
