import * as React from 'react';
import cn from 'classnames';
import { detectExtension } from 'mimetypes';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useAuthUser } from 'react-auth-kit';
import { useAccessToken } from 'src/hooks/useAccessToken';
import useRoleManager from 'src/hooks/useRoleManager';

import { deleteAsset, getAssetMediaUrl, restoreAsset } from 'src/api/functions/assetsApi';
import Checkbox from '../Checkbox';
import {
  PlayBox,
  FileArrowDown,
  CaretDown,
  Eye,
  TrashSimple,
  ArrowCounterClockwise,
  Gear,
  Stack,
} from 'src/common/icons';
import { isPreviewPlayable } from 'src/utils/previews';
import { humanFileSize } from 'src/utils/transform';

import styles from './ListingCard.module.scss';
import messages from './messages';
import { useState } from 'react';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import ReactTooltip from 'react-tooltip';
import { useAtom } from 'jotai';
import { deletedAssetsAtom } from 'src/shared/deletedAssets';
import AssetPreview from '../AssetPreview';
import { useCollectionMutation } from 'src/api/mutations/collectionMutations';
import { useCollectionQuery } from 'src/api/queries/collectionsQueries';
import { queryNames } from 'src/api/utils/queryNames';
import { CollectionResponse } from 'src/types/TCollection';
import { useQueryClient } from 'react-query';

const timestamp = Date.now();

type ListingCardProps = {
  className?: string;
  id: string;
  siblings?: string[];
  imageUrl: string;
  name: string;
  size: string;
  trashed: boolean;
  status: string;
  contentType: string;
  isSelected?: boolean;
  error?: any;
  onSelectChange?: React.ChangeEventHandler<HTMLInputElement> & ((value: React.ChangeEvent<HTMLInputElement>) => void);
};

export const ListingCard: React.FC<ListingCardProps> = ({
  className,
  id,
  siblings,
  imageUrl,
  name,
  size,
  trashed,
  contentType,
  isSelected,
  onSelectChange,
  status,
  error,
  ...rest
}) => {
  const location = useLocation();
  const downloadingRef = React.useRef('');
  const auth = useAuthUser();
  const accessToken = useAccessToken();
  const { isUser } = useRoleManager();

  const { showSuccess, showAlert } = useAlerts();
  const { getErrorMessage } = useErrorMessages();

  const [isDeletingAsset, setIsDeletingAsset] = useState(false);
  const [isRestoringAsset, setIsRestoringAsset] = useState(false);

  const [deletedAssets, updateDeletedAssets] = useAtom(deletedAssetsAtom);
  const queryClient = useQueryClient();

  const { data: basketData } = useCollectionQuery(undefined, {
    staleTime: 1000 * 60 * 5,
  });

  const basketMutation = useCollectionMutation(Number(basketData?.data?.id), {
    onError: (error, newData, context: any) => {
      queryClient.setQueryData([queryNames.collection, null], context.previousData);

      showAlert(getErrorMessage(error.message));
    },
    onMutate: (data) => {
      /*showInfo(`Requesting update...`);*/
      const previousData = queryClient.getQueryData<CollectionResponse>([queryNames.collection, null]);
      queryClient.setQueryData<CollectionResponse | undefined>(
        [queryNames.collection, null],
        (old: CollectionResponse | undefined) => {
          if (typeof old !== 'undefined') {
            old.data.assets = (old?.data?.assets ?? [])
              .filter((id) => !(data?.deletions ?? []).includes(id))
              .concat(data?.additions ?? []);
          }
          return old;
        },
      );
      return { previousData };
    },
    onSuccess: () => {
      showSuccess('Successfully added asset to basket');
    },
  });

  const getMediaUrl = async () => {
    if (downloadingRef.current === '') {
      const url = await getAssetMediaUrl(id, 'original', auth()!.accessId, 'download', accessToken);
      downloadingRef.current = url;
      window.open(url);
    } else {
      window.open(downloadingRef.current);
    }
  };

  const handleRestoreAsset = async () => {
    if (isRestoringAsset) return;

    setIsRestoringAsset(true);
    try {
      const response = await restoreAsset(id, accessToken);

      if (typeof response.status === 'number' && !([200,202].includes(response.status))) {
        throw response;
      }

      showSuccess(`Successfully restored asset.`);
    } catch (e: any) {
      showAlert(getErrorMessage(e.message));
    } finally {
      setIsRestoringAsset(false);
    }
  };

  const handleDeleteAsset = async () => {
    if (isDeletingAsset) return;

    setIsDeletingAsset(true);
    try {
      const response = await deleteAsset(id, accessToken);

      if (typeof response.status === 'number' && !([200,202].includes(response.status))) {
        throw response;
      }

      showSuccess(`Successfully deleted asset.`);
      updateDeletedAssets([...deletedAssets, id]);
    } catch (e: any) {
      showAlert(getErrorMessage(e.message));
    } finally {
      setIsDeletingAsset(false);
    }
  };

  const handleAddToBasket = () => {
    if (basketMutation.isLoading) return;
    basketMutation.mutateAsync({ additions: [id] });
  };

  return (
    <div className={cn([styles.ListingCard, className])}>
      <div className={styles.thumbWrapper}>
        {status === 'NOT_READY' && (
          <div className={styles.processingAsset}>
            <Gear /> <span>Processing</span>
          </div>
        )}

        <AssetPreview src={imageUrl} name={name} status={status} contentType={contentType} />
        {contentType && status !== 'NOT_READY' && isPreviewPlayable(contentType) && (
          <div className={styles.playbox}>
            <PlayBox />
          </div>
        )}
        <div className={styles.overlay}>
          {!trashed && (
            <Link
              className={styles.primeButton}
              to={{
                pathname: `/asset/${id}`,
                state: { background: location, siblings },
              }}
            >
              <Eye />
              <FormattedMessage {...messages.view} />
            </Link>
          )}
          {!trashed && (
            <button className={styles.primeButton} onClick={getMediaUrl}>
              <FileArrowDown />
              <FormattedMessage {...messages.download} /> <CaretDown />
            </button>
          )}
          <div className={styles.overlayFooter}>
            {!trashed && (
              <button data-tip="Add to Basket" onClick={handleAddToBasket}>
                <Stack />
                <ReactTooltip effect="solid" />
              </button>
            )}
            {isUser ? (
              <></>
            ) : (
              <>
                {!isUser && trashed ? (
                  <button data-tip="Restore Asset" onClick={handleRestoreAsset}>
                    <ArrowCounterClockwise />
                    <ReactTooltip effect="solid" />
                  </button>
                ) : (
                  <button data-tip="Delete Asset" onClick={handleDeleteAsset}>
                    <TrashSimple />
                    <ReactTooltip effect="solid" />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.titleWrapper}>
        <div className={styles.checkbox}>
          <Checkbox checked={isSelected} onChange={onSelectChange} />
        </div>
        <div className={styles.title}>
          <div className={styles.titleHolder}>
            {error?.original && (
              <button data-tip={error?.original} className={styles.errorAsset}>
                <span className={styles.errorTitleStyle}>ERROR</span>
                <ReactTooltip effect="solid" />
              </button>
            )}
            {!trashed ? (
              <Link
                to={{
                  pathname: `/asset/${id}`,
                  state: { background: location, siblings },
                }}
              >
                {name}
              </Link>
            ) : (
              <span>{name}</span>
            )}
          </div>
          <small>
            {detectExtension(contentType).toUpperCase()} {humanFileSize(Number(size))}
          </small>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
