import { useAuthUser } from 'react-auth-kit';
import rolesConfig, { PERMISSIONS, ROLES } from 'src/common/roles';

const useRoleManager = () => {
  const auth = useAuthUser();

  const userRole: ROLES = auth()?.role || '';

  const hasRole = (role: string) => role === userRole;

  const hasPermission = (permission: PERMISSIONS) => rolesConfig[userRole]?.includes(permission);

  const hasAnyPermission = (...args: PERMISSIONS[]) => args.some((permission) => hasPermission(permission));

  const hasPermissions = (...args: PERMISSIONS[]) => args.every((permission) => hasPermission(permission));

  return {
    isAdmin: hasRole(ROLES.ADMIN),
    isManager: hasRole(ROLES.MANAGER),
    isUser: hasRole(ROLES.USER),
    isCreator: hasRole(ROLES.CREATOR),
    hasPermission,
    hasAnyPermission,
    hasPermissions,
  };
};

export default useRoleManager;
