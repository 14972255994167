import React, { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import cn from 'classnames';
import styles from './SearchFiltersSidebar.module.scss';
import messages from './messages';
import { CaretDown, CaretRight } from 'src/common/icons';
import Input from '../Input';
import Checkbox from '../Checkbox';
import Button from '../Button';
import { merge } from 'lodash';

type SearchTypes = 'text' | 'checkbox' | 'select' | 'date';

interface BaseFilterInput {
  type: SearchTypes;
  label?: string;
  placeholder?: string;
  initialValue?: any;
}

export interface FilterInputNoKey extends BaseFilterInput {}

export interface FilterInputWithKey extends BaseFilterInput {
  key: string;
}

export type SearchFilter = {
  title: string;
  key: string;
  inputs: FilterInputWithKey[] | FilterInputNoKey;
};

type FoldableFilterProps = {
  title: string;
};

type FilterInputProps = {
  input: FilterInputWithKey | FilterInputNoKey;
  filterKey: string;
  onFilterChange?: (key: string, value: any) => void;
  initialValue?: any;
};

type SearchFiltersSidebarProps = {
  className?: string;
  filters: SearchFilter[];
  onApplyFilters?: (filters: { [key: string]: any }) => void;
  onFilterChange?: (key: string, value: any) => void;
};

type SearchFiltersState = {
  [key: string]: any;
};

const DEFAULT_STATE: SearchFiltersState = {};

const SearchFilterContext = createContext<[SearchFiltersState, React.Dispatch<SearchFiltersState>]>([
  DEFAULT_STATE,
  () => {},
]);

const reducer = (state: SearchFiltersState, payload: SearchFiltersState) => {
  return merge({}, state, payload);
};

const FoldableFilter: React.FC<FoldableFilterProps> = ({ children, title }) => {
  const [isRevealed, setIsRevealed] = useState(true);

  return (
    <div className={styles.foldableContainer}>
      <h4 onClick={() => setIsRevealed(!isRevealed)}>
        {isRevealed ? <CaretDown /> : <CaretRight />} {title}
      </h4>
      {isRevealed && <div className={styles.filterChildren}>{children}</div>}
    </div>
  );
};

const FilterInput = ({ input, filterKey, onFilterChange, initialValue }: FilterInputProps) => {
  const { type, label, placeholder } = input;

  const [state, dispatcher] = useContext(SearchFilterContext);

  const handleOnChange = (value: any) => {
    if ((input as FilterInputWithKey).key) {
      const key = (input as FilterInputWithKey).key;

      dispatcher({ [filterKey]: { [key]: value } });

      return onFilterChange && onFilterChange(`${filterKey}.${(input as FilterInputWithKey).key}`, value);
    }

    dispatcher({ [filterKey]: value });

    return onFilterChange && onFilterChange(filterKey, value);
  };

  const inputValue = useMemo(() => {
    if ((input as FilterInputWithKey).key) {
      return state[filterKey]?.[(input as FilterInputWithKey).key];
    }

    return state[filterKey];
  }, [state]);

  useEffect(() => {
    if (initialValue) {
      handleOnChange(initialValue);
    }
  }, [initialValue]);

  switch (type) {
    case 'date':
    case 'text':
      return (
        <Input
          type={type}
          label={label}
          value={inputValue ?? ''}
          placeholder={placeholder}
          onChange={handleOnChange}
          variant="secondary"
        />
      );
    case 'checkbox':
      return (
        <Checkbox label={label} checked={inputValue ?? false} onChange={(e) => handleOnChange(e.target.checked)} />
      );
  }

  return null;
};

export const SearchFiltersSidebar = ({
  className,
  filters,
  onApplyFilters,
  onFilterChange,
  ...rest
}: SearchFiltersSidebarProps) => {
  const [state, dispatcher] = useReducer(reducer, DEFAULT_STATE);

  const handleApplyFilters = () => onApplyFilters && onApplyFilters(state);

  return (
    <SearchFilterContext.Provider value={[state, dispatcher]}>
      <div className={cn([styles.SearchFiltersSidebar, className])}>
        {filters.map((filter, i) => (
          <FoldableFilter title={filter.title} key={i}>
            {filter.inputs instanceof Array ? (
              filter.inputs.map((input) => (
                <FilterInput
                  key={input.key}
                  input={input}
                  filterKey={filter.key}
                  onFilterChange={onFilterChange}
                  initialValue={input.initialValue}
                />
              ))
            ) : (
              <FilterInput
                input={filter.inputs}
                filterKey={filter.key}
                onFilterChange={onFilterChange}
                initialValue={filter.inputs.initialValue}
              />
            )}
          </FoldableFilter>
        ))}

        <Button size="small" onClick={handleApplyFilters} className={styles.applyFiltersButton}>
          Apply Filters
        </Button>
      </div>
    </SearchFilterContext.Provider>
  );
};

export default SearchFiltersSidebar;
