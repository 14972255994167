import * as Sentry from '@sentry/react';
import { User } from '@sentry/react';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { CustomQueryOptions, FetchError } from 'src/types/TApiQueries';
import { getUser, getUsers } from '../functions/userManagementApi';
import { queryFnFetchErrorWrapper, throwFetchError } from '../utils/errorHandlers';
import { mergeQueryOptions } from '../utils/helpers';
import { queryNames } from '../utils/queryNames';

export function useUserManagementQuery(
  searchFilters: any,
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<any[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions<User[]>(options, queryOptions);
  const accessToken = useAccessToken();

  const fetchFn = async (token: string) => {
    const result = (await getUsers(searchFilters, token)) as any;
    return result.data;
  };

  const queryFn = useMemo(() => queryFnFetchErrorWrapper(() => fetchFn(accessToken)), [searchFilters, accessToken]);

  const query = useQuery<any, FetchError>([queryNames.userManagement, searchFilters], queryFn, actualQueryOptions);

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching settings', Sentry.Severity.Error);
    } else {
      throwFetchError(query.error);
    }
  }
  return query;
}

export function useUserQuery(
  userId: number,
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<any[], FetchError>,
) {
  const actualQueryOptions = mergeQueryOptions<User[]>(options, queryOptions);
  const accessToken = useAccessToken();

  const fetchFn = useMemo(() => queryFnFetchErrorWrapper(() => getUser(userId, accessToken)), [accessToken, userId]);

  const query = useQuery<any, FetchError>([queryNames.user, userId], fetchFn, actualQueryOptions);

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching settings', Sentry.Severity.Error);
    } else {
      throwFetchError(query.error);
    }
  }
  return query;
}
