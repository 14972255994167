import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useEffect, useMemo } from 'react';
import { useCollectionQuery } from 'src/api/queries/collectionsQueries';
import {
  BatchActions,
  ListingCard,
  ListingCardShimmer,
  ListingRow,
  PageTitle,
  Shimmer,
  LayoutViewSwitcher,
  LayoutView,
} from 'src/components';
import { useAccessToken } from 'src/hooks/useAccessToken';
import useLayoutSwitcher from 'src/hooks/useLayoutSwitcher';
import { deletedAssetsAtom } from 'src/shared/deletedAssets';
import { isRefetchingAtom, refreshAtom } from 'src/shared/refresh';
import { selectedAssetsAtom } from 'src/shared/selectedAssets';
import { getThumbnailUrl } from 'src/utils/previews';
import styles from './styles.module.scss';

const Basket = () => {
  const { isLayoutList, isLayoutGrid } = useLayoutSwitcher();

  const refreshValue = useAtomValue(refreshAtom);
  const setIsRefetching = useUpdateAtom(isRefetchingAtom);
  const [selectedAssets, updateSelectedAssets] = useAtom(selectedAssetsAtom);
  const [deletedAssets, updateDeletedAssets] = useAtom(deletedAssetsAtom);

  const { isLoading, data: fetchedData, refetch, isRefetching } = useCollectionQuery();
  const accessToken = useAccessToken();

  useEffect(() => {
    updateDeletedAssets([]);
  }, []);

  const basket = useMemo(() => {
    if (!fetchedData) return fetchedData;

    const assets = fetchedData?.data?.assets.filter((asset: string) => !deletedAssets.includes(asset));

    return { data: { ...fetchedData?.data, assets } };
  }, [fetchedData, deletedAssets]);

  const handleOnAssetSelectChange = (assetId: string) => {
    const newSelectedAssets = selectedAssets.includes(assetId)
      ? selectedAssets.filter((id) => id !== assetId)
      : [...selectedAssets, assetId];
    updateSelectedAssets(newSelectedAssets);
  };

  const handleSelectAllAssets = (selected: boolean) => {
    const newSelectedAssets = selected ? basket.data?.assets : [];
    updateSelectedAssets(newSelectedAssets);
  };

  useEffect(() => {
    if (isRefetching) return;
    refetch();
  }, [refreshValue]);

  useEffect(() => {
    setIsRefetching(isRefetching);
  }, [isRefetching]);

  useEffect(() => {
    return () => {
      if (selectedAssets.length > 0) {
        updateSelectedAssets([]);
      }
    };
  }, []);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageContent}>
        <PageTitle title="Basket" counter={basket?.data?.assets.length} />
        <div className={styles.actions}>
          <BatchActions onSelectAll={handleSelectAllAssets} showSelectAll={basket?.data?.assets.length > 0} />

          <LayoutViewSwitcher />
        </div>
        <LayoutView
          data={basket?.data?.assets}
          isLoading={isLoading}
          emptyDataMessage="No assets in basket"
          classNames={{
            cardView: 'listingGridWrapper',
          }}
          cardView={(assetId: string) => (
            <ListingCard
              key={assetId}
              id={assetId}
              siblings={basket.data?.result}
              imageUrl={getThumbnailUrl({ width: 300, height: 300, id: assetId, jwt: accessToken })}
              name={basket.data?.extended_result[assetId]?.name}
              size={basket.data?.extended_result[assetId]?.size}
              contentType={basket.data?.extended_result[assetId]?.content_type}
              trashed={basket.data?.extended_result[assetId]?.trashed}
              status={basket?.data?.extended_result[assetId]?.status}
              onSelectChange={() => handleOnAssetSelectChange(assetId)}
              isSelected={selectedAssets.includes(assetId)}
              error={basket?.data?.extended_result[assetId]?.errors}
            />
          )}
          listView={(assetId: string) => (
            <ListingRow
              key={assetId}
              id={assetId}
              siblings={basket.data?.result}
              imageUrl={getThumbnailUrl({ width: 100, height: 100, id: assetId, jwt: accessToken })}
              name={basket.data?.extended_result[assetId]?.name}
              size={basket.data?.extended_result[assetId]?.size}
              uploadTime={basket.data?.extended_result[assetId]?.upload_time}
              contentType={basket.data?.extended_result[assetId]?.content_type}
              trashed={basket.data?.extended_result[assetId]?.trashed}
              status={basket?.data?.extended_result[assetId]?.status}
              onSelectChange={() => handleOnAssetSelectChange(assetId)}
              isSelected={selectedAssets.includes(assetId)}
              error={basket?.data?.extended_result[assetId]?.errors}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Basket;
