import React, { useEffect, useReducer, useState, useMemo } from 'react';
import { getApiStats, getCdnStats, getTranscodeStats, getAddonStats } from 'src/api/functions/statsApi';
import { useAccessToken } from 'src/hooks/useAccessToken';
import {
  ApiStatsUsageTypes,
  CdnStatsUsageTypes,
  TranscodeStatsUsageTypes,
  userTypes,
  AddonStatsUsageTypes,
} from 'src/types/TCheckUsage';
import styles from './CheckUsage.module.scss';
import { Pager, PageTitle } from 'src/components';
import CheckUsageDropDown from './DropDown/DropDown';
import { getUsers } from 'src/api/functions/userManagementApi';
import { ArrowDownCircle, ArrowUpCircle } from 'src/common/icons';
import { useAtom } from 'jotai';
import { addonsSettingsAtom } from 'src/shared/settings/addons';
type ErrorProps = {
  message: string;
};

const LoadingStatus = () => {
  return (
    <div className={styles.loadingHolder}>
      <h3 className={styles.loading} style={{ fontSize: 20 }}>
        Fetching Stats
      </h3>
    </div>
  );
};

const ErrorStatus = ({ message }: ErrorProps) => {
  return (
    <div className={styles.loadingHolder}>
      <h3 style={{ fontSize: 20 }}>{message}</h3>
    </div>
  );
};
const initialFilters = {
  page: 1,
  limit_per_page: 30,
  email: '',
  first_name: '',
  last_name: '',
  enabled: false,
};

const filtersReducer = (state: any, payload: { [key: string]: any }) => ({ ...state, ...payload });

export const CheckUsageComponent: React.FC = () => {
  const accessToken = useAccessToken();

  const [apiStatData, setApiStatData] = useState<ApiStatsUsageTypes>();
  const [cdnStatsData, setCdnStatsData] = useState<CdnStatsUsageTypes>();
  const [transcodeStatData, setTranscodeStatsData] = useState<TranscodeStatsUsageTypes>();
  const [addonStatData, setAddonStatsData] = useState<AddonStatsUsageTypes>();
  const [userData, setUserData] = useState<userTypes>();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [activeTab, setActiveTab] = useState<'api' | 'addon' | 'transcode' | 'cdn'>('api');
  const [selectedCdnPeriod, setSelectedCdnPeriod] = useState(24);
  const [selectedTranscodePeriod, setSelectedTranscodePeriod] = useState(24);
  const [selectedAddonPeriod, setSelectedAddonPeriod] = useState(24);
  const [selectedPeriod, setSelectedPeriod] = useState(24);
  const [selectedUser, setSelectedUser] = useState('all');
  const [filters, dispatchFilter] = useReducer(filtersReducer, initialFilters);
  const [sortConfig, setSortConfig] = useState({ key: 'subtype', direction: 'asc' });
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [addonsSettings, updateAddonsSettings] = useAtom(addonsSettingsAtom);
  const fetchAPIData = async () => {
    try {
      setIsLoading(true);
      const response = await getApiStats(accessToken, selectedPeriod, selectedUserId);
      if (response) {
        setApiStatData(response as ApiStatsUsageTypes);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCDNData = async () => {
    try {
      setIsLoading(true);
      const response = await getCdnStats(accessToken, selectedCdnPeriod);
      if (response) {
        setCdnStatsData(response as CdnStatsUsageTypes);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchTranscodeData = async () => {
    try {
      setIsLoading(true);
      const response = await getTranscodeStats(accessToken, selectedTranscodePeriod, selectedUserId);
      if (response) {
        setTranscodeStatsData(response as TranscodeStatsUsageTypes);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchAddonData = async () => {
    try {
      setIsLoading(true);
      const response = await getAddonStats(accessToken, selectedAddonPeriod, selectedUserId);
      if (response) {
        setAddonStatsData(response as AddonStatsUsageTypes);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const response = await getUsers(filters, accessToken);
      setUserData(response.data as userTypes);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    fetchAPIData();
  }, [selectedUser, selectedPeriod]);

  useEffect(() => {
    fetchCDNData();
  }, [selectedCdnPeriod]);
  useEffect(() => {
    fetchTranscodeData();
  }, [selectedUser, selectedTranscodePeriod]);
  useEffect(() => {
    fetchAddonData();
  }, [selectedUser, selectedAddonPeriod]);

  useEffect(() => {
    if (apiStatData && apiStatData.data) {
      let count = 0;
      Object.keys(apiStatData.data).forEach((el) => {
        Object.values(apiStatData.data[el]).forEach((item) => {
          count += parseInt(item);
        });
      });
      setTotalCount(count);
    }
  }, [apiStatData]);
  function convertSeconds(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${seconds}s (${hours}h ${minutes}m ${remainingSeconds}s)`;
  }

  function formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const tableHeaderStyle = {
    padding: '12px 16px',
    textAlign: 'left' as const,
    fontWeight: 'bold',
    borderBottom: '2px solid #ddd',
  };

  const tableCellStyle = {
    padding: '12px 16px',
    borderBottom: '1px solid #ddd',
    textAlign: 'left' as const,
    fontSize: '16px',
  };
  const sortData = (data: any, key: any) => {
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const requestSort = (key: any) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const addonsDiscription = useMemo(() => {
    if (!addonsSettings) return [];
    return Object.entries(addonsSettings).map(([key, value]: [string, any]) => ({ key, ...value }));
  }, [addonsSettings]);

  function addonTablePopulate(addonName: string): number {
    for (const key in addonStatData?.data) {
      const statsPerUser: any = addonStatData?.data[key];
      for (const a in statsPerUser) {
        if (a === addonName) {
          return statsPerUser[a];
        }
      }
    }
    return 0;
  }
  const renderContent = () => {
    if (isLoading || !userData) {
      return <LoadingStatus />;
    }

    if (activeTab === 'api' && apiStatData) {
      let flattenedData = [];
      const processData = (data: any) => {
        if (Array.isArray(data)) {
          return data.filter((item) => item.subtype && item.subtype.startsWith('/'));
        } else if (typeof data === 'object') {
          return Object.entries(data)
            .map(([subtype, count]) => ({ subtype, count }))
            .filter((item) => item.subtype.startsWith('/'));
        }
        return [];
      };

      if (selectedUser === 'all') {
        flattenedData = Object.entries(apiStatData.data).flatMap(([userId, userData]) => {
          const processedData = processData(userData);
          return processedData.map((item) => ({ ...item, userId }));
        });
      } else {
        const userData = apiStatData.data[selectedUser];
        if (userData) {
          flattenedData = processData(userData).map((item) => ({ ...item, userId: selectedUser }));
        }
      }

      if (flattenedData.length > 0) {
        flattenedData = sortData(flattenedData, sortConfig.key);
      }

      return (
        <div className={styles.container}>
          <div className={styles.dropdownContainer}>
            <CheckUsageDropDown
              options={[
                { key: 'all', value: <div style={{ display: 'flex', alignItems: 'center' }}>All</div> },

                ...(userData?.users
                  ? userData.users.map((user: any) => ({
                      key: user.id,
                      value: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ fontSize: '16px', marginRight: '10px' }}>{user.first_name}</span>
                          <span style={{ fontSize: '14px', color: '#666' }}>{user.email}</span>
                        </div>
                      ),
                    }))
                  : []),
              ]}
              selectedKeys={[selectedUser]}
              onClick={(key) => {
                setSelectedUser(key);
                setSelectedUserId(key === 'all' ? null : key);
              }}
              placeholder="All"
            >
              {selectedUser === 'all'
                ? 'All'
                : userData?.users.find((user: any) => user.id === selectedUser)?.first_name}
            </CheckUsageDropDown>
            <CheckUsageDropDown
              options={[
                { key: 24, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 24 Hours</div> },
                { key: 168, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Week</div> },
                { key: 730, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Month</div> },
                { key: 8760, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Year</div> },
              ]}
              selectedKeys={[selectedPeriod]}
              onClick={(key) => setSelectedPeriod(key as any)}
              placeholder="Past 24 Hours"
            >
              {selectedPeriod === 24
                ? 'Past 24 Hours'
                : selectedPeriod === 168
                ? 'Past 1 Week'
                : selectedPeriod === 730
                ? 'Past 1 Month'
                : 'Past 1 Year'}
            </CheckUsageDropDown>
          </div>

          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.tableRow}>
                  <th style={tableHeaderStyle}>EndPoint</th>
                  <th style={tableHeaderStyle}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      Count <span>({totalCount})</span>
                      <p onClick={() => requestSort('count')} style={{ paddingTop: '1.3px' }}>
                        {sortConfig.key === 'count' ? (
                          sortConfig.direction === 'asc' ? (
                            <ArrowDownCircle />
                          ) : (
                            <ArrowUpCircle />
                          )
                        ) : (
                          <ArrowDownCircle />
                        )}
                      </p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {flattenedData.length > 0 ? (
                  flattenedData.map((item, index) => (
                    <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff' }}>
                      <td style={tableCellStyle}>{item.subtype}</td>
                      <td style={tableCellStyle}>{item.count}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2} style={tableCellStyle}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    if (activeTab === 'addon' && addonStatData) {
      return (
        <div className={styles.container}>
          <div className={styles.dropdownContainer}>
            <CheckUsageDropDown
              options={[
                { key: 'all', value: <div style={{ display: 'flex', alignItems: 'center' }}>All</div> },

                ...(userData?.users
                  ? userData.users.map((user: any) => ({
                      key: user.id,
                      value: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ fontSize: '16px', marginRight: '10px' }}>{user.first_name}</span>
                          <span style={{ fontSize: '14px', color: '#666' }}>{user.email}</span>
                        </div>
                      ),
                    }))
                  : []),
              ]}
              selectedKeys={[selectedUser]}
              onClick={(key) => {
                setSelectedUser(key);
                setSelectedUserId(key === 'all' ? null : key);
              }}
              placeholder="All"
            >
              {selectedUser === 'all'
                ? 'All'
                : userData?.users.find((user: any) => user.id === selectedUser)?.first_name}
            </CheckUsageDropDown>
            <CheckUsageDropDown
              options={[
                { key: 24, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 24 Hours</div> },
                { key: 168, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Week</div> },
                { key: 730, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Month</div> },
                { key: 8760, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Year</div> },
              ]}
              selectedKeys={[selectedAddonPeriod]}
              onClick={(key) => setSelectedAddonPeriod(key as any)}
              placeholder="Past 24 Hours"
            >
              {selectedAddonPeriod === 24
                ? 'Past 24 Hours'
                : selectedAddonPeriod === 168
                ? 'Past 1 Week'
                : selectedAddonPeriod === 730
                ? 'Past 1 Month'
                : 'Past 1 Year'}
            </CheckUsageDropDown>
          </div>

          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.tableRow}>
                  <th style={tableHeaderStyle}>Addons</th>
                  <th style={tableHeaderStyle}>Usage</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(addonsDiscription).map((value) => (
                  <React.Fragment>
                    <tr className={styles.tableRow}>
                      <td style={tableCellStyle}>{value['title']}</td>
                      <td style={tableCellStyle}>{addonTablePopulate(value['key'])}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    if (activeTab === 'transcode' && transcodeStatData) {
      return (
        <div className={styles.container}>
          <div className={styles.dropdownContainer}>
            <CheckUsageDropDown
              options={[
                { key: 'all', value: <div style={{ display: 'flex', alignItems: 'center' }}>All</div> },

                ...(userData?.users
                  ? userData.users.map((user: any) => ({
                      key: user.id,
                      value: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ fontSize: '16px', marginRight: '10px' }}>{user.first_name}</span>
                          <span style={{ fontSize: '14px', color: '#666' }}>{user.email}</span>
                        </div>
                      ),
                    }))
                  : []),
              ]}
              selectedKeys={[selectedUser]}
              onClick={(key) => {
                setSelectedUser(key);
                setSelectedUserId(key === 'all' ? null : key);
              }}
              placeholder="All"
            >
              {selectedUser === 'all'
                ? 'All'
                : userData?.users.find((user: any) => user.id === selectedUser)?.first_name}
            </CheckUsageDropDown>
            <CheckUsageDropDown
              options={[
                { key: 24, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 24 Hours</div> },
                { key: 168, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Week</div> },
                { key: 730, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Month</div> },
                { key: 8760, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Year</div> },
              ]}
              selectedKeys={[selectedTranscodePeriod]}
              onClick={(key) => setSelectedTranscodePeriod(key as any)}
              placeholder="Past 24 Hours"
            >
              {selectedTranscodePeriod === 24
                ? 'Past 24 Hours'
                : selectedTranscodePeriod === 168
                ? 'Past 1 Week'
                : selectedTranscodePeriod === 730
                ? 'Past 1 Month'
                : 'Past 1 Year'}
            </CheckUsageDropDown>
          </div>

          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.tableRow}>
                  <th style={tableHeaderStyle}>Transcode</th>
                  <th style={tableHeaderStyle}>Usage</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(transcodeStatData?.data).some((value) =>
                  Object.entries(value).some(([transcode, time]) => transcode),
                ) ? (
                  Object.values(transcodeStatData?.data).map((value) => (
                    <React.Fragment>
                      {Object.entries(value).map(([transcode, time]) =>
                        transcode ? (
                          <tr className={styles.tableRow} key={transcode}>
                            <td style={tableCellStyle}>
                              {transcode.replace('VIDEO_TRANSCODE_SECONDS', 'Video Transcodes')}
                            </td>
                            <td style={tableCellStyle}>{convertSeconds(time)}</td>
                          </tr>
                        ) : null,
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <tr className={styles.tableRow}>
                    <td style={tableCellStyle}>Video Transcodes</td>
                    <td style={tableCellStyle}>{convertSeconds(0)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    if (activeTab === 'cdn' && cdnStatsData) {
      return (
        <div className={styles.container}>
          <div className={styles.dropdownContainer}>
            <CheckUsageDropDown
              options={[
                { key: 24, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 24 Hours</div> },
                { key: 168, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Week</div> },
                { key: 730, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Month</div> },
                { key: 8760, value: <div style={{ display: 'flex', alignItems: 'center' }}>Past 1 Year</div> },
              ]}
              selectedKeys={[selectedCdnPeriod]}
              onClick={(key) => setSelectedCdnPeriod(key as any)}
              placeholder="Past 24 Hours"
            >
              {selectedCdnPeriod === 24
                ? 'Past 24 Hours'
                : selectedCdnPeriod === 168
                ? 'Past 1 Week'
                : selectedCdnPeriod === 730
                ? 'Past 1 Month'
                : 'Past 1 Year'}
            </CheckUsageDropDown>
          </div>

          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.tableRow}>
                  <th style={tableHeaderStyle}>CDN</th>
                  <th style={tableHeaderStyle}>Usage</th>
                </tr>
              </thead>
              <tbody>
                <tr className={styles.tableRow}>
                  <td style={tableCellStyle}>{cdnStatsData?.cdn}</td>
                  <td style={tableCellStyle}>{formatBytes(cdnStatsData?.data?.sum)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return <ErrorStatus message="No data available" />;
  };

  const tabStyle = (tabName: 'api' | 'addon' | 'transcode' | 'cdn') => ({
    className: `${styles.tabButton} ${activeTab === tabName ? styles.active : ''}`,
    onClick: () => setActiveTab(tabName),
  });

  return (
    <div className={styles.mainContainer}>
      <div className={styles.pageHeader}>
        <PageTitle title="Usage Stats" className={styles.pageTitle} />
      </div>
      <div className={styles.tabContainer}>
        <button {...tabStyle('api')}>API Stats</button>
        <button {...tabStyle('addon')}>Addon Stats</button>
        <button {...tabStyle('transcode')}>Video Transcode Stats</button>
        <button {...tabStyle('cdn')}>CDN Stats</button>
      </div>
      {renderContent()}
    </div>
  );
};

export default CheckUsageComponent;
