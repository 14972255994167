import { humanFileSize, humanDuration } from 'src/utils/transform';

export const formatCoreData = (key: string, value: string | number) => {
  switch (key) {
    case 'size':
      return humanFileSize(value as number);
    case 'width':
    case 'height':
      return value !== 0 ? `${value} px` : 'N/A';
    case 'duration':
      return value !== 0 ? humanDuration(value as number) : 'N/A';
    default:
      return value !== 0 ? value : 'N/A';
  }
};
