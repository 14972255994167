import { APISchemaUpdate, FetchResult } from 'src/types/TApiQueries';
import { config } from 'src/utils/config';
import { User } from 'src/types/TUser';
import { getStandardHeaders, getUploadHeaders, performFetchWithLogging } from '../utils/helpers';

export async function getSettings(accessToken: string): Promise<FetchResult<User>> {
  const url = `${config.hostUrl}/api/v1/user/settings`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

type UpdateSettingsSchema = {
  key: string;
  value: {
    [key: string]: any;
  };
};

export async function updateSettings(data: UpdateSettingsSchema, accessToken: string): Promise<FetchResult<any>> {
  const payload = JSON.stringify(data);

  return performFetchWithLogging(`${config.hostUrl}/api/v1/user/settings/update`, {
    method: 'PATCH',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

export async function updateAddonSettingsApi(data: any, addon: string, accessToken: string): Promise<FetchResult<any>> {
  const payload = JSON.stringify(data);

  return performFetchWithLogging(`${config.hostUrl}/api/v1/user/settings/addons/${addon}`, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}


export async function saveSettingsAsTemplate(data: any, accessToken: string): Promise<FetchResult<any>> {
  return new Promise((resolve) => setTimeout((): void => resolve(data), 3000));
}

export async function updateWatermark(file: any, accessToken: string): Promise<FetchResult<any>> {
  const form = new FormData();
  form.append('name', file);

  return performFetchWithLogging(`${config.hostUrl}/api/v1/user/settings/watermark`, {
    method: 'POST',
    headers: getUploadHeaders(accessToken),
    credentials: 'include',
    body: form,
  });
}
