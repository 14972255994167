import { Logo, Paper } from 'src/components';

import styles from '../styles.module.scss';

const BaseLayout: React.FC = ({ children }) => {
  return (
    <main className={styles.onboarding}>
      <header>
        <Logo />
      </header>

      <section className={styles.container}>
        <Paper className={styles.content}>{children}</Paper>
      </section>
    </main>
  );
};

export default BaseLayout;
