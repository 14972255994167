import cn from 'classnames';
import * as React from 'react';
import { CheckboxChecked, CheckboxEmpty } from 'src/common/icons';
import { ForwardRefExoticComponent } from 'src/types/TForms';
import styles from './Checkbox.module.scss';

type CheckboxProps = {
  label?: string | number | React.ReactNode;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

type PluralProps = ForwardRefExoticComponent<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & CheckboxProps
>;

export const Checkbox: PluralProps = React.forwardRef(
  ({ label, checked, className, disabled, required, name, onChange = () => null, ...rest }, ref) => (
    <label
      className={cn([
        styles.Checkbox,
        {
          [styles.disabled]: disabled,
          [styles.checked]: checked,
        },
        className,
      ])}
    >
      <span className={styles.icon}>
        {checked ? <CheckboxChecked /> : <CheckboxEmpty />}
        <input
          id={name}
          type="checkbox"
          name={name}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          ref={ref}
          {...rest}
        />
      </span>
      {label && <span className={styles.label}>{label}</span>}
      {required && '*'}
    </label>
  ),
);

export default Checkbox;
