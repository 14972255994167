import { Checkbox, Footer, Input, Loader, Paper, Table } from 'src/components';
import { useOnboardingContext } from '../context';
import StepBar from '../components/StepBar';

import styles from '../styles.module.scss';
import { useSettingsQuery } from 'src/api/queries/userQueries';
import { useAtom } from 'jotai';
import { storageSettingsAtom } from 'src/shared/settings/storage';
import { ACTION_TYPES } from 'src/shared/settings/base';
import { Checks } from 'src/common/icons';
import { updatedSettingsAtom } from 'src/shared/settings';
import { useAtomValue } from 'jotai/utils';
import { formatMessage } from '@formatjs/intl';
import messages from 'src/admin-pages/ImageSettings/messages';
import { useSettingsMutation } from 'src/api/mutations/userMutations';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';

const Storage = () => {
  const { goToNextStep, goToPreviousStep } = useOnboardingContext();
  const updatedSettings = useAtomValue(updatedSettingsAtom);

  const hasChanges = !!updatedSettings?.storage;

  const { isLoading } = useSettingsQuery();

  const [storageSettings, updateStorageSettings] = useAtom(storageSettingsAtom);

  const { showAlert } = useAlerts();
  const { getErrorMessage } = useErrorMessages();

  const settingsMutation = useSettingsMutation({
    onError: (error) => {
      showAlert(getErrorMessage(error.message));
    },
    onSuccess: () => {
      goToNextStep();
    },
  });

  const handleChange = (name: string, value: string) => {
    updateStorageSettings({ type: ACTION_TYPES.UPDATE_STATE, payload: { [name]: value } });
  };

  const handleGoToNextStep = () => {
    if (!hasChanges) return goToNextStep();

    settingsMutation.mutateAsync(null);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main className={styles.step}>
      <StepBar
        onGoToNextStep={handleGoToNextStep}
        onGoToPreviousStep={goToPreviousStep}
        loadingNextStep={settingsMutation.isLoading}
        hasUnsavedChanges={hasChanges}
      />
      <section className={styles.container}>
        <p className={styles.stepInfo}>
          Update to use your own Cloud Storage. Skip this step to use default FileSpin Storage.
        </p>
        <Paper>
          <Table headers={['Files', 's3 bucket', 'Storage path prefix', 'Verified']}>
            <tr>
              <td>Original Files</td>
              <td>
                <Input
                  variant="secondary"
                  value={storageSettings.originals_root || ''}
                  onChange={(value: string) => handleChange('originals_root', value)}
                />
              </td>
              <td>
                <Input
                  variant="secondary"
                  value={storageSettings.originals_root_folder || ''}
                  onChange={(value: string) => handleChange('originals_root_folder', value)}
                />
              </td>
              <td>
                {storageSettings.originals_root_ok ? <Checks color="var(--color-prime-400)" fontSize={24} /> : null}
              </td>
            </tr>
            <tr>
              <td>Image conversions</td>
              <td>
                <Input
                  variant="secondary"
                  value={storageSettings.derivatives_root || ''}
                  onChange={(value: string) => handleChange('derivatives_root', value)}
                />
              </td>
              <td>
                <Input
                  variant="secondary"
                  value={storageSettings.derivatives_root_folder || ''}
                  onChange={(value: string) => handleChange('derivatives_root_folder', value)}
                />
              </td>
              <td>
                {storageSettings.derivatives_root_ok ? <Checks color="var(--color-prime-400)" fontSize={24} /> : null}
              </td>
            </tr>
            <tr>
              <td>Video transcodes</td>

              <td>
                <Input
                  variant="secondary"
                  value={storageSettings.transcodes_root || ''}
                  onChange={(value: string) => handleChange('transcodes_root', value)}
                />
              </td>
              <td>
                <Input
                  variant="secondary"
                  value={storageSettings.transcodes_root_folder || ''}
                  onChange={(value: string) => handleChange('transcodes_root_folder', value)}
                />
              </td>
              <td>
                {storageSettings.transcodes_root_ok ? <Checks color="var(--color-prime-400)" fontSize={24} /> : null}
              </td>
            </tr>
          </Table>
        </Paper>
      </section>
      <Footer />
    </main>
  );
};

export default Storage;
