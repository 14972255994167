import cn from 'classnames';
import styles from './BatchActions.module.scss';
import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { getCollectionDownloadUrl } from 'src/api/functions/collectionsApi';
import { useCollectionMutation } from 'src/api/mutations/collectionMutations';
import { useCollectionQuery } from 'src/api/queries/collectionsQueries';
import { queryNames } from 'src/api/utils/queryNames';
import { useAlerts } from 'src/common/AlertManager';
import { DownloadSimple, Trash, ArchiveBox, Stack, CaretDown, Copy } from 'src/common/icons';
import { useAccessToken } from 'src/hooks/useAccessToken';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import useModal from 'src/hooks/useModal';
import { deletedAssetsAtom } from 'src/shared/deletedAssets';
import { refreshAtom } from 'src/shared/refresh';
import { selectedAssetsAtom } from 'src/shared/selectedAssets';
import { CollectionResponse } from 'src/types/TCollection';
import Button from '../Button';
import Dropdown from '../Dropdown';
import { SelectInputOption } from '../Dropdown/Dropdown';
import messages from './messages';
import ConfirmDuplicateCollectionModal from '../ConfirmDuplicateCollectionModal';

const BasketActions = () => {
  const [selectedAssets, updateSelectedAssets] = useAtom(selectedAssetsAtom);

  const { showAlert, showSuccess } = useAlerts();
  const { getErrorMessage } = useErrorMessages();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const refreshUpdate = useUpdateAtom(refreshAtom);
  const [, updateDeletedAssets] = useAtom(deletedAssetsAtom);
  const [isPreparingDownload, setIsPreparingDownload] = useState(false);
  const accessToken = useAccessToken();

  const handleRefresh = () => {
    updateDeletedAssets([]);
    refreshUpdate((c) => c + 1);
  };

  const handleGoToBasket = () => history.push('/basket');

  const {
    data: response,
    error,
    isLoading,
  } = useCollectionQuery(undefined, {
    staleTime: 1000 * 60 * 5,
  });

  const { showModal } = useModal();

  const basketMutation = useCollectionMutation(Number(response?.data?.id), {
    onError: (error, newData, context: any) => {
      queryClient.setQueryData([queryNames.collection, null], context.previousData);

      showAlert(getErrorMessage(error.message));
    },
    onMutate: (data) => {
      /*showInfo(`Requesting update...`);*/
      const previousData = queryClient.getQueryData<CollectionResponse>([queryNames.collection, null]);
      queryClient.setQueryData<CollectionResponse | undefined>(
        [queryNames.collection, null],
        (old: CollectionResponse | undefined) => {
          if (typeof old !== 'undefined') {
            old.data.assets = (old?.data?.assets ?? [])
              .filter((id) => !(data?.deletions ?? []).includes(id))
              .concat(data?.additions ?? []);
          }
          return old;
        },
      );
      return { previousData };
    },
    onSuccess: () => {
      showSuccess('Request succeeded. Refreshing data...');
      handleRefresh();
    },
  });

  const handleBasketDownload = async () => {
    setIsPreparingDownload(true);
    const url: any = await getCollectionDownloadUrl(response?.data?.id, accessToken);

    if (url.status === 404) {
      showAlert(`Download not available at the moment. Please try again later.`);
      setIsPreparingDownload(false);
      return;
    }

    window.open(url);
    setIsPreparingDownload(false);
  };

  const handleRemoveSelectedAssetsToBasket = () =>
    !isLoading && basketMutation.mutateAsync({ deletions: selectedAssets });

  const handleEmptyBasket = async () => {
    if (isLoading) return;

    await basketMutation.mutateAsync({ deletions: response?.data?.assets });
    updateSelectedAssets([]);
  };

  const handleBasketMenuClick = (key: string) => {
    switch (key) {
      case 'download':
        handleBasketDownload();
        break;
      case 'remove':
        handleRemoveSelectedAssetsToBasket();
        break;
      case 'empty':
        handleEmptyBasket();
        break;
      case 'duplicate':
        showModal(<ConfirmDuplicateCollectionModal title="Save basket as Collection" sourceId={response?.data?.id} />);
        break;
    }
  };

  const basketDropdownOptions: Array<SelectInputOption> = [
    {
      key: 'download',
      value: 'Download',
      icon: <DownloadSimple />,
      optionDisabled: response?.data?.assets.length === 0 || isPreparingDownload,
    },
    {
      key: 'duplicate',
      value: 'Save basket as a Collection',
      icon: <Copy />,
      optionDisabled: response?.data?.assets.length === 0,
    },
    {
      key: 'empty',
      value: 'Empty',
      icon: <ArchiveBox />,
      optionDisabled: response?.data?.assets.length === 0,
    },
  ];

  if (location.pathname === '/basket') {
    basketDropdownOptions.push({
      key: 'remove',
      value: 'Remove Selected Assets',
      icon: <Trash />,
      optionDisabled: selectedAssets.length === 0,
    });
  }

  return (
    <div className={styles.batchAction}>
      <Button
        mode="tertiary"
        size="small"
        className={cn(styles.batchActionPrimaryButton, styles.basketButton)}
        onClick={handleGoToBasket}
        icon={<Stack />}
      >
        <div>
          <span>{response?.data?.assets?.length ?? '0'}</span>
          <span>
            <FormattedMessage {...messages.basket} />
          </span>
        </div>
      </Button>
      <Dropdown
        className={styles.batchActionDropdown}
        icon={<CaretDown />}
        title="Basket"
        titleLink="/basket"
        options={basketDropdownOptions}
        onClick={handleBasketMenuClick}
      />
    </div>
  );
};

export default BasketActions;
