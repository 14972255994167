import { useMemo } from 'react';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { FetchError, NotificationsResponse } from 'src/types/TApiQueries';
import { TNotification } from 'src/types/TNotification';
import { deleteNotifications } from '../functions/notificationsApi';
import { queryFnFetchErrorWrapper } from '../utils/errorHandlers';
import { queryNames } from '../utils/queryNames';

export const useDeleteNotificationsMutation = (mutationOptions?: UseMutationOptions<any, FetchError, any>) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  const mutationFn = useMemo(
    () => queryFnFetchErrorWrapper((data: number[]) => deleteNotifications(data, accessToken)),
    [accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, deletedNotifications, ...args) => {
      queryClient.setQueryData([queryNames.notification, 'all'], (currentData: any) => {
        const { notifications } = currentData;

        const newNotifications = notifications.filter(
          (notification: TNotification) => !deletedNotifications.includes(notification.id),
        );

        return {
          ...currentData,
          notifications: newNotifications,
        };
      });

      mutationOptions?.onSuccess?.(result, deletedNotifications, ...args);
    },
  });
};
