import * as React from 'react';
import cn from 'classnames';
import styles from './Notifications.module.scss';
import messages from './messages';
import { Bell, X } from 'src/common/icons';
import Dropdown from '../Dropdown';
import { TNotification } from 'src/types/TNotification';
import moment from 'moment';
import { useAllNotificationsQuery } from 'src/api/queries/notificationQueries';
import Loader from '../Loader';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { useHistory } from 'react-router-dom';
import { useDeleteNotificationsMutation } from 'src/api/mutations/notificationMutations';
import { config } from 'src/utils/config';
import Button from '../Button';

type NotificationsProps = {};

export const Notifications = (props: NotificationsProps) => {
  const history = useHistory();

  const deleteNotificationsMutation = useDeleteNotificationsMutation();

  const notificationsRef = React.useRef<HTMLDivElement>(null);
  const [notificationsExpanded, setNotificationsExpanded] = React.useState(false);

  const { isLoading, data, refetch, isRefetching } = useAllNotificationsQuery(undefined, {
    refetchIntervalInBackground: true,
    refetchInterval: notificationsExpanded ? false : config.notificationsRefetchInterval,
  });

  const hasNewNotifications = data?.notifications.length > 0;

  const readNotifications = data?.notifications?.filter(
    (notification: TNotification) => notification.disposal === 'ON_READ',
  );
  const ackNotifications = data?.notifications?.filter(
    (notification: TNotification) => notification.disposal === 'ON_ACK',
  );

  const handleOnClickNotification = (notification: TNotification) => {
    if (!notification.url) return;

    if (notification.url.startsWith('/')) {
      history.push(notification.url);
      setNotificationsExpanded(false);
      return;
    }

    return window.open(notification.url, '_blank');
  };

  const handleCloseNotification = () => {
    const { notifications } = data;

    const notificationsOnRead = (notifications as TNotification[])
      ?.filter((notification) => notification.disposal === 'ON_READ')
      .map((notification) => notification.id);

    if (notificationsOnRead.length === 0) return;

    deleteNotificationsMutation.mutateAsync(notificationsOnRead);
  };

  const handleClickNotificationsBell = () => {
    setNotificationsExpanded((prev) => !prev);
  };

  const handleDimissNotification = (notification: TNotification) => {
    deleteNotificationsMutation.mutateAsync([notification.id]);
  };

  const handleDismissAckNotifications = () =>
    deleteNotificationsMutation.mutateAsync(ackNotifications.map((notification: TNotification) => notification.id));

  useOutsideClick(notificationsRef, () => setNotificationsExpanded(false));

  React.useEffect(() => {
    if (notificationsExpanded && !data) {
      refetch();
    }

    if (!notificationsExpanded && !isRefetching && !isLoading) {
      handleCloseNotification();
    }
  }, [notificationsExpanded]);

  React.useEffect(() => {
    if (isRefetching) {
      console.log('refetching!');
    }
  }, [isRefetching]);

  return (
    <div ref={notificationsRef} className={styles.notifications}>
      <button onClick={handleClickNotificationsBell}>
        <Bell fontSize={22} />
        {hasNewNotifications && <span className={styles.newNotification} />}
      </button>
      {notificationsExpanded && (
        <div className={styles.notificationsDropdown}>
          {(isLoading || isRefetching) && (
            <div>
              <p>Loading Notifications...</p>
              <Loader />
            </div>
          )}
          {!isLoading && !isRefetching && (
            <>
              {data?.notifications?.length === 0 && <p className={styles.emptyNotifications}>No Notifications</p>}
              {ackNotifications.length > 0 && <p className={styles.separator}>MUST ACKNOWLEDGE</p>}
              {ackNotifications.length > 1 && (
                <div className={styles.clearNotifications} onClick={handleDismissAckNotifications}>
                  <button>Clear</button>
                </div>
              )}
              {ackNotifications?.map((notification: TNotification) => (
                <div key={notification.id} className={styles.notification}>
                  <p onClick={() => handleOnClickNotification(notification)}>
                    {notification.message}
                    <span>{moment(notification.timestamp).fromNow()}</span>
                  </p>

                  <Button
                    icon={<X />}
                    size="small"
                    mode="flat"
                    onClick={() => handleDimissNotification(notification)}
                  />
                </div>
              ))}
              {readNotifications.length > 0 && <p className={styles.separator}>INFO</p>}
              {readNotifications?.map((notification: TNotification) => (
                <div
                  key={notification.id}
                  className={styles.notification}
                  onClick={() => handleOnClickNotification(notification)}
                >
                  <p>
                    {notification.message}
                    <span>{moment(notification.timestamp).fromNow()}</span>
                  </p>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
