import * as React from 'react';

function SvgPencilSimple(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M9 20.25H4.5a.75.75 0 01-.75-.75v-4.19a.75.75 0 01.22-.53L15.22 3.53a.75.75 0 011.06 0l4.19 4.19a.75.75 0 010 1.06L9 20.25zM12.75 6L18 11.25"
        stroke="#2E53E3"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgPencilSimple;
