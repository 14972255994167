import * as React from 'react';
import cn from 'classnames';
import styles from './AdminSettingsSection.module.scss';
import messages from './messages';

type AdminSettingsSectionProps = {
  className?: string;
  title: string;
};

export const AdminSettingsSection: React.FC<AdminSettingsSectionProps> = ({ children, className, title, ...rest }) => {
  return (
    <div className={cn([styles.AdminSettingsSection, className])}>
      <h2 className={styles.title}>{title}</h2>
      {children}
    </div>
  );
};

export default AdminSettingsSection;
