import { PinturaEditor } from 'react-pintura';

import styles from './Modal.module.scss';
import { getEditorDefaults } from 'pintura';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AssetUpdatedDialog } from './components/AssetUpdateDialog';
import { useQueryClient } from 'react-query';
import { useAlerts } from 'src/common/AlertManager';
import { updateAssetConversionMedia, updateAssetMedia } from 'src/api/functions/assetsApi';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { queryNames } from 'src/api/utils/queryNames';
import { Button, Loader } from 'src/components';
import { WebVTTParser } from 'webvtt-parser';
import AssetModalContext from './context';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import { useIntl } from 'react-intl';
import useRoleManager from 'src/hooks/useRoleManager';

import messages from './messages';

export enum EDITION_MODE {
  SUBTITLE,
  CONVERSION,
  ORIGINAL,
}

export enum IMAGE_TYPE {
  ORIGINAL,
  CONVERSION,
}

export type ConversionData = {
  key: string;
  name: string;
  url: string;
};

export type OriginalData = {
  url: string;
  name: string;
};

export type SubtitleData = {
  key: string;
  url: string;
};

type ModalEditorProps = {
  assetId: string;
  type: EDITION_MODE;
  data: ConversionData | SubtitleData | OriginalData;
};

type ImageEditorProps = {
  assetId: string;
  imageType: IMAGE_TYPE;
  data: ConversionData | OriginalData;
};

type SubtitleEditorProps = {
  assetId: string;
  data: SubtitleData;
};

const vttParser = new WebVTTParser();

const SubtitleEditor = ({ assetId, data }: SubtitleEditorProps) => {
  const [subtitleText, setSubtitleText] = useState('');
  const [errors, setErrors] = useState<string[] | null>(null);
  const linesRef = useRef<HTMLTextAreaElement>(null);
  const [isSaving, setIsSaving] = useState(false);
  const accessToken = useAccessToken();
  const modalAPI = useContext(AssetModalContext);
  const [loading, setLoading] = useState(false);
  const { showSuccess, showAlert } = useAlerts();
  const { getErrorMessage } = useErrorMessages();
  const { formatMessage } = useIntl();
  const { isUser } = useRoleManager();

  const lines = useMemo(() => {
    const numberOfLines = subtitleText.split('\n').length;

    return Array.from({ length: numberOfLines }, (_, i) => i).join('\n');
  }, [subtitleText]);

  const isValid = useMemo(() => errors === null, [errors]);

  const fetchSubtitleFile = async () => {
    setLoading(true);
    const response = await fetch(data.url);
    const blob = await response.blob();

    const reader = new FileReader();
    await reader.readAsText(blob);

    reader.onloadend = () => {
      setSubtitleText(reader.result as string);
    };

    setLoading(false);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = event;
    setSubtitleText(value);

    const result = vttParser.parse(value);

    if (result.errors.length > 0) {
      setErrors(result.errors.map((e: any) => `${e.message} at line ${e.line}`));
    } else {
      setErrors(null);
    }
  };

  const handleScroll: React.UIEventHandler<HTMLTextAreaElement> = (e) => {
    if (!linesRef.current) return;

    linesRef.current.scrollTop = e.currentTarget.scrollTop;
  };

  const handleSave = async () => {
    setIsSaving(true);
    const vttBlob = new Blob([subtitleText], { type: 'text/vtt' });
    const vttFile = new File([vttBlob], `${data.key}.vtt`, { type: 'text/vtt' });

    try {
      const response = await updateAssetConversionMedia(
        assetId,
        (data as SubtitleData).key,
        {
          file: vttFile,
          filename: vttFile.name,
        },
        accessToken,
      );

      if (response.status === 500) {
        throw response;
      }

      modalAPI?.handleOnFinishEditing();
      showSuccess(formatMessage(messages.subtitleUpdated));
    } catch (error: any) {
      showAlert(getErrorMessage(error?.key));
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    fetchSubtitleFile();
  }, [data]);

  if (loading)
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );

  return (
    <div className={styles.subtitleEditor}>
      <div className={styles.subtitleEditorTextareaContainer}>
        <textarea ref={linesRef} className={styles.textareaLine} disabled value={lines} />
        <textarea
          className={styles.textareaEditor}
          value={subtitleText}
          onChange={handleOnChange}
          onScroll={handleScroll}
          disabled={isUser ? true : false}
        />
        {errors && (
          <div className={styles.editorErrors}>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {isUser ? (<></>) : (
        <div className={styles.subtitleEditorControls}>
          <Button mode="primary" size="small" disabled={!isValid} loading={isSaving} onClick={handleSave}>
            Save changes
          </Button>
        </div>
      )}
    </div>

  );
};

const ImageEditor = ({ assetId, imageType, data }: ImageEditorProps) => {
  const [showAssetUpdatedDialog, setShowAssetUpdatedDialog] = useState(false);
  const [editedImage, setEditatedImage] = useState<any>(null);
  const editorConfig = getEditorDefaults();
  const queryClient = useQueryClient();
  const { showSuccess } = useAlerts();
  const accessToken = useAccessToken();
  const modalAPI = useContext(AssetModalContext);
  const { formatMessage } = useIntl();

  editorConfig.utils = ['crop', 'filter', 'finetune', 'frame', 'redact', 'resize'];
  editorConfig.cropSelectPresetOptions = [
    [undefined, 'Custom'],
    [1, 'Square'],
    [4 / 3, 'Landscape'],
    [3 / 4, 'Portrait'],
  ];

  const updateConversion = () =>
    updateAssetConversionMedia(
      assetId,
      (data as ConversionData).key,
      { file: editedImage.dest, filename: data?.name ?? ' image' },
      accessToken,
    );

  const updateOriginalImage = () =>
    updateAssetMedia(assetId, { file: editedImage.dest, filename: data?.name ?? 'image' }, accessToken);

  const handleAssetUpdate = (image: any) => {
    /*if (imageType === IMAGE_TYPE.CONVERSION) {
      return updateConversion(image);
    }*/
    setEditatedImage(image);
    setShowAssetUpdatedDialog(true);
  };

  const handleCreateAssetConversion = async (conversionName: string) => {
    const response = await updateAssetConversionMedia(
      assetId,
      conversionName,
      { file: editedImage.dest, filename: data?.name ?? 'image' },
      accessToken,
    );

    queryClient.setQueryData([queryNames.asset, assetId], (data: any) => {
      data.conversions[conversionName] = response;
      return data;
    });

    setShowAssetUpdatedDialog(false);
    showSuccess(formatMessage(messages.savedConversion, { conversionName }));

    modalAPI?.handleOnFinishEditing();
  };

  const handleDownloadConversion = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(editedImage.dest);
    link.download = data?.name ?? 'image';
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.parentNode?.removeChild(link);
    }, 0);

    setShowAssetUpdatedDialog(false);
    modalAPI?.handleOnFinishEditing();
  };

  const handleCancelAssetUpdate = () => setShowAssetUpdatedDialog(false);

  const onReplaceOriginalAsset = async () => {
    if (imageType === IMAGE_TYPE.CONVERSION) {
      await updateConversion();
    } else {
      await updateOriginalImage();
    }

    showSuccess(
      formatMessage(messages.replaceOriginal, {
        type: imageType === IMAGE_TYPE.CONVERSION ? 'conversion' : 'asset',
      }),
    );
    setShowAssetUpdatedDialog(false);
    modalAPI?.handleOnFinishEditing();
  };

  const assetType = imageType === IMAGE_TYPE.CONVERSION ? 'conversion' : 'original';

  return (
    <>
      <PinturaEditor
        {...editorConfig}
        className={styles.editor}
        src={data.url}
        imageCropAspectRatio={undefined}
        enableButtonExport
        onProcess={handleAssetUpdate}
      />
      {showAssetUpdatedDialog && (
        <AssetUpdatedDialog
          onCreateConversion={handleCreateAssetConversion}
          onReplaceOriginal={onReplaceOriginalAsset}
          onDownloadConversion={handleDownloadConversion}
          onCancel={handleCancelAssetUpdate}
          assetType={assetType}
        />
      )}
    </>
  );
};

const ModalEditor = ({ assetId, type, data }: ModalEditorProps) => {
  switch (type) {
    case EDITION_MODE.SUBTITLE:
      return <SubtitleEditor assetId={assetId} data={data as SubtitleData} />;
    case EDITION_MODE.CONVERSION:
      return <ImageEditor assetId={assetId} imageType={IMAGE_TYPE.CONVERSION} data={data as ConversionData} />;
    case EDITION_MODE.ORIGINAL:
      return <ImageEditor assetId={assetId} imageType={IMAGE_TYPE.ORIGINAL} data={data as OriginalData} />;
  }
};

export default ModalEditor;
