const at = (array: any[], n: number) => {
  n = Math.trunc(n) || 0;

  if (n < 0) n += array.length;
  if (n < 0 || n >= array.length) return undefined;

  return array[n];
};

export default at;
