import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { getCollectionDownloadUrl } from 'src/api/functions/collectionsApi';
import { useCollectionMutation, useDuplicateCollectionMutation } from 'src/api/mutations/collectionMutations';
import { useCollectionQuery } from 'src/api/queries/collectionsQueries';
import { queryNames } from 'src/api/utils/queryNames';
import { useAlerts } from 'src/common/AlertManager';
import { BookmarkSimple, Copy, Faders, FileArrowDown, Pencil, ShareNetwork, Trash } from 'src/common/icons';
import SvgWrench from 'src/common/icons/Wrench';
import useRoleManager from 'src/hooks/useRoleManager';

import {
  BatchActions,
  Button,
  LayoutView,
  LayoutViewSwitcher,
  ListingCard,
  ListingRow,
  PageTitle,
} from 'src/components';
import { useAccessToken } from 'src/hooks/useAccessToken';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import useLayoutSwitcher from 'src/hooks/useLayoutSwitcher';
import useModal from 'src/hooks/useModal';
import { selectedAssetsAtom } from 'src/shared/selectedAssets';
import { CollectionResponse } from 'src/types/TCollection';
import { getThumbnailUrl } from 'src/utils/previews';
import EditCollectionModal from './components/EditCollectionModal';
import { useAuthUser } from 'react-auth-kit';
import moment from 'moment';

import styles from './styles.module.scss';
import ConfirmDuplicateCollectionModal from 'src/components/ConfirmDuplicateCollectionModal';

type CollectionParams = {
  id: string;
};

enum PAGE_ACTIONS {
  REMOVE_FROM_COLLECTION = 'removeFromCollection',
}

const Collection = () => {
  const auth = useAuthUser();
  const userEmail = auth() ? auth()!.userEmail : '';

  const { id } = useParams<CollectionParams>();

  const queryClient = useQueryClient();
  const { data: collection, isLoading } = useCollectionQuery(parseInt(id));
  const accessToken = useAccessToken();
  const [isPreparingDownload, setIsPreparingDownload] = useState(false);
  const { showAlert, showSuccess } = useAlerts();
  const { showModal } = useModal();

  const history = useHistory();

  const duplicateCollectionMutation = useDuplicateCollectionMutation({
    onSuccess: () => {
      showSuccess(`Successfully duplicated collection #${id}`);
      history.push('/collections');
    },
  });

  const { getErrorMessage } = useErrorMessages();

  const [selectedAssets, updateSelectedAssets] = useAtom(selectedAssetsAtom);

  const patchCollectionMutation = useCollectionMutation(parseInt(id), {
    onSuccess: (_data, variables) => {
      const collectionId = parseInt(id);
      const { deletions } = variables;
      const currentQueryData = queryClient.getQueryData<CollectionResponse>([queryNames.collection, collectionId]);

      if (!currentQueryData) return;

      const { data } = currentQueryData;

      const newAssets = data.assets.filter((asset) => {
        return !deletions.includes(asset);
      });

      queryClient.setQueryData([queryNames.collection, collectionId], {
        ...currentQueryData,
        data: {
          ...data,
          assets: newAssets,
        },
      });

      showSuccess(`Successfully removed assets from collection`);
    },
    onError: (error) => {
      showAlert(getErrorMessage(error.message));
    },
  });

  const handleOnAssetSelectChange = (assetId: string) => {
    const newSelectedAssets = selectedAssets.includes(assetId)
      ? selectedAssets.filter((id) => id !== assetId)
      : [...selectedAssets, assetId];
    updateSelectedAssets(newSelectedAssets);
  };

  const handleDownloadCollection = async () => {
    if (isPreparingDownload) return;

    setIsPreparingDownload(true);
    const url: any = await getCollectionDownloadUrl(parseInt(id), accessToken);

    if (url.status === 404) {
      showAlert(`Download not available at the moment. Please try again later.`);
      setIsPreparingDownload(false);
      return;
    }

    window.open(url);
    setIsPreparingDownload(false);
  };

  const showEditCollectionModal = () => showModal(<EditCollectionModal collection={collection.data} />);

  const handleSelectAllAssets = (selected: boolean) => {
    const newSelectedAssets = selected ? collection?.data?.assets : [];
    updateSelectedAssets(newSelectedAssets);
  };

  const handleRemoveSelectedAssets = () => {
    patchCollectionMutation.mutateAsync({
      deletions: selectedAssets,
    });
  };

  const handleOnClickAction = (action: string) => {
    switch (action) {
      case PAGE_ACTIONS.REMOVE_FROM_COLLECTION:
        return handleRemoveSelectedAssets();
    }
  };

  const handleDuplicateCollection = () =>
    showModal(
      <ConfirmDuplicateCollectionModal
        title={`Duplicate collection ${collection.data.name}`}
        sourceId={parseInt(id)}
        name={`${collection.data.name} (copy)`}
        description={collection.data.description}
        groupAccess={collection.data.group_access}
      />,
    );

  return (
    <div className={styles.pageWrapper}>
      <section className={styles.collectionHeader}>
        <h1>
          <span className={styles.pageTitle}>Collection</span>
          <span className={styles.collectionName}>{collection?.data?.name}</span>
        </h1>
        {collection?.data?.description && (
          <p className={styles.collectionDescription}>{collection?.data?.description}</p>
        )}
      </section>

      <PageTitle
        title="Assets"
        counter={collection?.data?.assets.length}
        midContainer={
          <div className={styles.buttonGroup}>
            {/* <Button size="small" mode="tertiary" icon={<ShareNetwork />}>
              Share
        </Button> */}
            <Button size="small" mode="tertiary" icon={<Copy />} onClick={handleDuplicateCollection}>
              Duplicate
            </Button>
            <Button
              size="small"
              mode="tertiary"
              icon={<Pencil />}
              onClick={showEditCollectionModal}
              disabled={userEmail !== collection?.data?.user_email}
            >
              Edit
            </Button>
          </div>
        }
        rightContainer={
          <Button
            size="small"
            icon={<FileArrowDown />}
            onClick={handleDownloadCollection}
            loading={isPreparingDownload}
            disabled={isPreparingDownload || !collection?.data?.assets.length}
          >
            Download All
          </Button>
        }
      />
      <p className={styles.lastUpdate}>
        Created by {userEmail === collection?.data?.user_email ? 'You' : collection?.data?.user_email}, updated{' '}
        {moment(collection?.data?.last_update).fromNow()}
      </p>

      <div className={styles.pageActions}>
        <BatchActions
          basket={false}
          showSelectAll={collection?.data?.assets.length > 0}
          onSelectAll={handleSelectAllAssets}
          pageActions={{
            options: [
              {
                key: PAGE_ACTIONS.REMOVE_FROM_COLLECTION,
                value: 'Remove from collection',
                icon: <Trash />,
                optionDisabled: selectedAssets.length === 0,
              },
            ],
            onClick: handleOnClickAction,
          }}
        />
        {collection?.data?.assets.length > 0 && <LayoutViewSwitcher />}
      </div>

      <LayoutView
        data={collection?.data?.assets}
        isLoading={isLoading}
        emptyDataMessage="No assets found"
        classNames={{
          cardView: 'listingGridWrapper',
        }}
        cardView={(assetId: string) => (
          <ListingCard
            key={assetId}
            id={assetId}
            siblings={collection?.data?.assets}
            imageUrl={getThumbnailUrl({ width: 300, height: 300, id: assetId, jwt: accessToken })}
            name={collection.data?.extended_result[assetId]?.name}
            size={collection.data?.extended_result[assetId]?.size}
            contentType={collection.data?.extended_result[assetId]?.content_type}
            trashed={collection.data?.extended_result[assetId]?.trashed}
            status={collection?.data?.extended_result[assetId]?.status}
            onSelectChange={() => handleOnAssetSelectChange(assetId)}
            isSelected={selectedAssets.includes(assetId)}
            error={collection?.data?.extended_result[assetId]?.errors}
          />
        )}
        listView={(assetId: string) => (
          <ListingRow
            key={assetId}
            id={assetId}
            siblings={collection?.data?.assets}
            imageUrl={getThumbnailUrl({ width: 100, height: 100, id: assetId, jwt: accessToken })}
            name={collection.data?.extended_result[assetId]?.name}
            size={collection.data?.extended_result[assetId]?.size}
            uploadTime={collection.data?.extended_result[assetId]?.upload_time}
            contentType={collection.data?.extended_result[assetId]?.content_type}
            trashed={collection.data?.extended_result[assetId]?.trashed}
            status={collection?.data?.extended_result[assetId]?.status}
            onSelectChange={() => handleOnAssetSelectChange(assetId)}
            isSelected={selectedAssets.includes(assetId)}
            error={collection?.data?.extended_result[assetId]?.errors}
          />
        )}
      />
    </div>
  );
};

export default Collection;
