import React, { createContext, useEffect, useReducer, useState } from 'react';
import { Button, Logo, Paper } from 'src/components';
import Intro from './components/Intro';
import Profile from './steps/Profile';
import Storage from './steps/Storage';
import Watermark from './steps/Watermark';

import styles from './styles.module.scss';
import { DEFAULT_STATE, OnboardingContext, reducer } from './context';
import { useHistory, useParams } from 'react-router-dom';
import { useActivateAccountQuery } from 'src/api/queries/accessQueries';
import Loading from './components/Loading';
import InvalidCode from './components/InvalidCode';
import { useSignIn } from 'react-auth-kit';
import { AuthTokenRequest, AuthTokenResponse } from 'src/types/TApiQueries';

type OnboardingParams = {
  code: string;
};

const Onboarding = () => {
  const history = useHistory();

  const [state, dispatcher] = useReducer(reducer, {
    ...DEFAULT_STATE,
    steps: [
      {
        title: 'Watermark',
        component: <Watermark />,
        skippable: true,
      },
      {
        title: 'Storage',
        component: <Storage />,
        skippable: true,
      },
      {
        title: 'Profile',
        component: <Profile />,
      },
    ],
  });

  const signIn = useSignIn();

  const setTokenOrThrow = ({
    jwt,
    accessID,
    notifications,
    permissions,
    preferences,
    role_id,
    role_name,
    uploadkey,
    username,
    user_email,
    user_id,
  }: AuthTokenResponse) => {
    if (
      signIn({
        token: jwt,
        expiresIn: 86400,
        tokenType: 'Bearer',
        authState: {
          isLoggedIn: true,
          accessId: accessID,
          uploadkey,
          role: role_name,
          roleId: role_id,
          permissions,
          notifications,
          preferences,
          username,
          userEmail: user_email,
          userId: user_id,
        },
      })
    ) {
      setTokenStatus('success');
    } else {
      setTokenStatus('error');
    }
  };

  const [showOnboarding, setShowOnboarding] = useState(false);

  const [tokenStatus, setTokenStatus] = useState<'loading' | 'success' | 'error'>('loading');

  const step = state.steps[state.currentStep - 1];

  const { code } = useParams<OnboardingParams>();

  const { isError, isLoading, error } = useActivateAccountQuery(
    code,
    {
      onSuccess: (result) => {
        setTokenOrThrow(result);
      },
    },
    {
      retry: 1,
    },
  );

  useEffect(() => {
    if (state.finishedOnboarding) {
      history.push('/');
    }
  }, [state]);

  if (isError || tokenStatus === 'error') {
    return <InvalidCode />;
  }

  if (isLoading || tokenStatus === 'loading') {
    return <Loading />;
  }

  return (
    <OnboardingContext.Provider value={[state, dispatcher]}>
      {showOnboarding ? step?.component : <Intro onStartOnboarding={() => setShowOnboarding(true)} />}
    </OnboardingContext.Provider>
  );
};

export default Onboarding;
