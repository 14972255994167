import { useMemo } from 'react';
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query';
import { queryFnFetchErrorWrapper } from '../utils/errorHandlers';
import { AuthTokenRequest, AuthTokenResponse, FetchError } from 'src/types/TApiQueries';
import { useAccessToken } from 'src/hooks/useAccessToken';
import {
  createCollection,
  deleteCollection,
  duplicateCollection,
  DuplicateCollectionData,
  patchCollection,
  patchCollections,
} from 'src/api/functions/collectionsApi';
import { queryNames } from 'src/api/utils/queryNames';
import { AllCollectionsResponse } from 'src/types/TCollection';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';

export function useCollectionMutation(
  collectionId: number,
  mutationOptions?: UseMutationOptions<any, FetchError, any>,
) {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () => queryFnFetchErrorWrapper((data: any) => patchCollection(accessToken, data, collectionId)),
    [collectionId, accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      //queryClient.setQueryData([queryNames.collection, collectionId], result);

      queryClient.invalidateQueries([queryNames.collection, 'all']);

      // update local cache
      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}

export function useCollectionsMutation(mutationOptions?: UseMutationOptions<any, FetchError, any>) {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  const mutationFn = useMemo(
    () => queryFnFetchErrorWrapper((data: any) => patchCollections(accessToken, data)),
    [accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      // update local cache
      queryClient.invalidateQueries([queryNames.collection, 'all']);

      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}

export const useCreateCollectionMutation = (mutationOptions?: UseMutationOptions<any, FetchError, any>) => {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () => queryFnFetchErrorWrapper((data: any) => createCollection(accessToken, data)),
    [accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      // update local cache

      const { data } = result;

      queryClient.setQueryData<AllCollectionsResponse | undefined>(
        [queryNames.collection, 'all'],
        (old: AllCollectionsResponse | undefined) => {
          if (!old) return old;

          old.data.collections.unshift(data);

          return old;
        },
      );

      //queryClient.invalidateQueries([queryNames.collection, 'all']);
      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
};

export const useDuplicateCollectionMutation = (mutationOptions?: UseMutationOptions<any, FetchError, any>) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  const { showAlert, showSuccess } = useAlerts();
  const { getErrorMessage } = useErrorMessages();

  const mutationFn = useMemo(
    () => queryFnFetchErrorWrapper((data: DuplicateCollectionData) => duplicateCollection(accessToken, data)),
    [accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        return mutationOptions?.onSuccess?.(data, variables, ...args);
      }

      queryClient.invalidateQueries([queryNames.collection, 'search']);
      showSuccess(`Successfully duplicated collection #${variables.source_id}`);

      //TODO: Implement a global search & pagination state handler
      window.location.reload();
    },
    onError: (error, ...args) => {
      showAlert(getErrorMessage(error.message));
      mutationOptions?.onError?.(error, ...args);
    },
  });
};

export const useDeleteCollectionMutation = (
  collectionId: number,
  mutationOptions?: UseMutationOptions<any, FetchError, any>,
) => {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () => queryFnFetchErrorWrapper(() => deleteCollection(collectionId, accessToken)),
    [collectionId, accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (...args) => {
      queryClient.invalidateQueries([queryNames.collection, 'search']);
      mutationOptions?.onSuccess?.(...args);
    },
  });
};
