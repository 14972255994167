type sortConfig = {
  delimiter: string;
  validFields: string[];
  validDirections: string[];
};

type ValidatorCallback = (lowerCaseSort: string, validFields: string[], validDirections: string[]) => boolean;

export const isValidSort = (sort: string, sortConfig: sortConfig, validator?: ValidatorCallback) => {
  const { validFields, validDirections, delimiter } = sortConfig;

  if (validator) return validator(sort.toLowerCase(), validFields, validDirections);

  const sortArray = sort.split(delimiter);

  if (sortArray.length !== 2) return false;

  const [field, direction] = sortArray;

  if (!validFields.includes(field.toLowerCase())) return false;

  if (!validDirections.includes(direction.toLowerCase())) return false;

  return true;
};
