import {
  AdminSettingsSidebar,
  Button,
  SettingsNavigation,
} from 'src/components';
import SaveChangesBar from './components/SaveChangesBar'
import styles from './styles.module.scss';
import * as React from 'react';
import useModal from 'src/hooks/useModal';
import { useSubstitutionsQuery } from 'src/api/queries/toolsQueries';
import ConfirmSubstitutionWordModal from './components/ConfirmSubstitutionWordModal';
import WordsLayoutView from './components/wordsLayout';
import { useUpdateSubstitutionMutation } from 'src/api/mutations/toolsMutations';
import { useAtom } from 'jotai'
import {
  wordSubstitutionPayloadAtom,
  originalWordSubstitutionPayloadAtom,
  writeWordSubstitutionPayloadAtom,
  originalWriteWordSubstitutionPayloadAtom
} from './state'


function arraysEqual(a: any, b: any) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}


function objectsAreEqual(object1: any, object2: any): boolean {
  return JSON.stringify(object1) === JSON.stringify(object2)
} 

const useSynchronisation = (sourceValue: any, setter: (value: any) => void) => {
  React.useEffect(() => {
    if (!sourceValue) return
    const deepCopy = JSON.parse(JSON.stringify(sourceValue))
    setter(deepCopy)
  }, [JSON.stringify(sourceValue)]
  )
}

const useRemoteSynch = () => {
  const [, setWordSubstitutionList] = useAtom(writeWordSubstitutionPayloadAtom)
  const [, setOriginaltWordSubstitutionList] = useAtom(originalWriteWordSubstitutionPayloadAtom)


  const substitutionQuery = useSubstitutionsQuery(undefined, { staleTime: 1000 * 60 * 5 });

  const remoteData = objectsAreEqual(substitutionQuery.data, {})
    ? { lang: '', domain: '', words: {} }
    : substitutionQuery.data

  useSynchronisation(remoteData, setWordSubstitutionList)
  useSynchronisation(remoteData, setOriginaltWordSubstitutionList)
  return substitutionQuery.isLoading
}



export const ToolsSettings = () => {
  const isLoading = useRemoteSynch()
  const { showModal } = useModal();
  const [wordSubstitutionList, setWordSubstitutionList] = useAtom(wordSubstitutionPayloadAtom)
  const [originalWordSubstitutionList] = useAtom(originalWordSubstitutionPayloadAtom)
  const updateSubstitutionMutation = useUpdateSubstitutionMutation()
  const saveUnsavedChanges = () => updateSubstitutionMutation.mutateAsync(wordSubstitutionList);
  const resetUnsavedChanges = () => setWordSubstitutionList(JSON.parse(JSON.stringify(originalWordSubstitutionList)))

  if (isLoading) return null;

  console.log(wordSubstitutionList)

  const handleAddWordClick = () => {
    showModal(
      <ConfirmSubstitutionWordModal
        title="Add New Word"
        type="add"
        word=''
        variants={['']}
      />,
    );
  }

  const originalWords = !objectsAreEqual(originalWordSubstitutionList, {})  
    ? Object.keys(originalWordSubstitutionList.words)
    : []

  const currentWords = !objectsAreEqual(wordSubstitutionList, {}) 
    ? Object.keys(wordSubstitutionList.words)
    : []
  
  let changesAreSaved = arraysEqual(originalWords, currentWords)

  for (const word of currentWords) {
    changesAreSaved = changesAreSaved && arraysEqual(
      originalWordSubstitutionList.words[word],
      wordSubstitutionList.words[word]
    )
  }

  return (
    <div className={styles.pageWrapper}>
      <SettingsNavigation />
      <SaveChangesBar
        isVisible={!changesAreSaved}
        isLoading={updateSubstitutionMutation.isLoading}
        onCancel={resetUnsavedChanges}
        onConfirm={saveUnsavedChanges}
      />
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <AdminSettingsSidebar
            items={[
              {
                key: 'subtitlesWordSubstitution',
                label: 'Subtitles Word Substitution',
              },
            ]}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.PageTitle}>
            <div>
              <span className={styles.title}>
                {'Subtitles Word Substitution'}
              </span>
            </div>
            <div>
              <Button size="small" onClick={handleAddWordClick}>
                Add Word
              </Button>
            </div>
          </div>
          <WordsLayoutView className='listingGridWrapper' />
        </div>
      </div>
    </div>
  );
};
