import * as React from 'react';

function SvgKey(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M8.735 11.516a6.744 6.744 0 113.75 3.75h0L11.251 16.5H9v2.25H6.75V21H3v-3.75l5.734-5.735h0z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.875 8.063a.937.937 0 100-1.875.937.937 0 000 1.875z" fill="currentColor" />
    </svg>
  );
}

export default SvgKey;
