import * as React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { motion } from 'framer-motion';
import WordsListingRow from '../wordListingRow';

import { useAtom } from 'jotai'
import { wordsListAtom, wordsAtom } from '../../state'

import WordsLayoutViewProps from './types';



/**
 * Layout view component
 * 
 * @param props
 * 
 * @returns
 * 
 **/
export const WordsLayoutView: React.FC<WordsLayoutViewProps> = (props) => {

  const wordsList = useAtom(wordsListAtom)[0]
  const words = useAtom(wordsAtom)[0]
  const isEmpty = wordsList.length === 0;


  const Wrapper = (innerProps: any) => {
    return (
      <div className={cn([styles.LayoutView, props.className, isEmpty && styles.empty])} data-test="layout-view">
        {isEmpty ? <p>Empty.</p> : innerProps.children}
      </div>
    )
  }

  return (
    <Wrapper>
      <motion.div className={cn(props.className)} initial={false} animate={{ opacity: 1, display: '' }}>
        {
          wordsList.map((word) => {
            const variants = words[word];
            return (
              <WordsListingRow
                word={word}
                variants={variants}
                key={word}
              />
            )
          })
        }
      </motion.div>
    </Wrapper>
  );
};

export default WordsLayoutView;
