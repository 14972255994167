import React from 'react';
import { AdminSettingsSection, Paper } from 'src/components';

import styles from '../styles.module.scss';

type SecuritySectionProps = {
  id: string;
  title: string;
  children: React.ReactNode;
  description: string;
};

export const SecuritySection: React.FC<SecuritySectionProps> = ({ id, title, children, description }) => (
  <AdminSettingsSection title={title}>
    <Paper>
      {children}
      <p className={styles.securitySectionDescription}>{description}</p>
    </Paper>
  </AdminSettingsSection>
);
