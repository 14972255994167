import BaseLayout from './BaseLayout';

const InvalidCode = () => {
  return (
    <BaseLayout>
      <h1>Looks like this code is invalid. The code may have expired. You can request the administrator to re-issue the invite.</h1>
    </BaseLayout>
  );
};

export default InvalidCode;
