import React, { useState } from 'react';
import BaseModal from '../BaseModal';

import styles from './Getlinks.module.scss';
import Input from '../Input';
import Button from '../Button';
import Select from '../Select';
import Checkbox from '../Checkbox';
import { useAuthUser } from 'react-auth-kit';
import { getBatchAssetLinks } from 'src/api/functions/assetsApi';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { useAlerts } from 'src/common/AlertManager';
import { CaretLeft } from 'src/common/icons';
import cn from 'classnames';

interface Props {}

interface U {
  asset_id: string;
  url: string;
}

export const GetLinksModal: React.FC<Props> = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [urls, setUrls] = useState<U[]>();

  const auth = useAuthUser();
  const accessToken = useAccessToken();
  const { showAlert } = useAlerts();

  const [dataToSend, setDataToSend] = useState<{
    ids: string[];
    expiry: number;
    content_key: string;
    delivery_mode: string;
    is_expiry_max: boolean;
  }>({
    ids: [],
    expiry: 1,
    content_key: 'original',
    delivery_mode: 'display',
    is_expiry_max: false,
  });

  const modifySetIds = (data: string[]) => {
    setDataToSend((prev: any) => {
      const d = { ...prev };
      d['ids'] = data;
      return d;
    });
  };

  const splitId = (stringID: string) => {
    if (stringID.length !== 0) {
      let ids: string[] = stringID.split(',');
      if (ids.length > 100) {
        setErrorMessage('Number of Assets must be lesser than 100');
        return;
      }
      setErrorMessage('');
      modifySetIds(ids);
    } else {
      setErrorMessage('');
      modifySetIds([]);
    }
  };

  const submitBtn = () => {
    setLoading(true);
    const content_key = dataToSend['content_key'];
    const expiry = dataToSend['expiry'] * 3600;
    const is_expiry_max = dataToSend['is_expiry_max'];
    const delivery_mode = dataToSend['delivery_mode'];
    const ids: string[] = [];
    dataToSend['ids'].forEach((id) => {
      ids.push(id.trim());
    });
    if (expiry < 1) {
      setErrorMessage('Min expiry cannot be lesser than 1 hour');
      setLoading(false);
      return;
    }
    getBatchAssetLinks(ids, accessToken, content_key, expiry, is_expiry_max, delivery_mode, auth()!.accessId)
      .then((value) => {
        const temp: U[] = [];
        Object.keys(value).forEach((key) => {
          temp.push({ asset_id: key, url: value[key] });
        });
        setUrls(temp);
        setShowResult(true);
      })
      .catch((error) => {
        showAlert('Oops ! Something went wrong.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BaseModal containerStyle={{ width: '90%', height: '90%', overflow: 'scroll' }}>
      <h3 className={styles.modal_header}>
        {showResult && (
          <CaretLeft
            style={{ fontSize: '24px', marginTop: '1px', marginRight: '5px', cursor: 'pointer' }}
            onClick={() => {
              setShowResult(false);
              setUrls(undefined);
            }}
          />
        )}{' '}
        Get Asset Links{' '}
      </h3>
      {!showResult && (
        <React.Fragment>
          <div style={{ margin: '10px', height: '60%' }}>
            <textarea
              id="assetIds"
              className={styles.textarea_sty}
              placeholder="Enter Asset IDs (comma separated, no spaces)"
              onChange={(e) => {
                splitId(e.target.value);
              }}
              value={dataToSend['ids']}
            />
          </div>
          <div>
            <span className={styles.error_sty} style={{ visibility: errorMessage ? 'visible' : 'hidden' }}>
              {errorMessage}
            </span>

            <div style={{ marginBlock: '12px', display: 'flex', gap: 20 }}>
              <div className={styles.option_holder}>
                <h4 className={styles.option_header}>Content Key</h4>
                <Input
                  className={styles.content_input}
                  placeholder="original"
                  value={dataToSend.content_key}
                  onChange={(v) => {
                    setDataToSend((prev: any) => {
                      return { ...prev, content_key: v };
                    });
                  }}
                />
              </div>

              <div className={styles.option_holder}>
                <h4 className={styles.option_header}>Delivery Mode</h4>
                <Select
                  className={styles.Select}
                  options={[
                    { label: 'Display', value: 'display' },
                    { label: 'Download', value: 'download' },
                  ]}
                  value={dataToSend.delivery_mode}
                  onChange={(v) => {
                    setDataToSend((prev: any) => {
                      return { ...prev, delivery_mode: v };
                    });
                  }}
                />
              </div>

              <div className={styles.option_holder}>
                <h4 className={styles.option_header}>Expiry</h4>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Input
                    className={styles.expiry_input}
                    type="number"
                    placeholder="1"
                    value={dataToSend.expiry}
                    min={1}
                    onChange={(v) => {
                      setErrorMessage('');
                      setDataToSend((prev: any) => {
                        return { ...prev, expiry: v };
                      });
                    }}
                  />
                  <span>hour</span>
                  <h4 style={{ margin: 0, fontSize: '15px' }}> (&nbsp;OR&nbsp;)</h4>
                  <Checkbox
                    label="MAX"
                    checked={dataToSend.is_expiry_max}
                    onChange={(v) => {
                      setDataToSend((prev: any) => {
                        return { ...prev, is_expiry_max: v.target.checked };
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.btnHolder}>
            <Button
              size="small"
              disabled={errorMessage?.length !== 0 || dataToSend['ids']?.length === 0}
              onClick={submitBtn}
              loading={loading}
            >
              Get Links
            </Button>
          </div>
        </React.Fragment>
      )}
      {showResult && (
        <React.Fragment>
          <div style={{ margin: '10px', marginBlock: '20px' }}>
            <table className="table-fill">
              <thead>
                <tr className={styles.table_row}>
                  <th className={cn([styles.text_center, styles.table_head])}>Asset ID</th>
                  <th className={cn([styles.text_center, styles.table_head])}>Link</th>
                </tr>
              </thead>
              <tbody className="table-hover">
                {urls?.map((data) => {
                  return (
                    <React.Fragment>
                      <tr className={styles.table_row}>
                        <td className={cn([styles.text_center, styles.table_data])}>{data.asset_id}</td>
                        <td className={cn([styles.text_center, styles.table_data])} style={{ maxWidth: '550px' }}>
                          <a href={data.url} target="_target">
                            {data.url ? data.url : 'Content key not available for asset'}
                          </a>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </BaseModal>
  );
};
