import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { layoutViewAtom } from 'src/shared/layoutView';
import { useLocalStorage } from './useLocalStorage';

const useLayoutSwitcher = () => {
  const [layoutView, setLayoutView] = useAtom(layoutViewAtom);
  const [savedLayoutView, saveLayoutView] = useLocalStorage('dashboard.layout', 'list');

  const toggleLayoutView = () => {
    const layout = layoutView === 'list' ? 'grid' : 'list';
    setLayoutView(layout);
    saveLayoutView(layout);
  };

  useEffect(() => {
    setLayoutView(savedLayoutView as 'list' | 'grid');
  }, []);

  return { layoutView, toggleLayoutView, isLayoutGrid: layoutView === 'grid', isLayoutList: layoutView === 'list' };
};

export default useLayoutSwitcher;
