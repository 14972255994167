import * as React from 'react';

function SvgImageSquare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M19.5 3.75h-15a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h15a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 15l-3.97-3.97a.75.75 0 00-1.06 0l-4.19 4.19a.75.75 0 01-1.06 0l-1.94-1.94a.75.75 0 00-1.06 0L3.75 16.5"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.375 9.563a.937.937 0 100-1.875.937.937 0 000 1.875z" fill="currentColor" />
    </svg>
  );
}

export default SvgImageSquare;
