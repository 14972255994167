import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { serviceToken } from 'src/api/functions/accessApi';
import { pickerModeAtom } from 'src/shared/pickerMode';
import { securitySettingsAtom } from 'src/shared/settings/security';
import { useAccessToken } from './useAccessToken';

const providers = ['local', 'weburl', 'flickr', 'box', 'onedrive', 'googledrive'];

const fileSpinEvents = ['close', 'ready', 'complete', 'start'];

type FileSpinHookOptions = {
  events?: {
    close?: () => void;
    ready?: () => void;
    start?: () => void;
    complete?: (data: any) => void;
    open?: () => void;
  };
};

const useFileSpinPicker = (options?: FileSpinHookOptions) => {
  const [mode, setMode] = useAtom(pickerModeAtom);

  const accessToken = useAccessToken();
  const securitySettings = useAtomValue(securitySettingsAtom);

  if (
    securitySettings?.dropbox_app_key?.length > 0 &&
    securitySettings?.dropbox_app_secret?.length > 0 &&
    !providers.includes('dropbox')
  ) {
    providers.push('dropbox');
  }

  const showPicker = () => {
    window.FileSpin.showPicker();
    options?.events?.open?.();
  };
  const resetPicker = () => window.FileSpin.resetPicker();
  const hidePicker = () => window.FileSpin.hidePicker();

  const replaceContent = async (id: string) => {
    const serviceKey = await serviceToken(accessToken);

    window.FileSpin.initPicker(
      {
        uploadKey: securitySettings.upload_key,
        providers,
        serviceToken: serviceKey,
        fileToReplaceId: id,
        autoUpload: false,
        display: false,
        // hideAfterUpload: true,
      },
      () => {
        showPicker();
        if (!mode || mode === 'add') {
          setMode('replace');
        }
      },
    );
  };

  const uploadContent = () => {
    if (!mode || mode === 'replace') {
      window.FileSpin.initPicker(
        {
          uploadKey: securitySettings.upload_key,
          providers,
          autoUpload: false,
          minimizeOnUpload: false,
          display: false,
        },
        () => {
          showPicker();
          setMode('add');
        },
      );
    } else {
      resetPicker();
      showPicker();
    }
  };

  useEffect(() => {
    if (!window.FileSpin || !options?.events) return;

    const { events } = options;

    Object.entries(events).forEach(([event, callback]) => {
      if (fileSpinEvents.includes(event)) {
        window.FileSpin.on(event, callback);
      }
    });
  }, [options]);

  return {
    showPicker,
    hidePicker,
    replaceContent,
    uploadContent,
  };
};

export default useFileSpinPicker;
