import { find } from 'lodash';
import { useIntl } from 'react-intl';
import errorMessages from 'src/common/errorMessages';

const useErrorMessages = () => {
  const { formatMessage } = useIntl();

  const getErrorMessage = (errorKey: string) => {
    const errorMessage = find(errorMessages, { id: errorKey });

    return formatMessage(errorMessage || errorMessages.unknownError);
  };

  return {
    getErrorMessage,
  };
};

export default useErrorMessages;
