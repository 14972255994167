import { useMemo } from 'react';
import { UseMutationOptions, useQueryClient, useMutation } from 'react-query';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { FetchError } from 'src/types/TApiQueries';
import { addUser, editUser } from '../functions/userManagementApi';
import { queryFnFetchErrorWrapper } from '../utils/errorHandlers';
import { queryNames } from '../utils/queryNames';

export function useAddUserManagementMutation(mutationOptions?: UseMutationOptions<any, FetchError, any>) {
  const accessToken = useAccessToken();

  const mutationFn = useMemo(() => queryFnFetchErrorWrapper((data: any) => addUser(data, accessToken)), [accessToken]);

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}

export function useEditUserManagementMutation(
  userId: number,
  mutationOptions?: UseMutationOptions<any, FetchError, any>,
) {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () => queryFnFetchErrorWrapper((data: any) => editUser(userId, data, accessToken)),
    [userId, accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
  });
}
