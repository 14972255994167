import * as React from 'react';

function SvgCheckboxChecked(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <rect y={0.5} width={24} height={24} rx={6} fill="#E7EBFC" />
      <path
        d="M17 9l-7.333 7L6 12.5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCheckboxChecked;
