import React, { useRef, useEffect } from 'react';

import cn from 'classnames';
import styles from './Slider.module.scss';
import messages from './messages';

type SliderProps = {
  value: number;
  onChange: (value: number) => void;
  min: string;
  max: string;
  step: string;
};

export const Slider: React.FC<SliderProps> = ({ value, onChange, min, max, step }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;

    const input = inputRef.current;

    const [minValue, maxValue] = [parseFloat(min), parseFloat(max)];

    const percentage = ((value - minValue) / (maxValue - minValue)) * 100;
    input.style.background = `linear-gradient(to right, #2e53e3 0%, #2e53e3 ${percentage}%, #e7ebfc ${percentage}%, #e7ebfc 100%)`;
  }, [value, inputRef]);

  return (
    <div className={styles.sliderContainer}>
      <span>{value.toFixed(2)}</span>
      <input
        ref={inputRef}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => onChange(parseFloat(e.target.value))}
      />
    </div>
  );
};

export default Slider;
