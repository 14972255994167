export enum PERMISSIONS {
  CREATE_ASSET = 'CREATE_ASSET',
  READ_ASSET = 'READ_ASSET',
  DOWNLOAD_ASSET = 'DOWNLOAD_ASSET',
  EDIT_ASSET = 'EDIT_ASSET',
  DISTRIBUTE_ASSET = 'DISTRIBUTE_ASSET',
  DELETE_UNDELETE_ASSETS = 'DELETE_UNDELETE_ASSET',
  PURGE_TRASH = 'PURGE_TRASH',
  MANAGE_USERS = 'MANAGE_USERS',
  UPDATE_SETTINGS = 'UPDATE_SETTINGS',
}

export enum ROLES {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  USER = 'USER',
  CREATOR = 'CREATOR',
}

const rolesConfig = {
  [ROLES.ADMIN]: [PERMISSIONS.CREATE_ASSET, PERMISSIONS.READ_ASSET, PERMISSIONS.DOWNLOAD_ASSET, PERMISSIONS.EDIT_ASSET, PERMISSIONS.DISTRIBUTE_ASSET, 
                  PERMISSIONS.DELETE_UNDELETE_ASSETS, PERMISSIONS.PURGE_TRASH,
                  PERMISSIONS.MANAGE_USERS, PERMISSIONS.UPDATE_SETTINGS],
  [ROLES.MANAGER]: [PERMISSIONS.CREATE_ASSET, PERMISSIONS.READ_ASSET, PERMISSIONS.DOWNLOAD_ASSET, PERMISSIONS.EDIT_ASSET, PERMISSIONS.DISTRIBUTE_ASSET, 
    PERMISSIONS.DELETE_UNDELETE_ASSETS, PERMISSIONS.UPDATE_SETTINGS],
  [ROLES.USER]: [PERMISSIONS.READ_ASSET, PERMISSIONS.DOWNLOAD_ASSET, PERMISSIONS.DISTRIBUTE_ASSET],
  [ROLES.CREATOR]: [PERMISSIONS.CREATE_ASSET, PERMISSIONS.EDIT_ASSET, PERMISSIONS.READ_ASSET, PERMISSIONS.DOWNLOAD_ASSET, PERMISSIONS.DISTRIBUTE_ASSET],
};

export default rolesConfig;
