import * as React from 'react';

function SvgBell(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M12 28h8M7.025 13a8.987 8.987 0 019.043-9c4.95.037 8.908 4.15 8.908 9.113V14c0 4.477.936 7.075 1.761 8.495a1 1 0 01-.86 1.505H6.123a1.001 1.001 0 01-.86-1.505c.825-1.42 1.762-4.018 1.762-8.495v-1z"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgBell;
