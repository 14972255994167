import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useAssetMetadataMutation } from 'src/api/mutations/assetMutations';
import { queryNames } from 'src/api/utils/queryNames';
import { useAlerts } from 'src/common/AlertManager';
import { Pencil, Trash } from 'src/common/icons';
import SvgPlus from 'src/common/icons/Plus';
import { Button, Input } from 'src/components';
import messages from '../messages';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import { Asset } from 'src/types/TAssets';
import { object, string } from 'yup';
import styles from '../Modal.module.scss';

const MAX_FIELDS_ALLOWED = 10;
const MAXIMUM_FIELD_NAME_LENGTH = 24;

type SetupFreeFormMetadataProps = {
  asset: Asset;
  onCancel: () => void;
};

type MetadataFieldProps = {
  onRemoveField: () => void;
  onEditField: () => void;
  onAddField: (fieldName: string, fieldValue: string) => void;
};

const fieldNameSchema = string()
  .max(MAXIMUM_FIELD_NAME_LENGTH, "Field name can't be longer than 24 characters")
  .required('Field name is required')
  .matches(
    /^[a-zA-Z0-9_]+$/,
    'Field name must be alphanumeric name with no spaces, only underscores or dashes allowed',
  );
const fieldValueSchema = string().required('Field value is required');

const fieldSchema = object().shape({
  name: fieldNameSchema,
  value: fieldValueSchema,
});

const MetadataField = ({ onRemoveField, onAddField, onEditField }: MetadataFieldProps) => {
  const [fieldName, setFieldName] = useState('');
  const [fieldValue, setFieldValue] = useState('');
  const [error, setError] = useState<string>();
  const [editMode, setEditMode] = useState(true);

  const handleFinish = async () => {
    try {
      await fieldSchema.validate({
        name: fieldName,
        value: fieldValue,
      });

      setEditMode(false);
      onAddField(fieldName, fieldValue);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleEditField = () => {
    onEditField();
    setEditMode(true);
  };

  return editMode ? (
    <div className={styles.metadataFreeFormDataInput}>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.metadataFreeFormFieldName}>
        <Input
          type="string"
          variant="secondary"
          value={fieldName}
          placeholder="Field Name"
          onChange={(value) => setFieldName(value)}
        />
        <Button icon={<Trash fontSize={24} />} mode="flat" size="small" onClick={onRemoveField} />
      </div>
      <div>
        <Input
          type="string"
          variant="secondary"
          value={fieldValue}
          placeholder="Field Value"
          onChange={(value) => setFieldValue(value)}
        />
      </div>
      <Button mode="primary" size="small" onClick={handleFinish} className={styles.fullWidthButton}>
        Finish
      </Button>
    </div>
  ) : (
    <div className={styles.metaFieldEditable}>
      <div>
        <p>{fieldName}</p>
        <Button icon={<Pencil />} mode="flat" size="small" onClick={handleEditField} />
      </div>
      <div>{fieldValue}</div>
    </div>
  );
};

const SetupFreeFormMetadata = ({ asset, onCancel }: SetupFreeFormMetadataProps) => {
  const [fields, setFields] = useState<any>([]);
  const [isAddingField, setIsAddingField] = useState(false);

  const queryClient = useQueryClient();

  const { showSuccess, showAlert } = useAlerts();
  const { getErrorMessage } = useErrorMessages();

  const metadataMutation = useAssetMetadataMutation(asset.id, {
    onError: (error, newData, context: any) => {
      queryClient.setQueryData([queryNames.asset, asset.id], context.previousData);
      // TODO: Handle error, show feedback
      showAlert(getErrorMessage(error.message));
    },
    onMutate: (data) => {
      const previousData = queryClient.getQueryData<Asset>([queryNames.asset, asset.id]);
      queryClient.setQueryData<Asset | undefined>([queryNames.asset, asset.id], (old) => {
        if (typeof old !== 'undefined') {
          old.data = data;
        }
        return old;
      });

      showSuccess('Asset metadata updated sucessfully');

      return { previousData };
    },
  });

  const handleAddInput = () => {
    if (fields.length >= MAX_FIELDS_ALLOWED) return;

    setFields([...fields, {}]);
    setIsAddingField(true);
  };

  const handleFieldChange = (index: number, fieldName: any, fieldValue: any) => {
    setFields((currentFields: any) => {
      const newFields = [...currentFields];
      newFields[index] = { value: fieldValue, name: fieldName };
      return newFields;
    });
    setIsAddingField(false);
  };

  const handleRemoveField = (index: number) => {
    setFields((currentFields: any[]) => {
      const newFields = [...currentFields];
      newFields.splice(index, 1);
      return newFields;
    });
    setIsAddingField(false);
  };

  const handleSubmit = async () => {
    const metadata: any = {};

    fields.forEach((field: any) => {
      metadata[field.name] = field.value;
    });

    await metadataMutation.mutateAsync({
      ...metadata,
    });
  };

  return (
    <div className={styles.freeFormDataContainer}>
      {fields.map((field: any, index: number) => (
        <MetadataField
          key={index}
          onRemoveField={() => handleRemoveField(index)}
          onAddField={(fieldName, fieldValue) => handleFieldChange(index, fieldName, fieldValue)}
          onEditField={() => setIsAddingField(true)}
        />
      ))}

      {fields.length >= MAX_FIELDS_ALLOWED && <p>You've reached a limit of {MAX_FIELDS_ALLOWED} fields allowed.</p>}
      {!isAddingField && (
        <Button
          icon={<SvgPlus color="var(--color-prime-400)" />}
          mode="tertiary"
          size="small"
          onClick={handleAddInput}
          disabled={fields.length >= MAX_FIELDS_ALLOWED}
          className={styles.fullWidthButton}
        >
          <FormattedMessage {...messages.addField} />
        </Button>
      )}
      <div className={styles.formButtonsContainer}>
        <Button mode="tertiary" size="small" onClick={onCancel}>
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button mode="primary" size="small" onClick={handleSubmit} disabled={isAddingField}>
          <FormattedMessage {...messages.submit} />
        </Button>
      </div>
    </div>
  );
};

export default SetupFreeFormMetadata;
