import * as React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';
import { NavBar, PageTitle } from 'src/components';

export const AdminSettingsLayout: React.FC<{}> = ({ children, ...rest }) => {
  return (
    <div className={styles.layout}>
      <NavBar />
      <PageTitle title="Settings" className={styles.pageTitle} />
      {children}
    </div>
  );
};

export default AdminSettingsLayout;
