import { defineMessages } from 'react-intl';

export default defineMessages({
	add: {
		id: 'pages.collections.add',
		defaultMessage: 'New Collection',
	},
	saveSearch: {
		id: 'pages.collections.saveSearch',
		defaultMessage: 'Save this search',
	},
});
