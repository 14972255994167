import React, { useState } from 'react';
import cn from 'classnames';
import styles from './PrivateField.module.scss';
import messages from './messages';

type PrivateFieldProps = {
  className?: string;
  fieldName: string;
  children: (visible: boolean, style: string | undefined) => React.ReactNode;
};

export const PrivateField: React.FC<PrivateFieldProps> = ({ children, className, fieldName, ...rest }) => {
  const [visible, setVisible] = useState(false);

  const buttonText = `${visible ? 'Hide' : 'Show'} ${fieldName}`;

  return (
    <div className={cn([styles.PrivateField, className])}>
      {children(visible, visible ? undefined : styles.hiddenField)}
      <button className={styles.revealButton} onClick={() => setVisible((state) => !state)}>
        {buttonText}
      </button>
    </div>
  );
};

export default PrivateField;
