import { useAuthUser } from 'react-auth-kit';
import { Button } from 'src/components';

import styles from '../styles.module.scss';
import BaseLayout from './BaseLayout';

type IntroProps = {
  onStartOnboarding: () => void;
};

const Intro = ({ onStartOnboarding }: IntroProps) => {
  const auth = useAuthUser();

  const authenticatedUser = auth();

  return (
    <BaseLayout>
      <h1>Hello {authenticatedUser?.username && <span>{authenticatedUser?.username}</span>}, welcome to FileSpin!</h1>
      <p>Please follow these steps to setup your account.</p>

      <Button onClick={onStartOnboarding}>Start setup wizard</Button>
    </BaseLayout>
  );
};

export default Intro;
