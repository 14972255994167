import * as React from 'react';

function SvgPanel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M1 1v16h16V1H1zm12.5 15.36H1.64V1.64H13.5v14.72zm2.86 0h-2.22V1.64h2.22v14.72z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.6}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgPanel;
