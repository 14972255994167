import React, { useState } from 'react';
import BaseModal from '../BaseModal';
import { useSettingsQuery } from 'src/api/queries/userQueries';
import Checkbox from 'src/components/Checkbox';
import styles from './Reprocess.module.scss';
import Button from '../Button';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { updateAssetBatchReprocess } from 'src/api/functions/assetsApi';
import { useAlerts } from 'src/common/AlertManager';
import useModel from 'src/hooks/useModal';

interface Props {}

export const ReprocessModal: React.FC<Props> = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [dataToSend, setDataToSend] = useState<{
    [ids: string]: string[];
    conversions: string[];
    transcodes: string[];
    addons: string[];
  }>({ ids: [], conversions: [], transcodes: [], addons: [] });
  const [loading, setLoading] = useState<boolean>(false);

  const { data, isLoading } = useSettingsQuery();
  const accessToken = useAccessToken();
  const { showSuccess, showAlert } = useAlerts();
  const { closeModal } = useModel();

  if (isLoading) {
    return null;
  }

  const conversions = data?.image?.conversions ?? [];
  const transcodes = data?.video?.transcodes ?? {};
  const addons = data?.addons ?? {};

  const stateHandler = (e: React.ChangeEvent<HTMLInputElement>, store: string, key: string) => {
    const d = { ...dataToSend };
    if (e.target.checked) {
      d[store].push(key);
    } else {
      d[store] = d[store].filter((item: string) => item !== key);
    }
    setDataToSend(d);
  };

  const modifySetIds = (data: string[]) => {
    setDataToSend((prev: any) => {
      const d = { ...prev };
      d['ids'] = data;
      return d;
    });
  };

  const splitId = (stringID: string) => {
    if (stringID.length !== 0) {
      let ids: string[] = stringID.split(',');
      if (ids.length > 100) {
        setErrorMessage('Number of Assets must be lesser than 100');
        return;
      }
      let err_flag = false;
      ids.forEach((id: string) => {
        if (id.length !== 32) {
          err_flag = true;
        }
      });
      if (err_flag) {
        setErrorMessage("Invalid Asset ID's Detected");
      } else {
        setErrorMessage('');
        modifySetIds(ids);
      }
    } else {
      setErrorMessage('');
      modifySetIds([]);
    }
  };

  const submitBtn = async () => {
    setLoading(true);
    try {
      const response = await updateAssetBatchReprocess(dataToSend, accessToken);
      showSuccess(`Re-processing Request Submitted Sucessfully for ${dataToSend['ids'].length} Assets !`);
      closeModal();
    } catch (error) {
      showAlert('Invalid Input fields. Please Contact Support Team');
    } finally {
      setLoading(false);
    }
  };
  const excludedKeys = [
    'REMOVE_BACKGROUND',
    'REMOVE_REDEYE',
    'IMAGE_AUTOFIX',
    'IMAGE_DEEPZOOM',
    'IMAGE_GRAPHICS',
    'VIDEO_TRANSCODE',
    'IMAGE_COLLAGE',
  ];
  return (
    <BaseModal containerStyle={{ width: '90%', height: '90%' }}>
      <div style={{ margin: '10px', height: '80%' }}>
        <h3 style={{ fontWeight: 500, margin: '10px' }}>Re-process Assets</h3>
        <div style={{ display: 'flex', gap: 10, height: '100%' }}>
          <div className={styles.modalLeftHolder}>
            <textarea
              id="assetIds"
              className={styles.textarea_sty}
              placeholder="Enter Asset IDs (comma separated, no spaces). Please select atleast one of the key to submit for Reprocess the Asset"
              onChange={(e) => {
                splitId(e.target.value);
              }}
            />
            {errorMessage && <span className={styles.error_sty}>{errorMessage}</span>}
          </div>
          <div className={styles.modalRightHolder}>
            <h3 className={styles.checkbox_title}>Conversions</h3>
            <div>
              {conversions.map((conv: any) => {
                return (
                  <Checkbox
                    className={styles.checkbox_sty}
                    key={conv['key']}
                    label={conv['key']}
                    checked={dataToSend['conversions'].includes(conv['key'])}
                    onChange={(e) => stateHandler(e, 'conversions', conv['key'])}
                  />
                );
              })}
            </div>
            <h3 className={styles.checkbox_title}>Transcodes</h3>
            {Object.keys(transcodes).map((key) => {
              return (
                <Checkbox
                  className={styles.checkbox_sty}
                  key={key}
                  label={key}
                  checked={dataToSend['transcodes'].includes(key)}
                  onChange={(e) => stateHandler(e, 'transcodes', key)}
                />
              );
            })}
            <h3 className={styles.checkbox_title}>Addons</h3>

            {Object.keys(addons).map((key) => {
              return (
                addons[key]['added'] &&
                addons[key]['enabled'] &&
                !excludedKeys.includes(key) && ( // Add condition to check if key is not in the excluded list
                  <Checkbox
                    className={styles.checkbox_sty}
                    key={key}
                    label={key}
                    checked={dataToSend['addons'].includes(key)}
                    onChange={(e) => stateHandler(e, 'addons', key)}
                  />
                )
              );
            })}
          </div>
        </div>
        <div className={styles.btnHolder}>
          <Button
            size="small"
            disabled={
              errorMessage.length > 0 ||
              dataToSend['ids'].length === 0 ||
              (dataToSend['addons'].length === 0 &&
                dataToSend['conversions'].length === 0 &&
                dataToSend['transcodes'].length === 0)
            }
            onClick={submitBtn}
            loading={loading}
          >
            Submit
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
