import { Loader } from 'src/components';
import styles from '../styles.module.scss';

type LoadingScreenProps = {
  message: string;
};

const LoadingScreen = ({ message }: LoadingScreenProps) => (
  <div className={styles.LoadingScreen}>
    <Loader />
    <h3>{message}</h3>
  </div>
);

export default LoadingScreen;
