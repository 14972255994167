import * as Sentry from '@sentry/react';
import { useAtom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { queryFnFetchErrorWrapper, throwFetchError } from 'src/api/utils/errorHandlers';
import { mergeQueryOptions } from 'src/api/utils/helpers';
import { queryNames } from 'src/api/utils/queryNames';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { ACTION_TYPES } from 'src/shared/settings/base';
import { settingsAtom } from 'src/shared/settings/index';
import { CustomQueryOptions, FetchError } from 'src/types/TApiQueries';
import { User } from 'src/types/TUser';
import { getSettings } from '../functions/userApi';


import  {
  originalStoryBoardTypeAtom,
} from 'src/shared/settings/video';



export function useSettingsQuery(options?: CustomQueryOptions, queryOptions?: UseQueryOptions<any[], FetchError>) {
  const actualQueryOptions = mergeQueryOptions<User[]>(options, queryOptions);
  const accessToken = useAccessToken();

  const [settings, setSettings] = useAtom(settingsAtom);
  const [originalStoryBoardType, setOriginalStoryBoardType] = useAtom(originalStoryBoardTypeAtom);

  const fetchFn = async (token: string) => {
    if (settings && originalStoryBoardType !== '') return settings;

    const result = (await getSettings(token)) as any;

    setSettings({
      type: ACTION_TYPES.SET_STATE,
      payload: result.data,
    });

    setOriginalStoryBoardType(result.data.video.thumbnails.storyboard.mode);

    console.debug(result.data.video.thumbnails.storyboard.mode)


    return result.data;
  };

  const queryFn = useMemo(() => queryFnFetchErrorWrapper(() => fetchFn(accessToken)), [accessToken]);

  const query = useQuery<any, FetchError>([queryNames.settings], queryFn, actualQueryOptions);

  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching settings', Sentry.Severity.Error);
    } else {
      throwFetchError(query.error);
    }
  }
  return query;
}
