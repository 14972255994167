import { merge } from 'lodash';
import { createAtom } from './base';
import { getSettings } from './utils';
import { atom, Setter, WritableAtom } from 'jotai';




export const originalStoryBoardTypeAtom = atom('')
export const currentStoryBoardTypeAtom = atom('')





export const { currentVideoSettingsAtom, updatedVideoSettingsAtom, videoSettingsAtom } = createAtom('video', {
  updater: (currentAtom: any, updatedAtom: any, get: any, set: any, action: any) => {
    const { payload } = action;

    const state = get(updatedAtom);

    const mergedState = merge({}, state, payload);

    set(updatedAtom, mergedState);
  },
  setter: (atom, set, action) => {
    const {
      payload,
      payload: {
        transcodes,
        preview,
        thumbnails,
        thumbnails: { storyboard },
      },
    } = action;

    const mappedTranscodes: any = {};
    const mappedPreview: any = { ...preview, video_preview_padding: `#${preview.video_preview_padding}` };
    const mappedStoryboard: any = storyboard ? { ...storyboard, padding: `#${storyboard.padding}` } : undefined;

    Object.keys(transcodes).forEach((key) => {
      mappedTranscodes[key] = {
        ...transcodes[key],
        padding: `#${transcodes[key].padding}`,
      };
    });

    set(atom, {
      ...payload,
      transcodes: mappedTranscodes,
      preview: mappedPreview,
      thumbnails: { ...thumbnails, storyboard: mappedStoryboard },
    });
  },
});

export const getVideoSettings = (get: any) =>
  getSettings(get, [videoSettingsAtom, updatedVideoSettingsAtom], (state: any) => {
    const {
      transcodes,
      preview,
      thumbnails,
      thumbnails: { storyboard },
    } = state;

    const mappedTranscodes: any = {};
    const mappedPreview: any = {
      ...preview,
      video_preview_length: parseInt(preview.video_preview_length),
      video_preview_start: parseInt(preview.video_preview_start),
      video_preview_padding: preview.video_preview_padding.replace('#', ''),
    };

    const mappedStoryboard = { ...storyboard };

    if (storyboard) {
      mappedStoryboard.padding = storyboard.padding.replace('#', '');
    }

    Object.keys(transcodes).forEach((key) => {
      const { watermark, padding, enabled, aspect, upscale, public: publicProperty } = transcodes[key];

      mappedTranscodes[key] = {
        watermark,
        enabled,
        aspect,
        upscale,
        public: publicProperty,
        padding: padding.replace('#', ''),
      };
    });

    return {
      ...state,
      transcodes: mappedTranscodes,
      preview: mappedPreview,
      thumbnails: { ...thumbnails, storyboard: mappedStoryboard },
    };
  });


