import { FetchResult } from 'src/types/TApiQueries';
import { AssetSchema } from 'src/types/TSchemes';
import { config } from 'src/utils/config';
import { getStandardHeaders, performFetchWithLogging } from '../utils/helpers';

export async function getSchemas(accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assetschemas`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export async function getSchema(accessToken: string, schemaId: number): Promise<FetchResult<AssetSchema>> {
  const url = `${config.hostUrl}/api/v1/assetschemas/${schemaId}`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}
