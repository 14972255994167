import { useSettingsQuery } from 'src/api/queries/userQueries';
import {
  AdminSettingsSection,
  AdminSettingsSidebar,
  Input,
  Paper,
  SaveChangesBar,
  SettingsNavigation,
} from 'src/components';
import styles from './styles.module.scss';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import ReactSwitch from 'react-switch';
import { databaseSettingsAtom } from 'src/shared/settings/database';
import { ACTION_TYPES } from 'src/shared/settings/base';

export const DatabaseSettings = () => {
  const { isLoading } = useSettingsQuery();

  const [databaseSettings, updateDatabaseSettings] = useAtom(databaseSettingsAtom);

  const events = useMemo(() => {
    if (!databaseSettings) return [];

    return Object.entries(databaseSettings.db_export_events).map(([key, value]: [string, any]) => ({
      key,
      enabled: value,
    }));
  }, [databaseSettings]);

  // TODO: Implement a loader state, this will only be called if the user fetches the data from the API.
  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.pageWrapper}>
      <SettingsNavigation />
      <SaveChangesBar />

      <div className={styles.container}>
        <div className={styles.sidebar}>
          <AdminSettingsSidebar
            items={[
              {
                key: 'database',
                label: 'Database',
              },
              {
                key: 'assetEvents',
                label: 'Asset events',
              },
            ]}
          />
        </div>
        <div className={styles.content}>
          <AdminSettingsSection title="Database">
            <Paper>
              <Input
                variant="secondary"
                value={databaseSettings.db_url}
                onChange={(value: string) =>
                  updateDatabaseSettings({ type: ACTION_TYPES.UPDATE_STATE, payload: { db_url: value } })
                }
                placeholder=""
              />
              <p className={styles.sectionDescription}>Database URL for data export. Only MySQL database is supported.</p>
            </Paper>
          </AdminSettingsSection>
          <div className={styles.webhookOptions}>
            <h2>Asset events</h2>
            <p className={styles.sectionDescription}>For Database Export, all events are enabled by default</p>
            {events.map(({ key, enabled }) => (
              // NOTE: For DB, all events are enabled by default.
              <Paper key={key} className={styles.webhookOption}>
                <ReactSwitch
                  checked={true}
                  onChange={() => {}}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#2e53e3"
                  disabled
                />
                <label>{key}</label>
              </Paper>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
