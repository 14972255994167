import { FetchResult } from 'src/types/TApiQueries';
import { config } from 'src/utils/config';
import { getStandardHeaders, performFetchWithLogging } from '../utils/helpers';

export interface SubstitutionsList {
  lang: string;
  domain: string;
  words: {
    [key: string]: string[];
  };
}


export async function getSubstitution(
  accessToken: string,
): Promise<FetchResult<SubstitutionsList>> {
  const url = `${config.hostUrl}/api/v1/addons/subtitles/wordsubstitutions`
  return performFetchWithLogging(url, {
     method: 'GET',
     headers: getStandardHeaders(accessToken),
     credentials: 'include',
   });
}


export type UpdateWordListData = {
  source_id: number;
  name?: string;
  description?: string;
  group_access?: boolean;
};



export async function updateSubstitutionList(
  accessToken: string,
  data: SubstitutionsList,
): Promise<FetchResult<SubstitutionsList>> {

  const url = `${config.hostUrl}/api/v1/addons/subtitles/wordsubstitutions`;
  const payload = JSON.stringify(data);

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}
