import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import styles from './JobStatus.module.scss';
import LoadingBar from '../components/LoadingBar/index';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { getAssetDetails, getJobStatus } from 'src/api/functions/assetsApi';
import { convertISOToCustomFormat, getStatusColor, get_job_map_value } from '../../../utils/common';
import { Gear } from 'src/common/icons';

type HeadingProps = {
  id: string;
  status: string;
  heading: string;
};

type ErrorProps = {
  message: string;
};

type StatusLayoutProps = {
  id: string;
  data: any;
  assetData: any;
};

type InputViewProps = {
  asset_id: string;
  file_name: string;
  content_type: string;
};

type SubHeadingProps = {
  requested_at: string;
  completed_at: string;
  message: any;
};

type StepTabProps = {
  status: string;
  percent: number;
  name: string;
  message: any;
  started_at: string;
  finished_at: string;
};

const LoadingStatus = () => {
  return (
    <React.Fragment>
      <div className={styles.loadingHolder}>
        <h3 className={styles.loading} style={{ fontSize: 20 }}>
          Fetching Job Status
        </h3>
      </div>
    </React.Fragment>
  );
};

const ErrorStatus = ({ message }: ErrorProps) => {
  return (
    <React.Fragment>
      <div className={styles.loadingHolder}>
        <h3 style={{ fontSize: 20 }}>{message}</h3>
      </div>
    </React.Fragment>
  );
};

const Heading = ({ id, status, heading }: HeadingProps) => {
  return (
    <div className={styles.headingContainer}>
      <div className={styles.headingInner}>
        <h3 className={styles.headingTitle}>Job ID - {id}</h3>
        <div className={styles.divider}></div>
        <h3 className={styles.headingId}>{get_job_map_value(heading)}</h3>
      </div>
      <div className={styles.statusContainer} style={{ backgroundColor: getStatusColor(status) }}>
        {status?.toUpperCase() === 'IN_PROGRESS' && <Gear />}
        {get_job_map_value(status?.toUpperCase())}
      </div>
    </div>
  );
};

const SubHeading = ({ requested_at, completed_at, message }: SubHeadingProps) => {
  return (
    <div className={styles.subHeadingContainer}>
      <div className={styles.subHeadingItem}>
        Requested At : <span className={styles.subHeadingText}>{convertISOToCustomFormat(requested_at)}</span>
      </div>
      <div className={styles.subHeadingItem}>
        Completed At : <span className={styles.subHeadingText}>{convertISOToCustomFormat(completed_at)}</span>
      </div>
      {message && (
        <div className={styles.subHeadingItem}>
          Message : <span className={styles.subHeadingText}>{message}</span>
        </div>
      )}
    </div>
  );
};

const ProcessTabs = ({ name, started_at, finished_at, message, status, percent }: StepTabProps) => {
  return (
    <React.Fragment>
      <div className={styles.processTabContainer}>
        <div className={styles.processTabName}>{name}</div>
        <div className={styles.processTabWrapper}>
          <div className={styles.processStatus} style={{ backgroundColor: getStatusColor(status) }}>
            {status?.toUpperCase() === 'IN_PROGRESS' && <Gear />}
            {get_job_map_value(status?.toUpperCase())}
          </div>
          <div className={styles.processDetails}>
            <div className={styles.processDetailItem}>
              Started at : <span className={styles.processDetailText}>{convertISOToCustomFormat(started_at)}</span>
            </div>
            <div className={styles.processDetailItem}>
              Finished at : <span className={styles.processDetailText}>{convertISOToCustomFormat(finished_at)}</span>
            </div>
            {message && (
              <div className={styles.processDetailItem}>
                Message : <span className={styles.processDetailText}>{message}</span>
              </div>
            )}
            {status?.toUpperCase() !== 'FAILED' && <LoadingBar value={percent} />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const StepsTab = ({ name, started_at, finished_at, status, percent, message }: StepTabProps) => {
  return (
    <React.Fragment>
      <div className={styles.stepsTabContainer}>
        <div className={styles.stepsTabWrapper}>
          <div className={styles.stepsStatus} style={{ backgroundColor: getStatusColor(status) }}>
            {status?.toUpperCase() === 'IN_PROGRESS' && <Gear />}
            {get_job_map_value(status?.toUpperCase())}
          </div>
          <div className={styles.stepsTabName}>{name}</div>
          <div className={styles.stepsDetails}>
            <div className={styles.processDetailItem}>
              Started At : <span className={styles.processDetailText}>{convertISOToCustomFormat(started_at)}</span>
            </div>
            <div className={styles.processDetailItem}>
              Finished At : <span className={styles.processDetailText}>{convertISOToCustomFormat(finished_at)}</span>
            </div>
            {message && (
              <div className={styles.processDetailItem}>
                Message : <span className={styles.processDetailText}>{message}</span>
              </div>
            )}
            {status?.toUpperCase() !== 'ERROR' && <LoadingBar value={percent} />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const InputView = ({ asset_id, file_name, content_type }: InputViewProps) => {
  const location = useLocation();
  const history = useHistory();
  const assetPopup = () => {
    history.push(`/asset/${asset_id}`, { background: location });
  };
  return (
    <>
      <div className={styles.inputViewHeader}>INPUT</div>
      <div className={styles.container}>
        <div className={styles.table}>
          <div className="table-content">
            <div className={styles.table_row}>
              <div className={styles.table_data} style={{ maxWidth: '30%', paddingInline: 5 }}>
                Asset ID
              </div>
              <div
                className={styles.table_data}
                onClick={assetPopup}
                style={{ cursor: 'pointer', color: '#003d7a', fontWeight: 'bold' }}
              >
                {asset_id}
              </div>
            </div>
            <div className={styles.table_row}>
              <div className={styles.table_data} style={{ maxWidth: '30%', paddingInline: 5 }}>
                File Name
              </div>
              <div className={styles.table_data}>{file_name}</div>
            </div>
            <div className={styles.table_row}>
              <div className={styles.table_data} style={{ maxWidth: '30%', paddingInline: 5 }}>
                Content Type
              </div>
              <div className={styles.table_data}>{content_type}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const StatusLayout = ({ id, data, assetData }: StatusLayoutProps) => {
  const stepsObjList = { ...data?.job_steps?.addons, ...data?.job_steps?.transcodes };
  return (
    <div className={styles.statusLayoutContainer}>
      <Heading id={id} heading={data?.type} status={data?.status} />
      <SubHeading requested_at={data?.requested_at} completed_at={data?.completed_at} message={data?.message} />
      <div className={styles.statusLayoutContent}>
        <div className={styles.statusLayoutLeft}>
          <div className={styles.leftColumn}>
            <InputView
              asset_id={data?.input?.asset_id || data?.input?.file_id}
              file_name={assetData?.name}
              content_type={data?.input?.content_type}
            />

            <div className={styles.processTabsContainer}>
              {data?.job_steps?.preprocess && (
                <ProcessTabs
                  name="PRE PROCESS"
                  status={data?.job_steps?.preprocess?.status}
                  percent={data?.job_steps?.preprocess?.percent_complete}
                  message={data?.job_steps?.preprocess?.message}
                  started_at={data?.job_steps?.preprocess?.started_at}
                  finished_at={data?.job_steps?.preprocess?.finished_at}
                />
              )}
              {data?.job_steps?.postprocess && (
                <ProcessTabs
                  name="POST PROCESS"
                  status={data?.job_steps?.postprocess?.status}
                  percent={data?.job_steps?.postprocess?.percent_complete}
                  message={data?.job_steps?.postprocess?.message}
                  started_at={data?.job_steps?.postprocess?.started_at}
                  finished_at={data?.job_steps?.postprocess?.finished_at}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.statusLayoutRight}>
          <div className={styles.stepsTabContainer}>
            <div className={styles.stepsHeader}>STEPS</div>
            {Object.keys(stepsObjList).map((k) => {
              return (
                <StepsTab
                  key={k}
                  name={k}
                  percent={stepsObjList[k]?.percent_complete}
                  status={stepsObjList[k]?.status}
                  message={stepsObjList[k]?.message}
                  started_at={stepsObjList[k]?.started_at}
                  finished_at={stepsObjList[k]?.finished_at}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
const JobStatus = () => {
  let { id } = useParams<any>();
  const accessToken = useAccessToken();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [jobData, setJobData] = useState<any>(null);
  const [assetData, setAssetData] = useState<any>(null);

  const intervalRef = useRef<any>(null);

  const getJobData = (fetch_asset_details = true, interval: any) => {
    getJobStatus(id, accessToken)
      .then((job_response: any) => {
        if (job_response.status === 404) {
          setError('Job Not Found !');
          setTimeout(() => {
            window.location.href = '/jobs';
          }, 3000);
        } else {
          if (fetch_asset_details) {
            getAssetDetails(job_response?.input?.asset_id || job_response?.input?.file_id, accessToken).then(
              (asset_response) => {
                setAssetData(asset_response);
                setJobData(job_response);
              },
            );
          } else {
            setJobData(job_response);
          }
        }
      })
      .catch((err) => {
        if (interval) clearInterval(interval);
        setError('Unable to fetch Job Details !');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (jobData) {
      if (jobData?.status === 'COMPLETED' || jobData?.status === 'ERROR') {
        clearInterval(intervalRef.current);
      }
    }
  }, [jobData]);

  useEffect(() => {
    getJobData(true, null);
    intervalRef.current = setInterval(() => {
      getJobData(false, intervalRef?.current);
    }, 5000);
    return () => clearInterval(intervalRef?.current);
  }, [id]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      {!loading ? (
        error ? (
          <ErrorStatus message={error} />
        ) : (
          jobData && assetData && <StatusLayout id={id} data={jobData} assetData={assetData} />
        )
      ) : (
        <LoadingStatus />
      )}
    </div>
  );
};

export default JobStatus;
