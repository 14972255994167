import Button from 'src/components/Button';
import styles from './styles.module.scss';
import cn from 'classnames';

import DialogProps from './types';


/**
 *  Dialog component
 * 
 * @param props 
 * @returns 
 */
const Dialog: React.FC<DialogProps> = (props) => {
  
  return (
    <div className={cn([styles.Dialog])} onClick={() => props.onClick(false)}>
      <div className={styles.box} onClick={(e) => e.stopPropagation()}>
        <h3 className={styles.h3}>
          {props.message}
        </h3>
        <div className={styles.dialogButton}>
          <Button size="small" mode="tertiary" onClick={() => props.onClick(true)}>
            Yes
          </Button>
          <Button size="small" mode="tertiary" onClick={() => props.onClick(false)}>
            No
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Dialog