import { useUpdateAtom } from 'jotai/utils';
import { useSignOut } from 'react-auth-kit';
import { settingsAtom } from 'src/shared/settings';
import { ACTION_TYPES } from 'src/shared/settings/base';

const useLogout = () => {
  const signOut = useSignOut();

  const updateSettings = useUpdateAtom(settingsAtom);

  const logOut = () => {
    //updateSettings({ type: ACTION_TYPES.CLEAR_STATE });
    signOut();
    window.location.reload();
  };

  return logOut;
};

export default useLogout;
