import * as React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Logo from '../Logo';

import styles from './Footer.module.scss';
import messages from './messages';

type FooterProps = {
  className?: string;
};

export const Footer: React.FC<FooterProps> = ({ className, ...rest }) => {
  return (
    <div className={cn([styles.Footer, className])}>
      <span>
        <FormattedMessage {...messages.poweredBy} />
      </span>{' '}
      <Logo />
    </div>
  );
};

export default Footer;
