import * as React from 'react';
import cn from 'classnames';
import styles from './CollectionCard.module.scss';
import messages from './messages';
import { CaretDown, Copy, Eye, FileArrowDown, Stack, TrashSimple, User, UserCircle, Users } from 'src/common/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useDeleteCollectionMutation } from 'src/api/mutations/collectionMutations';
import { useAlerts } from 'src/common/AlertManager';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import { useIntl } from 'react-intl';
import { getCollectionDownloadUrl } from 'src/api/functions/collectionsApi';
import { useAccessToken } from 'src/hooks/useAccessToken';
import Loader from '../Loader';
import { Collection } from 'src/types/TCollection';
import moment from 'moment';
import { getThumbnailUrl } from 'src/utils/previews';
import useModal from 'src/hooks/useModal';
import ConfirmDuplicateCollectionModal from '../ConfirmDuplicateCollectionModal';
import { useAuthUser } from 'react-auth-kit';

type CollectionCardProps = {
  collection: Collection;
  className?: string;
};

export const CollectionCard: React.FC<CollectionCardProps> = ({ className, collection, ...rest }) => {
  const { id, name, assets, last_update, group_access, description } = collection;

  const auth = useAuthUser();
  const history = useHistory();
  const userEmail = auth() ? auth()!.userEmail : '';

  const { showAlert, showSuccess } = useAlerts();
  const { getErrorMessage } = useErrorMessages();
  const { formatMessage } = useIntl();
  const [isPreparingDownload, setIsPreparingDownload] = React.useState(false);
  const accessToken = useAccessToken();
  const { showModal } = useModal();

  const deleteCollectionMutation = useDeleteCollectionMutation(id, {
    onSuccess: () => {
      showSuccess(formatMessage(messages.collectionDeleted));
    },
    onError: (error) => {
      showAlert(getErrorMessage(error.message));
    },
  });

  const goToCollection = () => {
    history.push(`/collection/${id}`);
  };

  const handleDownloadCollection = async () => {
    if (isPreparingDownload) return;

    setIsPreparingDownload(true);
    const url: any = await getCollectionDownloadUrl(id, accessToken);

    if (url.status >= 400) {
      showAlert(`Download not available at the moment. Please try again later.`);
      setIsPreparingDownload(false);
      return;
    }

    window.open(url);
    setIsPreparingDownload(false);
  };

  const handleDeleteCollection = () => deleteCollectionMutation.mutateAsync(undefined);
  const handleDuplicateCollection = () =>
    showModal(
      <ConfirmDuplicateCollectionModal
        title={`Duplicate collection ${name}`}
        sourceId={id}
        name={`${name} (copy)`}
        description={description}
        groupAccess={group_access}
      />,
    );

  return (
    <div className={cn([styles.CollectionCard, className])}>
      <div className={styles.collectionImage}>
        <span className={styles.groupCollection}>
          {group_access ? (
            <>
              <Users />
              <span>Group Collection</span>
            </>
          ) : (
            <>
              <User />
              <span>Private Collection</span>
            </>
          )}
        </span>

        {assets.length === 0 && <Stack className={styles.assetPreview} />}
        {assets.length > 0 && (
          <img
            src={getThumbnailUrl({ width: 300, height: 300, id: assets[0], jwt: accessToken })}
            alt="Asset In Collection"
          />
        )}

        <div className={styles.overlay}>
          <button onClick={goToCollection}>
            <Eye />
            <span>View Collection</span>
          </button>
          <button onClick={handleDownloadCollection} disabled={assets.length === 0}>
            {isPreparingDownload && <Loader />}
            <FileArrowDown />
            <span>Download All</span>

            <CaretDown />
          </button>
          <div className={styles.overlayFooter}>
            <button onClick={handleDuplicateCollection}>
              <Copy />
            </button>
            {userEmail === collection.user_email && (
              <button onClick={handleDeleteCollection}>
                <TrashSimple />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.collectionContent}>
        <div className={styles.collectionTitleSection}>
          <h5>{name} </h5>
          <span>{assets.length}</span>
        </div>
        {description && <p className={styles.description}>{description}</p>}
        <div className={styles.collectionInfoSection}>
          <span>Updated {moment(last_update).fromNow()}</span>
        </div>
        {/** TODO: Implement the user avatars */}
        {false && (
          <div className={styles.collectionUsers}>
            {new Array(5).fill(0).map((_, index) => (
              <UserCircle key={index} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectionCard;
