import { useMutation, UseMutationOptions } from 'react-query';
import { queryFnFetchErrorWrapper } from '../utils/errorHandlers';
import { activateAccount, createToken } from '../functions/accessApi';
import { AuthTokenRequest, AuthTokenResponse, FetchError } from 'src/types/TApiQueries';
import { useAccessToken } from 'src/hooks/useAccessToken';

export function useObtainTokenMutation(
  mutationOptions?: UseMutationOptions<AuthTokenResponse, FetchError, AuthTokenRequest>,
) {
  const mutationFn = queryFnFetchErrorWrapper(({ username, password }: AuthTokenRequest) =>
    createToken(username, password),
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: (result, ...args) => {
      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}
