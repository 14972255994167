import * as React from 'react';

function SvgUserCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z"
        stroke="white"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path d="M16 20a5 5 0 100-10 5 5 0 000 10z" stroke="white" strokeWidth={2} strokeMiterlimit={10} />
      <path
        d="M7.975 24.922a9.004 9.004 0 0116.05 0"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgUserCircle;
