import * as React from 'react';
import cn from 'classnames';

import { X } from 'src/common/icons';

import styles from './Tag.module.scss';
import messages from './messages';

type TagProps = {
  className?: string;
  text: string;
  onClose?: () => void;
};

export const Tag: React.FC<TagProps> = ({ text, onClose, className, ...rest }) => {
  return (
    <div className={cn([styles.Tag, className, { [styles.closeable]: onClose }])}>
      {text}
      {onClose && (
        <span onClick={onClose}>
          <X />
        </span>
      )}
    </div>
  );
};

export default Tag;
