import * as Yup from 'yup';

const PasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old password is required'),
  newPassword: Yup.string().min(7, 'New password must have at least 7 characters').required('New password is required'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .equals([Yup.ref('newPassword')], 'Confirm password must match new password'),
});

export default PasswordSchema;
