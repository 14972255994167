import { defineMessages } from 'react-intl';

export default defineMessages({
	add: {
		id: 'pages.dashboard.add',
		defaultMessage: 'Add Assets',
	},
	saveSearch: {
		id: 'pages.dashboard.saveSearch',
		defaultMessage: 'Save this search',
	},
});
