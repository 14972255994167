import React, { useState, useEffect } from 'react';
import styles from './loading.module.scss';

type LoadingProps = {
  value: number;
};

const LoadingBar = ({ value }: LoadingProps) => {
  const [progressValue, setProgressValue] = useState(1);
  let intervalId: NodeJS.Timeout;

  function update() {
    if (progressValue >= 100) {
      clearInterval(intervalId);
    } else {
      setProgressValue((prevValue) => prevValue + 1);
    }
  }

  useEffect(() => {
    if (value > progressValue) {
      intervalId = setInterval(update, 1);
    } else {
      setProgressValue(value);
    }

    return () => clearInterval(intervalId);
  }, [value, progressValue]);

  return (
    <div className={styles.Progress_Status}>
      <div
        className={styles.myprogressBar}
        style={{
          width: `${progressValue}%`,
          backgroundColor: progressValue >= 100 ? '#34C700' : 'var(--color-prime-400)',
        }}
      >
        <div className={styles.Progress_Text} style={{ color: progressValue > 49 ? '#fff' : '#000' }}>
          {progressValue}%
        </div>
      </div>
    </div>
  );
};

export default LoadingBar;
