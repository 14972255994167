import React, { useState, useMemo, useReducer, useEffect, useRef } from 'react';
import { Gear, MagnifyingGlass } from 'src/common/icons';
import { Button, Input, PageTitle, Pager } from 'src/components';
import styles from './Jobs.module.scss';
import { useAlerts } from 'src/common/AlertManager';
import { convertISOToCustomFormat, getStatusColor, get_job_map_value } from '../../../utils/common';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { getJobs } from 'src/api/functions/assetsApi';
import { CaretRight, CaretDown } from 'src/common/icons';
import cn from 'classnames';
import { SlidersHorizontal } from 'src/common/icons';
import Dropdown from 'src/components/Dropdown';

type StepTabProps = {
  job_id: string;
  status: string;
  name: string;
  message: any;
  started_at: string;
  finished_at: string;
  transcodes: Array<string>;
};

const Jobs = () => {
  const accessToken = useAccessToken();
  const StatusOptions = [
    { key: 'ALL', value: 'ALL' },
    { key: 'COMPLETED', value: 'COMPLETED' },
    { key: 'ERROR', value: 'ERROR' },
    { key: 'QUEUED', value: 'QUEUED' },
    { key: 'PROCESSING', value: 'PROCESSING' },
  ];
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const { showAlert } = useAlerts();

  const filtersSidebarRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('ALL');
  const [startTime, setStartTime] = useState('YYYY-MM-DDThh:mm:ssZ');
  const [endTime, setEndTime] = useState('YYYY-MM-DDThh:mm:ssZ');

  useEffect(() => {
    handlePageChange(1);
  }, []);

  const ProcessIncomingJobs = (j: any) => {
    const incoming_jobs_list = j?.jobs;
    const modified_jobs_list = incoming_jobs_list?.map((job: any) => {
      let mod_transcode: Array<any> = []; // Initialize mod_transcode as an empty array
      if (job?.job_steps?.transcodes) {
        mod_transcode = [...mod_transcode, ...Object.keys(job.job_steps.transcodes)];
      }
      if (job?.job_steps?.addons) {
        // Check for addons separately
        mod_transcode = [...mod_transcode, ...Object.keys(job.job_steps.addons)];
      }
      return { ...job, transcodes: mod_transcode }; // Fix the property name to 'transcodes'
    });
    setJobs(modified_jobs_list);
  };
  const handlePageChange = (page: number) => {
    const limit = itemsPerPage;
    const offset = (page - 1) * itemsPerPage;
    setCurrentPage(page);
    setPageLoading(false);
    FetchJobs(limit, offset, selectedStatus, startTime, endTime);
  };

  const handleItemsPerPageChange = (newLimit: number) => {
    setItemsPerPage(newLimit);
    FetchJobs(newLimit, 0, selectedStatus, startTime, endTime);
  };

  const LoadingStatus = () => {
    return (
      <React.Fragment>
        <div className={styles.loadingHolder}>
          <h3 className={styles.loading} style={{ fontSize: 20 }}>
            Fetching Job Status
          </h3>
        </div>
      </React.Fragment>
    );
  };
  const FoldableFilter: React.FC<{ title: string }> = ({ title, children }) => {
    const [isRevealed, setIsRevealed] = useState(true);
    return (
      <div className={styles.foldableContainer}>
        <h4 onClick={() => setIsRevealed(!isRevealed)}>
          {isRevealed ? <CaretDown /> : <CaretRight />} {title}
        </h4>
        {isRevealed && <div className={styles.filterChildren}>{children}</div>}
      </div>
    );
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const clear_Advanced_search = () => {
    setCurrentPage(1);
    setSelectedStatus('ALL');
    setStartTime('YYYY-MM-DDThh:mm:ssZ');
    setEndTime('YYYY-MM-DDThh:mm:ssZ');
    FetchJobs(itemsPerPage, (currentPage - 1) * itemsPerPage, 'ALL', startTime, endTime);
  };
  const Advanced_search = () => {
    console.log(startTime);
    setCurrentPage(1);
    FetchJobs(itemsPerPage, (currentPage - 1) * itemsPerPage, selectedStatus, startTime, endTime);
  };
  const FetchJobs = (limit: number, offset: number, status: any, start_time: any, end_time: any) => {
    //trigger api call
    getJobs(limit, offset, accessToken, status, start_time, end_time)
      .then((j: any) => {
        setTotalPages(Math.ceil(j?.total / itemsPerPage));
        ProcessIncomingJobs(j);
      })
      .catch((err) => {
        console.log(err);
        showAlert('Error fetching jobs !');
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  const StepsTab = ({ job_id, name, started_at, finished_at, status, message, transcodes }: StepTabProps) => {
    return (
      <React.Fragment>
        <div className={styles.stepsTabContainer} onClick={() => (window.location.href = `/jobs/${job_id}`)}>
          <div className={styles.stepsTabWrapper}>
            <div className={styles.stepsStatus} style={{ backgroundColor: getStatusColor(status) }}>
              {status?.toUpperCase() === 'IN_PROGRESS' && <Gear />}
              {get_job_map_value(status?.toUpperCase())}
            </div>
            <div className={styles.stepsTabName}>{get_job_map_value(name)}</div>

            <div className={styles.stepsDetails}>
              <div className={styles.processDetailItem}>
                Job Id : <span className={styles.processDetailText}>{job_id}</span>
              </div>
              <div className={styles.processDetailItem}>
                Started At : <span className={styles.processDetailText}>{convertISOToCustomFormat(started_at)}</span>
              </div>
              <div className={styles.processDetailItem}>
                Finished At : <span className={styles.processDetailText}>{convertISOToCustomFormat(finished_at)}</span>
              </div>
              {message && (
                <div className={styles.processDetailItem}>
                  Message : <span className={styles.processDetailText}>{message}</span>
                </div>
              )}
              <div className={styles.processDetailItemFlex}>
                Transcodes :{' '}
                <span style={{ display: 'inline-flex', gap: 5, marginBlock: 5 }}>
                  {transcodes.map((t) => {
                    return <span className={styles.processDetailTextChip}>{t}</span>;
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  // Handler for radio button change events
  const handleChange = (key: string) => {
    setSelectedStatus(key);
  };
  const handleStartTimeChange = (e: any) => {
    setStartTime(e.target.value);
  };
  const handleEndTimeChange = (e: any) => {
    setEndTime(e.target.value);
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <div
        style={{
          margin: 20,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexGrow: 1,
          gap: 10,
        }}
      >
        <div>
          <PageTitle title={'Jobs'} className={styles.pageTitle} />
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <Button icon={<SlidersHorizontal />} mode="flat" onClick={toggleVisibility}></Button>
            <div className={styles.searchInput}>
              <Input
                className={styles.inputWrapper}
                placeholder={'Job Id'}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e);
                }}
                icon={<MagnifyingGlass style={{ fontSize: 16 }} />}
              />
            </div>
            <Button
              className={styles.searchBtn}
              size="small"
              onClick={() => {
                if (searchTerm?.trim() !== '' && searchTerm?.length < 32) {
                  window.location.href = `${window.location.pathname}/${searchTerm}`;
                } else {
                  showAlert('Invalid Job Id !');
                }
              }}
            >
              <div style={{ display: 'flex', fontSize: 14 }}>Get Job Status</div>
            </Button>
          </div>
        </div>
        <div className={styles.mainJob}>
          {isVisible && (
            <div ref={filtersSidebarRef} className={styles.pageWrapper} style={{ width: '20%', float: 'left' }}>
              <div className={styles.dropdown}>
                <FoldableFilter title={'Status'}>
                  {StatusOptions ? (
                    <Dropdown
                      className={styles.statusDropdown}
                      isSortDropdown
                      dropdownAnchor="right"
                      onClick={handleChange}
                      options={StatusOptions.map((option) => ({ ...option, noBorderBottom: true }))}
                    >
                      {selectedStatus}
                    </Dropdown>
                  ) : (
                    ''
                  )}
                </FoldableFilter>
                <FoldableFilter title={'Time Range'}>
                  <div className={styles.timeInput}>
                    <input type="datetime-local" value={startTime} onChange={handleStartTimeChange} />
                    <input type="datetime-local" value={endTime} onChange={handleEndTimeChange} />
                  </div>
                </FoldableFilter>
                <br />
              </div>
              <div className={styles.buttons}>
                <Button onClick={Advanced_search}>Search</Button>
                <br />
                <Button onClick={clear_Advanced_search}>Clear</Button>
              </div>
            </div>
          )}
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              marginBlock: 10,
              flexDirection: 'column',
              gap: 10,
            }}
          >
            {pageLoading && <LoadingStatus />}
            {jobs?.length > 0 && !pageLoading && (
              <React.Fragment>
                {jobs.map((job: any, index: number) => (
                  <StepsTab
                    key={index}
                    job_id={job?.job_id}
                    name={job?.type}
                    status={job?.status}
                    message={job?.message}
                    started_at={job?.requested_at}
                    finished_at={job?.completed_at}
                    transcodes={job?.transcodes}
                  />
                ))}
              </React.Fragment>
            )}
            {jobs?.length === 0 && !pageLoading && (
              <React.Fragment>
                <br />
                <div className={styles.loadingHolder}>
                  <h3 style={{ fontSize: 20 }}>No Jobs Found !</h3>
                </div>
              </React.Fragment>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {jobs?.length > 0 && (
              <Pager
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={handlePageChange}
                itemsPerPage={Number(itemsPerPage)}
                onItemsPerPageChange={handleItemsPerPageChange}
                showItemsPerPage={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
