import React, { createContext, useContext } from 'react';

export enum OnboardingActionType {
  GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP',
  GO_TO_PREVIOUS_STEP = 'GO_TO_PREVIOUS_STEP',
  INITIAL_DATA = 'INITIAL_DATA',
}

type OnboardingStep = {
  title: string;
  component: JSX.Element;
  skippable?: boolean;
};

type OnboardingState = {
  currentStep: number;
  steps: OnboardingStep[];
  finishedOnboarding?: boolean;
};

type OnboardingAction = {
  type: OnboardingActionType;
  payload?: any;
};

type OnboardingReducer = (state: OnboardingState, action: OnboardingAction) => OnboardingState;

export const DEFAULT_STATE: OnboardingState = {
  currentStep: 1,
  steps: [],
};

export const reducer: OnboardingReducer = (state, action) => {
  switch (action.type) {
    case OnboardingActionType.GO_TO_NEXT_STEP:
      if (state.currentStep >= state.steps.length) return { ...state, finishedOnboarding: true };
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case OnboardingActionType.GO_TO_PREVIOUS_STEP:
      if (state.currentStep <= 1) return state;
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case OnboardingActionType.INITIAL_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const OnboardingContext = createContext<[OnboardingState, React.Dispatch<OnboardingAction>]>([
  DEFAULT_STATE,
  () => {},
]);

export const useOnboardingContext = () => {
  const [{ currentStep, steps }, dispatch] = useContext(OnboardingContext);

  const stepTitle = steps[currentStep - 1]?.title;
  const totalSteps = steps.length;

  const isLastStep = currentStep === totalSteps;
  const isFirstStep = currentStep === 1;
  const skippable = steps[currentStep - 1]?.skippable ?? false;

  const goToNextStep = () => dispatch({ type: OnboardingActionType.GO_TO_NEXT_STEP });
  const goToPreviousStep = () => dispatch({ type: OnboardingActionType.GO_TO_PREVIOUS_STEP });

  return {
    currentStep,
    stepTitle,
    totalSteps,
    goToNextStep,
    goToPreviousStep,
    isLastStep,
    isFirstStep,
    skippable,
  };
};
