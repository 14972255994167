import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './ColorPicker.module.scss';
import messages from './messages';
import { ChromePicker, ColorChangeHandler } from 'react-color';
import { Portal } from 'src/common/Portal';

type ColorPickerProps = {
  value?: string;
  className?: string;
  onChange?: (color: string) => void;
};

export const ColorPicker: React.FC<ColorPickerProps> = ({ value = '#000000', className, onChange }) => {
  const [color, setColor] = useState(value);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const colorPreviewRef = useRef<HTMLSpanElement>(null);
  const colorInputRef = useRef<HTMLDivElement>(null);
  const colorPickerRef = useRef<HTMLDivElement>(null);

  const handleOnChangeComplete: ColorChangeHandler = (newColor) => {
    setColor(newColor.hex);
    onChange?.(newColor.hex);
  };

  useEffect(() => {
    if (!colorPreviewRef.current) return;

    colorPreviewRef.current.style.backgroundColor = color;
  }, [color]);

  useEffect(() => {
    setColor(value);
  }, [value]);

  useEffect(() => {
    if (!showColorPicker || !colorInputRef.current || !colorPickerRef.current) return;

    const { offsetTop, offsetLeft } = colorInputRef.current;

    colorPickerRef.current.style.top = `${offsetTop + colorInputRef.current.offsetHeight}px`;
    colorPickerRef.current.style.left = `${offsetLeft}px`;
  }, [showColorPicker, colorInputRef, colorPickerRef]);

  return (
    <div ref={colorInputRef} className={cn([styles.ColorPicker, className])}>
      <div className={styles.colorInput} onClick={() => setShowColorPicker((state) => !state)}>
        <span ref={colorPreviewRef} className={styles.colorPreview}></span>
        <input type="text" value={color} readOnly />
      </div>
      {showColorPicker &&
        Portal({
          children: (
            <div ref={colorPickerRef} className={styles.picker}>
              <div className={styles.cover} onClick={() => setShowColorPicker(false)} />
              <ChromePicker color={color} onChange={handleOnChangeComplete} />
            </div>
          ),
        })}
    </div>
  );
};

export default ColorPicker;
