export const humanFileSize = (bytes: number) => {
  let u,
    b = bytes,
    t = 1000;
  // eslint-disable-next-line
  ['', 'K', ...'MGTPEZY'].find((x) => ((u = x), (b /= t), b ** 2 < 1));
  return `${u ? (t * b).toFixed(1) : bytes} ${u}B`;
};

const timeScalars = [1000, 60, 60, 24];
const timeUnits = ['ms', 's', 'm', 'h'];

export const humanDuration = (miliseconds: number) => {
  let timeScalarIndex = 0,
    scaledTime = miliseconds;

  while (scaledTime > timeScalars[timeScalarIndex]) {
    scaledTime /= timeScalars[timeScalarIndex++];
  }

  return `${scaledTime.toFixed(0)} ${timeUnits[timeScalarIndex]}`;
};
