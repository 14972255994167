import { FormattedMessage, useIntl } from 'react-intl';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useSignIn, useIsAuthenticated } from 'react-auth-kit';
import { Redirect } from 'react-router-dom';
import { Button, Checkbox, Input, Logo } from 'src/components';
import { useObtainTokenMutation } from 'src/api/mutations/accessMutations';
import { useAlerts } from 'src/common/AlertManager';
import { AuthTokenResponse } from 'src/types/TApiQueries';
import styles from './styles.module.scss';
import messages from './messages';
import { useAtomValue } from 'jotai/utils';
import { securitySettingsAtom } from 'src/shared/settings/security';
import { config } from 'src/utils/config';

export const Login = () => {
  const { formatMessage } = useIntl();
  const signIn = useSignIn();
  const { showAlert } = useAlerts();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [checked, setChecked] = useState(false);
  const [formData, setFormData] = useState({ email: '', password: '' });

  // check if user already logged in, try refresh token

  const securitySettings = useAtomValue(securitySettingsAtom);

  const obtainTokenMutation = useObtainTokenMutation({
    onSuccess: (result) => {
      setTokenOrThrow(result);
    },
  });

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      await obtainTokenMutation.mutateAsync({
        username: formData.email,
        password: formData.password,
      });
    } catch (error) {
      showAlert('Email and password do not match, please try again');
    }
  };

  const setTokenOrThrow = ({
    jwt,
    accessID,
    notifications,
    permissions,
    preferences,
    role_id,
    role_name,
    uploadkey,
    username,
    user_email,
    user_id,
  }: AuthTokenResponse) => {
    if (
      signIn({
        token: jwt,
        expiresIn: 86400,
        tokenType: 'Bearer',
        authState: {
          isLoggedIn: true,
          accessId: accessID,
          uploadkey,
          role: role_name,
          roleId: role_id,
          permissions,
          notifications,
          preferences,
          username,
          userEmail: user_email,
          userId: user_id,
        },
      })
    ) {
      setIsLoggedIn(true);
    } else {
      showAlert('Error while obtaining tokens, please try again');
    }
  };

  if (isLoggedIn) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <div className={styles.formWrapper}>
      <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.logoContainer}>
          <Logo linkable={false} />
        </div>
        <Input
          label={formatMessage(messages.email)}
          placeholder=""
          onChange={(newVal) => setFormData({ ...formData, email: newVal })}
        />
        <Input
          label={formatMessage(messages.password)}
          type="password"
          placeholder=""
          onChange={(newVal) => setFormData({ ...formData, password: newVal })}
        />
        <div className={styles.splitWrapper}>
          <Checkbox
            label={formatMessage(messages.rememberMe)}
            checked={checked}
            onChange={() => setChecked((e) => !e)}
          />
          <a href={`${config.hostUrl}/usersecurity/forgotpassword`}>
            <FormattedMessage {...messages.forgotPassword} />
          </a>
        </div>
        <div className={styles.buttonWrapper}>
          <Button type="submit" size="small" loading={obtainTokenMutation.isLoading}>
            <FormattedMessage {...messages.signIn} />
          </Button>
        </div>

        <div className={styles.termsAndPolicyContainer}>
          <a href="https://filespin.io/terms">Terms of Service</a>
          <span>|</span>
          <a href="https://filespin.io/privacy/">Privacy Policy</a>
        </div>
      </form>
    </div>
  );
};
