import { atom, Setter, WritableAtom } from 'jotai';
import { capitalize, merge } from 'lodash';

type CreateAtomActions = {
  updater: (currentAtom: any, updatedAtom: any, get: any, set: Setter, action: any) => void;
  setter?: (atom: any, set: Setter, action: any) => void;
};

type CreateAtomParams = (
  name: string,
  actions: CreateAtomActions,
) => {
  [x: string]: WritableAtom<any, any, void>;
};

const combinedGetter = (currentAtom: any, updatedAtom: any, get: any) => {
  const currentState = get(currentAtom);
  const updatedState = get(updatedAtom);

  if (!updatedState) {
    return currentState;
  }

  const mergedState = merge({}, currentState, updatedState);

  //return { ...mergedState, updatedAt: Date.now() };
  return mergedState;
};

export const ACTION_TYPES = {
  SET_STATE: 'setState',
  SAVE_CHANGES: 'saveChanges',
  UPDATE_STATE: 'updateState',
  UNDO_CHANGES: 'undoChanges',
  CLEAR_STATE: 'clearState',
};

type ActionCallback = (currentAtom: any, updatedAtom: any, get: any, set: Setter) => void;

const setState = (atom: any, set: Setter, action: any) => set(atom, action.payload);
const undoChanges = (atom: any, set: Setter) => set(atom, undefined);
const saveChanges: ActionCallback = (currentAtom, updatedAtom, get, set) => {
  const initialState = get(currentAtom);
  const updatedState = get(updatedAtom);

  const mergedState = merge({}, initialState, updatedState);

  set(currentAtom, mergedState);
  set(updatedAtom, undefined);
};

export const createAtom: CreateAtomParams = (name, { updater, setter }) => {
  const currentAtom = atom<any | undefined>(undefined);

  const updatedAtom = atom<any | undefined>(undefined);

  const combinedAtom = atom<any, any>(
    (get) => combinedGetter(currentAtom, updatedAtom, get),
    (get, set, action) => {
      const { type } = action;

      switch (type) {
        case ACTION_TYPES.SET_STATE:
          if (setter) {
            setter(currentAtom, set, action);
          } else {
            setState(currentAtom, set, action);
          }
          break;
        case ACTION_TYPES.SAVE_CHANGES:
          saveChanges(currentAtom, updatedAtom, get, set);
          break;
        case ACTION_TYPES.UPDATE_STATE:
          updater(currentAtom, updatedAtom, get, set, action);
          break;
        case ACTION_TYPES.UNDO_CHANGES:
          undoChanges(updatedAtom, set);
          break;
        case ACTION_TYPES.CLEAR_STATE:
          set(currentAtom, undefined);
          break;
        default:
          break;
      }
    },
  );

  return {
    [`current${capitalize(name)}SettingsAtom`]: currentAtom,
    [`updated${capitalize(name)}SettingsAtom`]: updatedAtom,
    [`${name}SettingsAtom`]: combinedAtom,
  };
};
