import * as React from 'react';
import cn from 'classnames';
import styles from './LayoutViewSwitcher.module.scss';
import messages from './messages';
import Button from '../Button';
import { ListBullets, SquaresFour } from 'src/common/icons';
import { useMemo, useReducer } from 'react';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import useLayoutSwitcher from 'src/hooks/useLayoutSwitcher';

type LayoutViewSwitcherProps = {
  className?: string;
  disabled?: boolean;
};

export const LayoutViewSwitcher: React.FC<LayoutViewSwitcherProps> = ({ disabled = false, className, ...rest }) => {
  const { toggleLayoutView, layoutView, isLayoutList } = useLayoutSwitcher();

  const layoutIcon = isLayoutList ? <ListBullets /> : <SquaresFour />;

  return (
    <Button
      size="small"
      mode="tertiary"
      className={cn(styles.LayoutViewSwitcher, className)}
      onClick={toggleLayoutView}
      icon={layoutIcon}
      disabled={disabled}
    />
  );
};

export default LayoutViewSwitcher;
