import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { queryFnFetchErrorWrapper, throwFetchError } from 'src/api/utils/errorHandlers';
import { mergeQueryOptions } from 'src/api/utils/helpers';
import { queryNames } from 'src/api/utils/queryNames';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { CustomQueryOptions, FetchError, DashboardFilters } from 'src/types/TApiQueries';
import { getSearch, getSearchByHash, getAssetDetails, getAssetVisionTags } from '../functions/assetsApi';
import { getSubstitution } from '../functions/toolsApi';
import { isValidSort } from '../utils/search';
import { updateSubstitutionList, SubstitutionsList } from 'src/api/functions/toolsApi';


export function useSubstitutionsQuery(
    options?: CustomQueryOptions,
    queryOptions?: UseQueryOptions<any, FetchError>,
  ) {
    const actualQueryOptions = mergeQueryOptions(options, queryOptions);
    const accessToken = useAccessToken();
  
    const queryFn = useMemo(
      () => queryFnFetchErrorWrapper(() => getSubstitution(accessToken)),
      [accessToken],
    );
  
    const query = useQuery<any, FetchError>([queryNames.substitution], queryFn, actualQueryOptions);
  
    if (options?.handleErrors && query.status === 'error') {
      if (query.isRefetchError) {
        Sentry.captureMessage('Failed refetching substitution', Sentry.Severity.Error);
      } else {
        throwFetchError(query.error);
      }
    }
  
    return query;
}