import { ErrorResponseResult, FetchError, FetchResult } from 'src/types/TApiQueries';

export function queryFnFetchErrorWrapper<TData, TParams extends any[] = unknown[]>(
  fetchFn: (...params: TParams) => Promise<FetchResult<TData>>,
) {
  return async function (...params: Parameters<typeof fetchFn>) {
    try {
      const result = await fetchFn(...params);

      if (isErrorResponseResult(result)) {
        if (result.status === 401 && window.location.pathname !== '/login') {
          localStorage.removeItem('fs.auth.');
          window.location.reload();
        }
        throw result;
      }

      return result;
    } catch (error) {
      if (error instanceof Error || isErrorResponseResult(error)) {
        throw error;
      } else {
        throw new Error('unknown api error');
      }
    }
  };
}

export function isErrorResponseResult<TData>(fetchResult: FetchResult<any>): fetchResult is ErrorResponseResult {
  return fetchResult.hasOwnProperty('error') && fetchResult.hasOwnProperty('status');
}

export function throwFetchError(error: FetchError | null) {
  if (isErrorResponseResult(error)) {
    throw new Error(error.message);
  }
  throw error;
}
