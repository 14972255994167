import * as React from 'react';
import cn from 'classnames';
import styles from './InfoSection.module.scss';
import messages from './messages';
import InfoIcon from 'src/common/icons/Info';

type InfoSectionProps = {
  className?: string;
  text: string;
};

export const InfoSection: React.FC<InfoSectionProps> = ({ text, className, ...rest }) => {
  return (
    <div className={cn([styles.InfoSection, className])}>
      <InfoIcon fontSize={24} className={styles.icon} />
      <p>{text}</p>
    </div>
  );
};

export default InfoSection;
