import * as React from 'react';

function SvgVideoCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M2.25 5.625h12a3 3 0 013 3v9a.75.75 0 01-.75.75h-12a3 3 0 01-3-3v-9a.75.75 0 01.75-.75v0zM17.25 10.5l5.25-3v9l-5.25-3"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgVideoCamera;
