import { useSettingsQuery } from 'src/api/queries/userQueries';
import { AdminSettingsSection, AdminSettingsSidebar, Paper, SaveChangesBar, SettingsNavigation } from 'src/components';

import styles from './styles.module.scss';
import { useAtom } from 'jotai';
import ReactSwitch from 'react-switch';
import { CustomImageConversions } from './components/CustomImageConversions';
import { Watermark } from './components/Watermark';
import { imageSettingsAtom } from 'src/shared/settings/image';
import { ACTION_TYPES } from 'src/shared/settings/base';

export const ImageSettings = () => {
  const { isLoading } = useSettingsQuery();

  const [imageSettings, updateImageSettings] = useAtom(imageSettingsAtom);

  const handleChange = (value: boolean) =>
    updateImageSettings({ type: ACTION_TYPES.UPDATE_STATE, payload: { on_demand_image_private: value } });

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.pageWrapper}>
      <SettingsNavigation />
      <SaveChangesBar />

      <div className={styles.container}>
        <div className={styles.sidebar}>
          <AdminSettingsSidebar
            items={[
              {
                key: 'onDemandImages',
                label: 'On-demand images',
              },
              {
                key: 'customImageConversions',
                label: 'Custom image conversions',
              },
              {
                key: 'watermark',
                label: 'Watermark',
              },
            ]}
          />
        </div>
        <div className={styles.content}>
          <AdminSettingsSection title="On-demand images">
            <Paper className={styles.onDemandImages}>
              <ReactSwitch
                checked={imageSettings.on_demand_image_private}
                onChange={handleChange}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor="#2e53e3"
              />

              <p>Make On-demand images private</p>
            </Paper>
          </AdminSettingsSection>

          <CustomImageConversions />
          <AdminSettingsSection title="Watermark" className={styles.section}>
            <Watermark />
          </AdminSettingsSection>
        </div>
      </div>
    </div>
  );
};
