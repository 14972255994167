import * as React from 'react';
import cn from 'classnames';
import styles from './NavBar.module.scss';
import messages from './messages';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Bell, CaretDown, Home, Settings, UserCircle } from 'src/common/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import SvgUserCircle from 'src/common/icons/UserCircle';
import Dropdown from '../Dropdown';
import { useAuthUser } from 'react-auth-kit';
import useRoleManager from 'src/hooks/useRoleManager';
//import SvgLogoIcon from 'src/common/icons/LogoIcon';
import LogoWhite from '../LogoWhite';
import useLogout from 'src/hooks/useLogout';
import Notifications from '../Notifications';
import useModel from 'src/hooks/useModal';
import { ReprocessModal } from './ReprocessModal';
import { GetLinksModal } from './GetLinksModal';
import { BatchCdnModal } from './BatchCdnModal';


type AdminNavBarProps = {
  className?: string;
};

const DropDownTemplate = () => {
  return (
    <div className={styles.dropdown_template}>
      Tools
      <CaretDown className={styles.dropdown_icon} />
    </div>
  );
};

export const AdminNavBar: React.FC<AdminNavBarProps> = ({ className }) => {
  const { formatMessage } = useIntl();
  const auth = useAuthUser();
  const history = useHistory();
  const logOut = useLogout();
  const username = auth() ? auth()!.username : '';

  const { isAdmin } = useRoleManager();
  const { isManager } = useRoleManager();
  const { showModal } = useModel();

  const getToolsOptions = () => {
    const options = [];
    options.push({
      key: 'getlink',
      value: 'Get Asset Links',
      noBorderBottom: false,
    });
    if (isAdmin || isManager) {
      options.push({
        key: 'cdn',
        value: 'CDN Prefetch/Purge',
        noBorderBottom: false,
      });
    }
    if(isAdmin){
       options.push({
        key: 'usage',
        value: 'Check Usage',
        noBorderBottom:false
       })
    }
    if (isAdmin) {
      options.push({
        key: 'reprocess',
        value: 'Reprocess Assets',
        noBorderBottom: false,
      });
    }
    return options;
  };

  const handleUserMenuClick = (key: string) => {
    switch (key) {
      case 'me':
      case 'settings':
        history.push(`/${key}`);
        break;
      case 'logout':
        logOut();
        break;
      default:
        throw new Error(`Unknown navigation key: ${key}`);
    }
  };

  const handleToolClick = (key: string) => {
    switch (key) {
      case 'reprocess':
        showModal(<ReprocessModal />);
        break;
      case 'getlink':
        showModal(<GetLinksModal />);
        break;
      case 'cdn':
        showModal(<BatchCdnModal />);
        break
      case 'usage':
        history.push("/usage")
        break;
      default:
        throw new Error(`Unknown navigation key: ${key}`);
    }
  };

  return (
    <nav className={cn([styles.AdminNavBar, className])}>
      <div className={styles.navRoutes}>
        {/*}
        <SvgLogoIcon className={styles.logoIcon} />
        */}
        <LogoWhite />
        <NavLink to={'/assets'} activeClassName={styles.active}>
          <span>
            <FormattedMessage {...messages.assets} />
          </span>
        </NavLink>
        <NavLink to={`/collections`} activeClassName={styles.active}>
          <FormattedMessage {...messages.collections} />
        </NavLink>
        {/*<NavLink to={`/asset-transfers`} activeClassName={styles.active}>
          <FormattedMessage {...messages.assetTransfers} />
  </NavLink>*/}
        {(isAdmin || isManager) && (
          <>
            <NavLink to={'/admin/settings'} activeClassName={styles.active}>
              <span>
                <FormattedMessage {...messages.settings} />
              </span>
            </NavLink>
          </>
        )}
        
        {isAdmin && (
          <>
            <NavLink to={'/admin/users'} activeClassName={styles.active}>
              <span>
                <FormattedMessage {...messages.users} />
              </span>
            </NavLink>
          </>
        )}
        
        {(isAdmin || isManager) && (
        <>
          <NavLink to={'/jobs'} activeClassName={styles.active}>
            <span>
              <FormattedMessage {...messages.jobs} />
            </span>
          </NavLink>
        </>
        )}

       
        <Dropdown
          dropdownAnchor="right"
          dropdownDirection="down"
          icon={<DropDownTemplate />}
          onClick={handleToolClick}
          options={getToolsOptions()}
          className={styles.dropdown}
        />
      </div>

      <div className={styles.navActions}>
        {/*<Link to={`/settings`}>
          <Settings />
        </Link>*/}
        <Notifications />
        <Dropdown
          dropdownAnchor="right"
          dropdownDirection="down"
          icon={<UserCircle fontSize={22} />}
          onClick={handleUserMenuClick}
          options={[
            {
              key: 'me',
              value: username,
              noBorderBottom: false,
            },
            /*{
              key: 'settings',
              value: formatMessage(messages.preferences),
              noBorderBottom: true,
            },*/
            {
              key: 'logout',
              value: formatMessage(messages.logout),
              noBorderBottom: true,
            },
          ]}
          className={styles.dropdown}
        />
      </div>
    </nav>
  );
};

export default AdminNavBar;
