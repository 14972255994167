import React, { useEffect, useMemo, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { useEditUserManagementMutation } from 'src/api/mutations/userManagementMutation';
import { useUserQuery } from 'src/api/queries/userManagementQueries';
import { useAlerts } from 'src/common/AlertManager';
import { CaretDown, CaretRight, Panel, Wrench } from 'src/common/icons';
import { Button, Input, Loader, PageTitle, Paper } from 'src/components';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import useForm from 'src/hooks/useForm';
import PasswordSchema from 'src/schemas/password';
import UserSchema from 'src/schemas/user';
import { ValidationError } from 'yup';
import messages from './messages';

import styles from './styles.module.scss';

const FoldableSection: React.FC<{ title: string }> = ({ title, children }) => {
  const [isRevealed, setIsRevealed] = useState(true);
  return (
    <div className={styles.foldableContainer}>
      <h4 onClick={() => setIsRevealed(!isRevealed)}>
        {isRevealed ? <CaretDown /> : <CaretRight />} {title}
      </h4>
      {isRevealed && <div>{children}</div>}
    </div>
  );
};

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const auth = useAuthUser();
  const authenticatedUser = auth();
  const { formatMessage } = useIntl();

  const { showSuccess, showAlert } = useAlerts();

  const { data: userData, isLoading } = useUserQuery(authenticatedUser?.userId);

  const { getErrorMessage } = useErrorMessages();

  const { form, setForm, handleChange, formErrors, clearFormErrors, setFieldError } = useForm({
    email: '',
    firstName: '',
    lastName: '',
  });

  const {
    form: passwordForm,
    handleChange: handlePasswordChange,
    formErrors: passwordFormErrors,
    setFieldError: setPasswordFieldError,
    clearFormErrors: clearPasswordFormErrors,
  } = useForm({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const editUserMutation = useEditUserManagementMutation(authenticatedUser?.userId, {
    onSuccess: () => {
      setIsEditing(false);

      showSuccess(formatMessage(messages.success));
    },
    onError: (error) => {
      showAlert(getErrorMessage(error.message));
    },
  });

  const hasChanges = useMemo(() => {
    if (!userData) return false;

    return (
      form.email !== userData.data.email ||
      form.firstName !== userData.data.first_name ||
      form.lastName !== userData.data.last_name
    );
  }, [userData, form]);

  const handleSaveChanges = () => {
    clearFormErrors();
    try {
      UserSchema.validateSync(form, { abortEarly: false });

      editUserMutation.mutateAsync({
        email: form.email,
        first_name: form.firstName,
        last_name: form.lastName,
      });
    } catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach((err) => {
          if (!err.path) return;

          setFieldError(err.path, err.message);
        });
      }
    }
  };

  const handleSavePassword = () => {
    clearPasswordFormErrors();
    try {
      PasswordSchema.validateSync(passwordForm, { abortEarly: false });

      editUserMutation.mutateAsync({
        old_password: passwordForm.oldPassword,
        new_password: passwordForm.newPassword,
      });
    } catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach((err) => {
          if (!err.path) return;

          setPasswordFieldError(err.path, err.message);
        });
      }
    }
  };

  useEffect(() => {
    if (!userData || !userData.data) return;

    setForm({
      email: userData.data.email,
      firstName: userData.data.first_name,
      lastName: userData.data.last_name,
    });
  }, [userData]);

  if (isLoading) return <Loader />;

  return (
    <section className={styles.pageWrapper}>
      <PageTitle
        title="Profile"
        rightContainer={
          isEditing ? (
            <div className={styles.groupButtons}>
              <Button size="small" mode="tertiary" onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
              <Button
                size="small"
                onClick={handleSaveChanges}
                loading={editUserMutation.isLoading}
                disabled={!hasChanges || editUserMutation.isLoading}
              >
                Save Changes
              </Button>
            </div>
          ) : (
            <Button icon={<Wrench />} size="small" onClick={() => setIsEditing(true)}>
              Edit Profile
            </Button>
          )
        }
      />

      <div className={styles.container}>
        <Paper>
          <div className={styles.userName}>
            <Input
              label="First Name"
              variant="secondary"
              disabled={!isEditing}
              value={form.firstName}
              onChange={(value) => handleChange('firstName', value)}
              error={formErrors.firstName}
            />
            <Input
              label="Last Name"
              variant="secondary"
              disabled={!isEditing}
              value={form.lastName}
              onChange={(value) => handleChange('lastName', value)}
              error={formErrors.lastName}
            />
          </div>
          <Input
            label="Email"
            variant="secondary"
            disabled={!isEditing}
            value={form.email}
            onChange={(value) => handleChange('email', value)}
            error={formErrors.email}
          />
        </Paper>

        <Paper>
          <FoldableSection title="Update Password">
            <Input
              label="Current Password"
              variant="secondary"
              type="password"
              value={passwordForm.oldPassword}
              onChange={(value) => handlePasswordChange('oldPassword', value)}
              error={passwordFormErrors.oldPassword}
            />
            <Input
              label="New Password"
              variant="secondary"
              type="password"
              value={passwordForm.newPassword}
              onChange={(value) => handlePasswordChange('newPassword', value)}
              error={passwordFormErrors.newPassword}
            />
            <Input
              label="Confirm Password"
              variant="secondary"
              type="password"
              value={passwordForm.confirmPassword}
              onChange={(value) => handlePasswordChange('confirmPassword', value)}
              error={passwordFormErrors.confirmPassword}
            />

            <div className={styles.actions}>
              <Button
                size="small"
                onClick={handleSavePassword}
                loading={editUserMutation.isLoading}
                disabled={editUserMutation.isLoading}
              >
                Save Password
              </Button>
            </div>
          </FoldableSection>
        </Paper>
      </div>
    </section>
  );
};

export default Profile;
