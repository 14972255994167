import * as React from 'react';
import cn from 'classnames';

import { Footer, NavBar } from 'src/components';

import styles from './styles.module.scss';

export const DefaultLayout: React.FC<{}> = ({ children, ...rest }) => {


  return (
    <div className={styles.layout}>
      <div className={styles.sticky}>
        <NavBar />
      </div>
      {children}
      <Footer />
    </div>
  );
};

export default DefaultLayout;
