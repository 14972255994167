import * as React from 'react';

function SvgMusicNotes(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M16.875 18.75a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25zM4.875 21.75a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25zM19.5 7.5l-12 3"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 19.125V6l12-3v13.125"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgMusicNotes;
