import { useMemo } from 'react';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { queryFnFetchErrorWrapper } from '../utils/errorHandlers';
import { AuthTokenRequest, AuthTokenResponse, FetchError } from 'src/types/TApiQueries';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { updateAssetMetadata } from 'src/api/functions/assetsApi';
import { queryNames } from 'src/api/utils/queryNames';

export function useAssetMetadataMutation(assetId: string, mutationOptions?: UseMutationOptions<any, FetchError, any>) {
  const queryClient = useQueryClient();
  const accessToken = useAccessToken();

  const mutationFn = useMemo(
    () => queryFnFetchErrorWrapper((data: any) => updateAssetMetadata(assetId, data, accessToken)),
    [assetId, accessToken],
  );

  return useMutation(mutationFn, {
    ...mutationOptions,
    onSuccess: async (result, ...args) => {
      // TODO: Instead of revalidate - use local cache to store the metadata
      // await queryClient.invalidateQueries([queryNames.asset, assetId]);
      mutationOptions?.onSuccess?.(result, ...args);
    },
  });
}
