import * as React from 'react';
import cn from 'classnames';
import styles from './Badge.module.scss';
import messages from './messages';

type BadgeProps = {
  className?: string;
};

export const Badge: React.FC<BadgeProps> = ({ children, className, ...rest }) => {
  return <span className={cn([styles.Badge, className])}>{children}</span>;
};

export default Badge;
