import { defineMessages } from 'react-intl';

export default defineMessages({
  storage: {
    id: 'component.settingsNavigation.storage',
    defaultMessage: 'Storage',
  },
  security: {
    id: 'component.settingsNavigation.security',
    defaultMessage: 'Security',
  },
  image: {
    id: 'component.settingsNavigation.image',
    defaultMessage: 'Image',
  },
  video: {
    id: 'component.settingsNavigation.video',
    defaultMessage: 'Video',
  },
  webhook: {
    id: 'component.settingsNavigation.webhook',
    defaultMessage: 'Webhook',
  },
  database: {
    id: 'component.settingsNavigation.database',
    defaultMessage: 'Database',
  },
  addons: {
    id: 'component.settingsNavigation.addons',
    defaultMessage: 'Add-ons',
  },
  tools: {
    id: 'component.settingsNavigation.addons',
    defaultMessage: 'Tools',
  },
  saveTemplate: {
    id: 'component.settingsNavigation.saveTemplate',
    defaultMessage: 'Save Settings as Template',
  },
});
