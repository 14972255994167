import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { CustomQueryOptions, FetchError } from 'src/types/TApiQueries';
import { getAllNotifications } from '../functions/notificationsApi';
import { queryFnFetchErrorWrapper, throwFetchError } from '../utils/errorHandlers';
import { mergeQueryOptions } from '../utils/helpers';
import { queryNames } from '../utils/queryNames';

export const useAllNotificationsQuery = (
  options?: CustomQueryOptions,
  queryOptions?: UseQueryOptions<any[], FetchError>,
) => {
/*
  return {
    isLoading:false,
    refetch: () => {},
    data: {
      notifications: [],
    },
    isRefetching:false,
  }
*/
  const actualQueryOptions = mergeQueryOptions(options, queryOptions);
  const accessToken = useAccessToken();

  const queryFn = useMemo(() => queryFnFetchErrorWrapper(() => getAllNotifications(accessToken)), [accessToken]);

  const query = useQuery<any, FetchError>([queryNames.notification, 'all'], queryFn, actualQueryOptions);



  if (options?.handleErrors && query.status === 'error') {
    if (query.isRefetchError) {
      Sentry.captureMessage('Failed refetching notifications', Sentry.Severity.Error);
    } else {
      throwFetchError(query.error);
    }
  }

  return query;

};
