import { UseQueryOptions } from 'react-query';
import { CustomQueryOptions, FetchError, FetchResult } from 'src/types/TApiQueries';
import { addSentryApiBreadcrumb } from 'src/utils/sentry';
import { isErrorResponseResult } from './errorHandlers';

type ResponseTransformer = 'json' | 'blob' | 'text';

const refetchInterval = 1 * 60 * 60 * 1000; // one minute

export async function getFetchResult<TData>(
  response: Response,
  transformer?: ResponseTransformer,
): Promise<FetchResult<TData>> {
  if (response.ok) {
    if (response.status === 202 || response.status === 204) return response as any;
    if (transformer === 'blob') return (await response.blob()) as any;
    if (transformer === 'text') return (await response.text()) as any;
    return await response.json();
  } else {
    const contentType = response.headers.get('content-type');
    let error = 'Unknown Error';
    if (contentType && contentType.includes('application/json')) {
      error = await response.json();
    }
    const message = `Request to "${response.url}" failed with ${response.status}`;
    return { message, error, status: response.status, url: response.url, statusText: response.statusText };
  }
}

export async function performFetchWithLogging<TData>(
  url: string,
  requestInit?: RequestInit,
  responseTransformer?: ResponseTransformer,
): Promise<FetchResult<TData>> {
  try {
    const response = await fetch(url, requestInit);
    const result = await getFetchResult<TData>(response, responseTransformer);

    addSentryApiBreadcrumb({
      url,
      response,
      errorResult: isErrorResponseResult(result) ? result : undefined,
      requestInit,
    });

    return result;
  } catch (error) {
    addSentryApiBreadcrumb({
      url,
      requestInit,
      error: error as Error,
    });
    throw error;
  }
}

export function getQueryString({ url, query = {} }: { url: string; query?: {} }): string {
  let composedUrl = new URL(url);
  let params = Object.entries(query)
    .filter(([k, v]) => v !== null && v !== '' && typeof v !== 'undefined')
    .map(([k, v]) => [String(k), String(v)]);
  composedUrl.search = new URLSearchParams(params).toString();

  return composedUrl.toString();
}

export function getStandardHeaders(accessToken?: string) {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (accessToken) {
    return {
      ...headers,
      Authorization: accessToken,
    };
  }

  return headers;
}

export function getUploadHeaders(accessToken?: string) {
  const headers: HeadersInit = {
    Accept: 'application/json',
  };

  if (accessToken) {
    return {
      ...headers,
      Authorization: accessToken,
    };
  }

  return headers;
}

export function mergeQueryOptions<T>(customOptions?: CustomQueryOptions, options?: UseQueryOptions<T, FetchError>) {
  const queryOptions = {
    ...options,
    ...customOptions,
  };

  if (customOptions?.shouldRefetch) {
    queryOptions.refetchInterval = refetchInterval;
  }

  return queryOptions;
}
