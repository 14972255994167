import * as React from 'react';

function SvgFolderPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      {...props}
    >
      <path
        d="M28 11v14.111a.89.89 0 01-.889.889H5a1 1 0 01-1-1V8a1 1 0 011-1h6.667a1 1 0 01.6.2l3.466 2.6a1 1 0 00.6.2H27a1 1 0 011 1zM13 18h6M16 15v6"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFolderPlus;
